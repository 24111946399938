import { apolloClient } from '../graphql/enhancers'
import {
  AUTHENTICATE_PROSPECT,
  UPDATE_PROSPECT_CONTACT_INFOS,
  GET_PROSPECT_UPDATES,
  VALIDATE_TOKEN,
} from '../graphql'
import { getPlanName } from '@anewgo/functions'
import { tokenService } from './token'
import { processProspect, setAutoLogout } from '../utils/auth'
import * as reducers from '../store/reducers'

/**
 * Authenticates a prospect with the given token and provider. If prospect not exists, it will be created.
 * @param accessToken {string} - Access token
 * @param clientName {string} - Client name
 * @param provider {string} - Authentication provider
 * @param communityId {number} - Community id
 * @param planName {string} - Plan name
 * @param consultantName {string} - Consultant name
 * @param consultantEmail {string} - Consultant email
 * @param consultantPhone {string} - Consultant phone
 * @return {Promise<null|{prospect, isRegistered, token: {exp, value}}>}
 */
export async function authenticateProspect(
  accessToken,
  clientName,
  provider,
  communityId,
  planName,
  consultantName,
  consultantEmail,
  consultantPhone
) {
  try {
    const result = await apolloClient.query({
      query: AUTHENTICATE_PROSPECT,
      variables: {
        clientName,
        source: 'truss',
        accessToken,
        identityProvider: provider,
        planName,
        communityId,
        consultantName,
        consultantEmail,
        consultantPhone,
      },
    })

    let resultData = result.data?.authenticateProspect
    if (!resultData) return null
    let { flagshipToken, tokenExp, isRegistered, prospect, profile } =
      resultData
    return {
      profile,
      prospect: prospect,
      isRegistered: isRegistered,
      token: {
        value: flagshipToken,
        exp: tokenExp,
      },
    }
  } catch (e) {
    console.error(e)
    throw new Error(`Identity validation failed!`)
  }
}

/**
 * Updates prospect phone and preferred contacts through fence
 * @param token {string} - Access token
 * @return {Promise<boolean>}
 */

export async function updateProspectContactInfos(
  id,
  phone,
  preferredContactMethods,
  name
) {
  try {
    const result = await apolloClient.mutate({
      mutation: UPDATE_PROSPECT_CONTACT_INFOS,
      variables: {
        id,
        phone,
        preferredContactMethods,
        name,
      },
    })
    return result.data.updateProspectContactInfos
  } catch (e) {
    console.error(e)
    throw new Error(`Prospect Contact Methods Update Failed!`)
  }
}

/**
 * Validates prospect token against fence
 * @param token {string} - Access token
 * @param clientName {string} - Client name
 * @param hasConsent {boolean} - Has consent to link to builder
 * @return {Promise<{isValid: boolean, isFirstSignIn: boolean, isRegistered: boolean}>}
 */
export async function validateProspectToken(clientName, token, hasConsent) {
  try {
    const result = await apolloClient.query({
      query: VALIDATE_TOKEN,
      variables: {
        token,
        clientName,
        hasConsent,
      },
    })
    return result.data.validateProspectToken
  } catch (e) {
    return {
      isValid: false,
      isRegistered: false,
      isFirstSignIn: false,
    }
  }
}

async function fetchProspectUpdates(clientName, id) {
  try {
    const result = await apolloClient.query({
      query: GET_PROSPECT_UPDATES,
      variables: {
        clientName,
        id,
      },
    })
    return result.data.prospectById
  } catch (e) {
    return null
  }
}

export async function loadProspect({
  clientName,
  dispatch,
  selection,
  anonymousProspect,
  track,
  uiConfig,
  newSignIn = true,
  hasConsent = false,
}) {
  dispatch(reducers.setIsProspectFetching(true))
  let token = undefined
  try {
    // Use the profile data to create or update a Prospect.
    // Retrieve the Prospect.
    token = tokenService.token()
    if (!token?.prospect) return
    /*
    if (!token?.prospect) {
      await failedAuthLogOut(
        'Authorization Expired. Please log in again to continue.'
      )
      return
    }
    */

    if (!(await tokenService.validate(clientName, hasConsent))) return // Token is invalid
    /*
    if (!(await tokenService.validate(clientName, hasConsent))) {
      await failedAuthLogOut(
        'Authorization Invalid. Please log in again to continue.'
      )
      return
    } // Token is invalid
    */

    if (token.validation.isFirstSignIn) {
      dispatch(reducers.setConsentToNewClientLoginDialogOpen(true))
      return // First sign in with new builder
    }

    // Fetch current favorites, brochures and reservations
    const updates = await fetchProspectUpdates(clientName, token.prospect.id)
    token.prospect = Object.assign({}, token.prospect, updates)
    /*
    setAutoLogout(dispatch, clientName, uiConfig, token.exp, () =>
      failedAuthLogOut('Your session has expired. Please sign in again.')
    )
    */
    setAutoLogout(dispatch, clientName, uiConfig, token.exp)
  } catch (error) {
    // Log a more detailed error message.
    console.error(
      'Encountered an error while initializing a prospect (and favorites):',
      error
    )
    // Rethrow the error to avoid executing any other code, and trigger a simpler error message in
    // a pop-up.
    throw error
  }

  const communityId = selection?.community?.id
  const planName =
    selection.plan &&
    `${getPlanName(selection.plan)} ${
      selection?.elevation?.caption || ''
    }`.trim()

  // Add any other prospect-related fields that are not stored in the database, but our app needs.
  const newProspect = {
    ...token.prospect,
    communityId: communityId || token.prospect.communityId || undefined,
    floorplanOfInterest: planName || token.prospect.planName || undefined,
  }

  await processProspect(
    newProspect,
    anonymousProspect,
    dispatch,
    track,
    clientName,
    newSignIn,
    hasConsent,
    selection,
    uiConfig
  )

  return newProspect
}
