/* eslint-disable eqeqeq */
import { getPath, propEquals, apply } from '@anewgo/functions'
import { siteplans } from '@anewgo/utils'
import { uniqueIndexForFavorite } from '../utils/favorites'
import {
  PAINTED_BRICK_MATERIAL_ID,
  PAINTED_BRICK_ROWLOCK_MATERIAL_ID,
  PAINTED_BRICK_NAME,
  BRICK_AND_MASONRY_MATERIAL_IDS,
  PAINTED_BRICK_ROWLOCK_COLOR_NAME,
  MASONRY2_MATERIAL_ID,
} from '../constants/PaintedBrick'
const { availableSchemesForLot, isInventoryLot } = siteplans

export const selectionActionTypes = {
  CLEAR_COLOR_SELECTIONS: 'CLEAR_COLOR_SELECTIONS',
  CLEAR_HOMESITE: 'CLEAR_HOMESITE',
  CLEAR_PLAN_ELEVATION_AND_HOMESITE: 'CLEAR_PLAN_ELEVATION_AND_HOMESITE',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  RESET_DESIGN_CHOICES: 'RESET_DESIGN_CHOICES',
  RESTORE_SELECTION: 'RESTORE_SELECTION',
  SET_SVG_MIRRORING: 'SET_SVG_MIRRORING',
  SELECT_COMMUNITY: 'SELECT_COMMUNITY',
  SELECT_LOT: 'SELECT_LOT',
  SELECT_MIRROR_ELEVATION: 'SELECT_MIRROR_ELEVATION',
  SELECT_PLAN_ELEVATION: 'SELECT_PLAN_ELEVATION',
  SELECT_SCHEME: 'SELECT_SCHEME',
  UPDATE_LAST_UPDATE: 'UPDATE_LAST_UPDATE',
  UPDATE_COLOR_SELECTIONS: 'UPDATE_COLOR_SELECTIONS',
  UPDATE_FP_OPT_SELECTIONS: 'UPDATE_FP_OPT_SELECTIONS',
  RESET_SELECTION_LOT: 'RESET_SELECTION_LOT',
  UPDATE_INTERIOR_DESIGN_SELECTIONS: 'UPDATE_INTERIOR_DESIGN_SELECTIONS',
  RESTORE_SELECTION_FROM_FAVORITES_LIST:
    'RESTORE_SELECTION_FROM_FAVORITES_LIST',
  SET_GARAGE_POSITION: 'SET_GARAGE_POSITION',
  UPDATE_MORTGAGE: 'UPDATE_MORTGAGE',
}

export const initialSelectionState = {
  community: null,
  plan: null,
  elevation: null,
  lot: null,
  scheme: null,
  lastUpdate: null,
  fpOptSelections: {},
  colorSelections: {},
  interiorDesignSelections: [],
}

export const selectionReducer = (action) => (state) => {
  if (global.loggingEnabled) {
    console.log('selectionReducer', { action, state })
  }

  switch (action.type) {
    case selectionActionTypes.RESET_SELECTION_LOT: {
      return {
        ...state,
        lot: null,
      }
    }
    case selectionActionTypes.CLEAR_COLOR_SELECTIONS: {
      return {
        ...state,
        colorSelections: initialSelectionState.colorSelections,
      }
    }
    case selectionActionTypes.CLEAR_HOMESITE: {
      return {
        ...state,
        lot: initialSelectionState.lot,
      }
    }
    case selectionActionTypes.CLEAR_PLAN_ELEVATION_AND_HOMESITE: {
      return {
        ...initialSelectionState,
        community: state.community,
      }
    }
    case selectionActionTypes.CLEAR_SELECTION: {
      return initialSelectionState
    }
    case selectionActionTypes.RESET_DESIGN_CHOICES: {
      return {
        ...state,
        colorSelections: initialSelectionState.colorSelections,
        fpOptSelections: initialSelectionState.fpOptSelections,
      }
    }
    case selectionActionTypes.RESTORE_SELECTION: {
      return action.payload
    }

    case selectionActionTypes.RESTORE_SELECTION_FROM_FAVORITES_LIST: {
      const favorites = state.favoritesState.favorites
      const selection = state.selectionState
      const planId = getPath('id')(selection.plan)
      const communityId = getPath('id')(selection.community)
      const elevationId = getPath('id')(selection.elevation)
      const duplicateNumber = getPath('duplicateNumber')(selection)
      if (selection.lot && isInventoryLot(selection.lot))
        return state.selectionState
      else if (favorites && favorites.length) {
        const foundFavorites = favorites.filter(
          (fav) =>
            fav.planId === planId &&
            fav.communityId === communityId &&
            ((duplicateNumber && fav.duplicateNumber === duplicateNumber) ||
              uniqueIndexForFavorite(fav) ===
                uniqueIndexForFavorite(selection)) &&
            getPath('elevation', 'id')(fav) === elevationId &&
            (!fav.lot || (fav.lot && !isInventoryLot(fav.lot))) &&
            (!fav.elevation.svgMirroring ||
              fav.garagePosition === selection.garagePosition)
        )
        if (foundFavorites && foundFavorites.length > 0) {
          if (foundFavorites.length > 1) {
            const correctFavorite = foundFavorites.find(
              (fav) => fav.id === selection.favId || fav.id === selection.id
            )
            return correctFavorite || foundFavorites[0]
          }
          return foundFavorites[0]
        }
      }
      return state.selectionState
    }

    case selectionActionTypes.SELECT_COMMUNITY: {
      if (action.payload?.id === getPath('id')(state.community)) {
        return state
      }

      return {
        ...initialSelectionState,
        interiorDesignSelections: state.interiorDesignSelections || [],
        community: action.payload,
      }
    }
    case selectionActionTypes.SELECT_LOT: {
      const { schemeForLot } = action.payload
      let scheme = initialSelectionState.scheme

      const availableSchemes = availableSchemesForLot(action.payload) || []

      if (schemeForLot) {
        scheme = schemeForLot
      } else if (
        state.scheme &&
        availableSchemes.some(propEquals('id', getPath('id')(state.scheme)))
      ) {
        scheme = state.scheme
      }
      return {
        ...state,
        lot: action.payload,
        scheme,
      }
    }
    case selectionActionTypes.SELECT_MIRROR_ELEVATION: {
      return {
        ...state,
        elevation: action.payload,
      }
    }
    case selectionActionTypes.SET_SVG_MIRRORING: {
      return state.elevation.svgMirroring
        ? {
            ...state,
            isMirrored: action.payload.isMirrored,
          }
        : state
    }
    case selectionActionTypes.SELECT_PLAN_ELEVATION: {
      const {
        plan,
        elevation,
        community,
        client,
        clientName,
        garagePosition,
        isMirrored,
        favId,
        id,
      } = action.payload
      const clientAltName = clientName || (client && client.altName)
      if (
        plan.id === getPath('id')(state.plan) &&
        elevation.id === getPath('id')(state.elevation)
      ) {
        if (apply(isInventoryLot)(state.lot)) {
          return {
            ...state,
            fpOptSelections: initialSelectionState.fpOptSelections,
            lot: initialSelectionState.lot,
            community: state.community || community,
          }
        }
        return state
      }
      return {
        ...initialSelectionState,
        community: state.community || community,
        plan,
        elevation,
        garagePosition,
        isMirrored,
        interiorDesignSelections: state.interiorDesignSelections || [],
        ...(clientName && { clientName: clientAltName }),
        // If favId OR id is present this is the favoriteId from either the floorplan page URL
        // query parameters OR a favorite with the same selection in prospect.favorites. We will conditionally
        // add the id and/or the favId
        ...((favId || id) && { favId: favId || id }),
        ...((id || favId) && { id: id || favId }),
      }
    }
    case selectionActionTypes.SELECT_SCHEME: {
      return {
        ...state,
        scheme: action.payload,
      }
    }
    case selectionActionTypes.UPDATE_LAST_UPDATE: {
      return {
        ...state,
        lastUpdate: action.payload,
      }
    }
    case selectionActionTypes.UPDATE_COLOR_SELECTIONS: {
      // We currently need to handle optional painted brick material (blend element id:246) and the regular brick
      // material (blend element id:12) on a per project basis to render the layers properly. This WILL be repeated
      // code in each project that uses the interactive exterior renderer at this time (10/5/22). So, with that being
      // said, the following code is checking that if the new material selection is BRICK (id:12) and is NOT `painted brick`
      //  we will remove ANY optional painted brick selection (id: 246) DRC.
      if (
        action.payload &&
        BRICK_AND_MASONRY_MATERIAL_IDS.includes(
          parseInt(Object.keys(action.payload)[0])
        ) &&
        !Object.values(action.payload)
          ?.name?.toLowerCase()
          ?.includes(PAINTED_BRICK_NAME.toLowerCase()) &&
        Object.keys(state.colorSelections).includes(
          PAINTED_BRICK_MATERIAL_ID.toString()
        )
      ) {
        delete state.colorSelections[PAINTED_BRICK_MATERIAL_ID.toString()]
      }
      if (
        action.payload &&
        parseInt(Object.keys(action.payload)[0]) === MASONRY2_MATERIAL_ID &&
        !Object.values(action.payload)
          ?.name?.toLowerCase()
          ?.includes(PAINTED_BRICK_ROWLOCK_COLOR_NAME.toLowerCase()) &&
        Object.keys(state.colorSelections).includes(
          PAINTED_BRICK_ROWLOCK_MATERIAL_ID.toString()
        )
      ) {
        delete state.colorSelections[
          PAINTED_BRICK_ROWLOCK_MATERIAL_ID.toString()
        ]
      }

      return {
        ...state,
        colorSelections: {
          ...state.colorSelections,
          ...action.payload,
        },
      }
    }
    case selectionActionTypes.REPLACE_COLOR_SELECTIONS: {
      return {
        ...state,
        colorSelections: {
          ...action.payload,
        },
      }
    }
    case selectionActionTypes.UPDATE_INTERIOR_DESIGN_SELECTIONS: {
      const updatedInteriorSelections = state.interiorDesignSelections
        .filter((intDes) => {
          if (intDes) {
            return !action.payload.find(
              (payload) => intDes.interiorId === payload.interiorId
            )
          }
          return updatedInteriorSelections
        })
        .concat(action.payload)

      return {
        ...state,
        interiorDesignSelections: updatedInteriorSelections,
      }
    }
    case selectionActionTypes.UPDATE_FP_OPT_SELECTIONS: {
      let update = false

      action.payload &&
        Object.keys(action.payload) &&
        Object.keys(action.payload).length &&
        Object.keys(action.payload).forEach((item) => {
          if (action.payload[item].length && action.payload[item].length > 0) {
            update = true
          }
        })
      return update
        ? {
            ...state,
            fpOptSelections: {
              ...state.fpOptSelections,
              ...action.payload,
            },
          }
        : {
            ...state,
            fpOptSelections: {},
          }
    }
    case selectionActionTypes.SET_GARAGE_POSITION: {
      const { garagePosition, isMirrored } = action.payload
      return {
        ...state,
        garagePosition,
        isMirrored,
      }
    }
    case selectionActionTypes.UPDATE_MORTGAGE: {
      return {
        ...state,
        mortgage: action.payload,
      }
    }
    default:
      throw new Error('Unexpected action sent to selection reducer.', {
        action,
      })
  }
}
