import { useEffect } from 'react'
import { AuthProviders } from '../constants/AuthProviders'
import { bootstrapProspect } from '../utils/auth'
import { sessionStorage as storage } from '@anewgo/storage'

export const google = window.google

let googleAccountsService = null

let loginCompletionEventHandlerFn = null

let onAuthInitHandler = () => {}
let onAuthFailedHandler = () => {}

function setOnAuthInitHandler(handler) {
  if (typeof handler === 'function') {
    onAuthInitHandler = handler
  }
}

function setOnAuthFailedHandler(handler) {
  if (typeof handler === 'function') {
    onAuthFailedHandler = handler
  }
}

async function handleGoogleAccountsCallback(data, args) {
  let accessToken = data?.credential
  if (!accessToken) {
    console.error('No access token received from Google Accounts!')
    return
  }

  const clientName = storage.getItem('clientName')
  onAuthInitHandler()

  await bootstrapProspect({
    clientName,
    dispatch: args.dispatch,
    selection: args.selection,
    track: args.track,
    anonymousProspect: args.anonymousProspect,
    authType: AuthProviders.GOOGLE,
    accessToken: accessToken,
    loginCompletionEventHandlerFn,
    uiConfig: args.uiConfig,
  }).catch(() => {
    onAuthFailedHandler()
  })
}

async function initGoogleAccounts(args) {
  try {
    googleAccountsService = window.google.accounts.id
    await googleAccountsService.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: (data) => handleGoogleAccountsCallback(data, args),
    })
  } catch (ex) {
    onAuthFailedHandler()
    console.error('failed initializing google accounts', ex)
  }
}

export async function getGoogleAccounts(args) {
  if (!googleAccountsService) {
    await initGoogleAccounts(args)
  }
  return googleAccountsService
}

function subscribeGoogleAccountsCallback(completeLoginHandler) {
  loginCompletionEventHandlerFn = (isRegistered, prospect, token, authType) =>
    completeLoginHandler(isRegistered, prospect, token, authType)
}

function unsubscribeGoogleAccountsCallback() {
  loginCompletionEventHandlerFn = null
}

function isAvailable() {
  return typeof window.google?.accounts?.id !== 'undefined'
}

export function useGoogleAccounts(handler) {
  useEffect(() => {
    subscribeGoogleAccountsCallback(handler)
    return () => {
      unsubscribeGoogleAccountsCallback()
    }
  }, [handler])
}

export const googleAccounts = {
  isAvailable: isAvailable,
  get: getGoogleAccounts,
  init: initGoogleAccounts,
  subscribe: subscribeGoogleAccountsCallback,
  unsubscribe: unsubscribeGoogleAccountsCallback,
  setOnAuthInitHandler,
  setOnAuthFailedHandler,
}
