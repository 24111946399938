import gql from 'graphql-tag'
import * as commonFields from './commonFields'

export const city = gql`
  fragment CityFields on CityInfo {
    id
    name
    customName
    region(clientName: $clientName) {
      __typename
      ... on USCityRegionInfo {
        stateCode
        metroName
        metroCustomName
        zipCode
      }
      ... on CANCityRegionInfo {
        provinceCode
        districtName
        postCode
      }
    }
  }
`

export const community = gql`
  fragment CommunityFields on Community {
    id
    active
    name
    thumb
    bonafide
    buildYourLot
    caption
    colormtd
    description
    pricing
    logo
    longitude
    latitude
    address
    sortType
    sortOrder
    url
    primarySiteplan(clientName: $clientName, active: true) {
      id
      lotMetric
      lotLegend(clientName: $clientName) {
        id
        code
        name
        hex
      }
    }
    cityLocation(clientName: $clientName) {
      ...CityFields
    }
    agents(clientName: $clientName) {
      id
      email
      phone
      firstName
      lastName
      picture
    }
  }
  ${city}
`

export const plan = gql`
  fragment PlanFields on Plan {
    ${commonFields.plan}
    ${commonFields.ranges}
  }
`

export const elevation = gql`
  fragment ElevationFields on Elevation {
    ${commonFields.elevation}
    ${commonFields.ranges}
    ${commonFields.elevationTags}
  }
`

export const lot = gql`
  fragment LotFields on Lot {
    ${commonFields.lot}
  }
`

export const inventory = gql`
  fragment InventoryFields on Inventory {
    ${commonFields.inventory}
  }
`

/**
 * The siteplan fragment (SiteplanFields) depends on the the inventory, plan,
 * and elevation fragments.
 */
export const siteplanSVG = gql`
  fragment SiteplanSVGFields on Siteplan {
    svg {
      viewBox {
        x
        y
        width
        height
      }
      style
      frame {
        x
        y
        width
        height
      }
      shapes {
        tagName
        attributes {
          className
          dataName
          x
          y
          width
          height
          transform
          points
          d
        }
      }
    }
  }
`

export const hotspots = gql`
  fragment HotspotsFields on Siteplan {
    hotspots {
      id
      siteplanId
      name
      width
      height
      x
      y
      description
      thumb
      assets {
        id
        listIndex
        src
        description
      }
    }
  }
`
