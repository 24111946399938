import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import { ApolloConsumer } from '@apollo/client'
import { compose, fromRenderProps, setDisplayName } from 'react-recompose'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import MaskedInput from 'react-text-mask'
import { getPath, getPlanName } from '@anewgo/functions'
import StyledDialog, { classes } from './ContactDialogStyles'

import { StoreContext } from '../../store'
import * as reducers from '../../store/reducers'
import { SEND_INQUIRY } from '../../graphql'
import {
  EVT_CLICKED_CONTACT_US,
  EVT_MY_BROCHURE_SELECTED_COLORS,
  EVT_MY_BROCHURE_SELECTED_FPOPTS,
  EVT_MY_BROCHURE_SELECTED_INTERIORS,
  EVT_MY_BROCHURE_INTERIOR_SELECTIONS,
} from '../../constants/eventTracking'
import { useEventTracker, useHomeIdentifier } from '../../utils/hooks'
import { generateSelectionTracking } from '../../utils'

const TextMaskCustom = React.forwardRef((props, inputRef) => (
  <MaskedInput
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null)
    }}
    mask={[
      '(',
      /[1-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
    placeholderChar={'\u2000'}
    showMask
  />
))

const DEFAULT_NAME_LABEL = 'Name'
const DEFAULT_EMAIL_LABEL = 'Email Address'
const DEFAULT_PHONE_LABEL = 'Phone #'
const DEFAULT_MESSAGE_LABEL = 'Message'
const INPUT_MODE = 'INPUT_MODE'
const GOODBYE_MODE = 'GOODBYE_MODE'

function ContactDialog({ match, apolloClient, location }) {
  const track = useEventTracker()
  const homeIdentifier = useHomeIdentifier()
  const { dispatch, uiConfig, prospect, selection } = useContext(StoreContext)
  const { contactDialogOpen } = uiConfig
  const [name, setName] = useState(undefined)
  const [nameLabel, setNameLabel] = useState(DEFAULT_NAME_LABEL)
  const [nameError, setNameError] = useState(false)

  const [email, setEmail] = useState(undefined)
  const [emailLabel, setEmailLabel] = useState(DEFAULT_EMAIL_LABEL)
  const [emailError, setEmailError] = useState(false)

  const [phone, setPhone] = useState(undefined)
  const [phoneLabel, setPhoneLabel] = useState(DEFAULT_PHONE_LABEL)

  const [message, setMessage] = useState(undefined)
  const [messageLabel, setMessageLabel] = useState(DEFAULT_MESSAGE_LABEL)
  const [messageError, setMessageError] = useState(false)

  const [displayMode, setDisplayMode] = useState(INPUT_MODE)

  const mySelection = useRef(null)
  mySelection.current = useMemo(() => {
    return selection
  }, [selection])

  let clientName = match && match.params ? match.params.clientName : undefined
  if (clientName === undefined) {
    const pathname = location ? location.pathname : undefined
    if (pathname !== undefined) {
      const result = pathname.split('/')
      clientName = result[2]
    }
  }

  const prospectId = prospect && prospect.id

  useEffect(() => {
    setName(prospect?.name)
    setEmail(prospect?.email)
    setPhone(prospect?.phone)
  }, [prospectId, contactDialogOpen])

  function handleClose(ev, cancel) {
    if (cancel) {
      setName(undefined)
      setEmail(undefined)
      setPhone(undefined)
      setMessage(undefined)
      dispatch(reducers.setContactDialogOpen(false))
      return
    }

    if (!name) {
      setNameLabel('Please provide your name')
      setNameError(true)
      return
    }

    if (!email) {
      setEmailLabel('Please provide your email address')
      setEmailError(true)
      return
    }

    if (!message) {
      setMessageLabel('Please provide your message')
      setMessageError(true)
      return
    }

    // send message
    const _agentEmails = []
    let communitiesNamesSelected = []
    if (selection?.community?.bonafide) {
      communitiesNamesSelected.push(selection.community.name)
    }

    if (uiConfig.tgtCommunities) {
      uiConfig.tgtCommunities.forEach(
        (tgtComm) =>
          selection.community.bonafide &&
          communitiesNamesSelected.push(tgtComm.name)
      )
    }

    const agents = selection?.community?.agents
    if (agents) {
      agents.forEach((agent) => _agentEmails.push(agent.email))
    }

    if (uiConfig.tgtCommunities && uiConfig.tgtCommunities.length) {
      uiConfig.tgtCommunities.forEach((tgtComm) =>
        tgtComm.agents.forEach(
          (agent) => agent.email && _agentEmails.push(agent.email)
        )
      )
    }

    let planName = getPlanName(selection && selection.plan)
    const totalAgentsEmails = [...new Set(_agentEmails)]
    const _agentEmail =
      totalAgentsEmails.length > 0 ? totalAgentsEmails.join() : undefined
    communitiesNamesSelected = [...new Set(communitiesNamesSelected)]
    const { prospectId, homeId } = queryString.parse(location.search)
    let myHomeUrl
    if (location.pathname.includes('/myhome') && prospectId && homeId) {
      // we are on the myhome page
      myHomeUrl = encodeURI(
        `${process.env.REACT_APP_TRUSS}${location.pathname}${location.search}`
      )
    }

    // we don't need to await
    apolloClient.mutate({
      mutation: SEND_INQUIRY,
      variables: {
        clientName,
        input: {
          prospectEmail: email,
          prospectName: name,
          prospectPhone: phone,
          agentEmail: _agentEmail,
          myHomeUrl,
          favoritesUrl: `${process.env.REACT_APP_TRUSS}/client/${clientName}/compareFavorites?email=${email}&name=${name}`,
          inquiry: message,
          communityId: getPath('id')(selection.community),
          communitiesSelected: communitiesNamesSelected.length
            ? communitiesNamesSelected.join(`, `)
            : null,
          planName,
          lotName: getPath('lot', 'name')(selection) || '',
          lotAddress: getPath('lot', 'address')(selection) || '',
        },
      },
    })

    // Track Contact us action after sending request
    const trackingProps = {
      communityId: selection.community?.id,
      communityName: selection.community?.name,
      inventoryId: selection.lot?.inventory?.id,
      lotId: selection.lot?.id,
      lotName: selection.lot?.name,
      lotAddress: selection.lot?.address,
      planId: selection.plan?.id,
      planName: selection.plan?.name,
      elevationId: selection.elevation?.id,
      elevationCaption: selection.elevation?.caption,
      actionType: 'Contact Us',
      message,
    }
    track(EVT_CLICKED_CONTACT_US, trackingProps)
    generateSelectionTracking(
      track,
      homeIdentifier,
      mySelection.current,
      null,
      EVT_CLICKED_CONTACT_US,
      EVT_MY_BROCHURE_SELECTED_COLORS,
      EVT_MY_BROCHURE_SELECTED_FPOPTS,
      EVT_MY_BROCHURE_SELECTED_INTERIORS,
      EVT_MY_BROCHURE_INTERIOR_SELECTIONS
    )

    setDisplayMode(GOODBYE_MODE)
    setTimeout(() => {
      dispatch(reducers.setContactDialogOpen(false))
      setDisplayMode(INPUT_MODE)
    }, 2000)
  }

  function handleNameChange(nameInput) {
    setNameError(false)
    setNameLabel(DEFAULT_NAME_LABEL)
    setName(nameInput)
  }

  function handleEmailChange(emailInput) {
    setEmailError(false)
    setEmailLabel(DEFAULT_EMAIL_LABEL)
    setEmail(emailInput)
  }

  function handlePhoneChange(phoneInput) {
    setPhoneLabel(DEFAULT_PHONE_LABEL)
    setPhone(phoneInput)
  }

  function handleMessageChange(msgInput) {
    setMessageError(false)
    setMessageLabel(DEFAULT_MESSAGE_LABEL)
    setMessage(msgInput)
  }

  function displayGoodbyeContent() {
    return (
      <DialogContent>
        <Typography variant="h5" style={{ paddingBottom: 32 }}>
          Thank you for contacting us! Our team will be in touch shortly.
        </Typography>
      </DialogContent>
    )
  }

  function displayInputContent() {
    return (
      <Fragment>
        <DialogContent>
          <TextField
            margin="dense"
            variant="standard"
            id="contactDlg-name"
            label={nameLabel}
            error={nameError}
            type="text"
            fullWidth
            required
            value={name}
            onChange={(ev) => handleNameChange(ev.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{ 'data-testid': 'contact_dialog_name_input' }}
          />
          <TextField
            margin="dense"
            variant="standard"
            id="contactDlg-email"
            label={emailLabel}
            error={emailError}
            type="email"
            fullWidth
            required
            value={email}
            InputLabelProps={{ shrink: true }}
            InputProps={{ 'data-testid': 'contact_dialog_email_input' }}
            onChange={(ev) => handleEmailChange(ev.target.value)}
          />
          <FormControl
            className={classes.formControl}
            fullWidth
            variant="standard"
          >
            <InputLabel htmlFor="contactDlg-phone">{phoneLabel}</InputLabel>
            <Input
              autoFocus
              margin="dense"
              value={phone ? phone : ''}
              onChange={(ev) => handlePhoneChange(ev.target.value)}
              InputProps={{ 'data-testid': 'contact_dialog_phone_input' }}
              id="contactDlg-phone"
              inputComponent={TextMaskCustom}
            />
          </FormControl>
          <TextField
            margin="dense"
            variant="standard"
            id="contactDlg-inquiry"
            label={messageLabel}
            error={messageError}
            type="text"
            required
            multiline
            fullWidth
            rows={4}
            onChange={(ev) => handleMessageChange(ev.target.value)}
            InputProps={{ 'data-testid': 'contact_dialog_message_input' }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(ev) => handleClose(ev, true)}
            color="secondary"
            data-testid="contact_dialog_cancel_button"
          >
            Cancel
          </Button>
          <Button
            onClick={(ev) => handleClose(ev, false)}
            color="primary"
            data-testid="contact_dialog_send_button"
          >
            Send
          </Button>
        </DialogActions>
      </Fragment>
    )
  }

  // r e n d e r
  return (
    <StyledDialog
      open={contactDialogOpen}
      onClose={(ev) => handleClose(ev, true)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ position: 'relative' }}>
        <Typography variant="h2" classes={{ h2: classes.h2 }}>
          Contact Us
        </Typography>
        <img
          alt="contact-us-banner"
          className={classes.img}
          src="https://res.cloudinary.com/renderinghouse/image/upload/v1558660799/anewgo/stock/contactus-banner.jpg"
        />
      </DialogTitle>
      {displayMode === INPUT_MODE && displayInputContent()}
      {displayMode === GOODBYE_MODE && displayGoodbyeContent()}
    </StyledDialog>
  )
}

const Wrapped = compose(
  withRouter,
  fromRenderProps(ApolloConsumer, (apolloClient) => ({ apolloClient })),
  setDisplayName('ContactDialog')
)(ContactDialog)

export default Wrapped
