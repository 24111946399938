import { styled } from '@mui/material/styles'
import DialogTitle from '@mui/material/DialogTitle'

const PREFIX = 'ConsentToNewClientLoginDialog'

export const classes = {
  space: `${PREFIX}-space`,
  line: `${PREFIX}-line`,
}

export default styled(DialogTitle)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  [`& .${classes.space}`]: {
    marginTop: '0.5em',
  },
  [`& .${classes.line}`]: {
    marginTop: '1em',
  },
}))
