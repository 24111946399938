import React from 'react'
import MaskedInput from 'react-text-mask/dist/reactTextMask'
import { phoneMask } from '../../utils'

const PhoneMaskedInput = React.forwardRef((props, inputRef) => (
  <MaskedInput
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null)
    }}
    mask={phoneMask}
    placeholderChar={'\u2000'}
    showMask
  />
))

export default PhoneMaskedInput
