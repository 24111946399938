import { Fragment, useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import { StoreContext } from '../../store'
import * as reducers from '../../store/reducers'

const AllowThirdPartyPackageDialog = () => {
  const {
    dialog: { isAllowThirdPartyPackageDialog },
    dispatch,
  } = useContext(StoreContext)

  return (
    <Fragment>
      <Dialog
        open={isAllowThirdPartyPackageDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ position: 'relative' }}>
          Sign-in with Google is not allowed in Incognito window. How to solve:
          <br />
          <List dense={true}>
            <ListItem>
              <ListItemText primary="1. Use Non-Incognito window" />
            </ListItem>
            <ListItem>
              <ListItemText primary="2. In browser setting allow Third-Party Cookies" />
            </ListItem>
          </List>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() =>
              dispatch(reducers.setAllowThirdPartyPackageDialogOpen(false))
            }
            color="primary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default AllowThirdPartyPackageDialog
