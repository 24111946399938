import { styled } from '@mui/material/styles'

const PREFIX = 'DisclaimerDialog'

export const classes = {
  close: `${PREFIX}-close`,
  h2: `${PREFIX}-h2`,
  img: `${PREFIX}-img`,
  formControl: `${PREFIX}-formControl`,
  link: `${PREFIX}-link`,
  snackDiv: `${PREFIX}-snackDiv`,
  snackMessage: `${PREFIX}-snackMessage`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.close}`]: {
    padding: theme.spacing(0.5),
    position: 'absolute',
    top: 0,
    right: 0,
  },

  [`& .${classes.h2}`]: {
    fontFamily: 'Gotham Book, sans-serif',
    fontWeight: 'bold',
    margin: 'auto 0px',
    textAlign: 'center',
    fontSize: 10,
  },

  [`& .${classes.img}`]: {
    width: '100%',
    height: 150,
    objectFit: 'cover',
    opacity: 0.75,
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },

  [`& .${classes.link}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.snackDiv}`]: {
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
    marginTop: 12,
  },

  [`& .${classes.snackMessage}`]: {
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
  },
}))

export default Root
