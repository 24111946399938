import Root, { classes } from './NoMatchStyles'

const NoMatch = ({ bgImg, msg }) => {
  const localStyles = {
    bg: {
      backgroundImage: `url(${bgImg})`,
    },
  }
  return (
    <Root className={classes.noMatchBg} style={localStyles.bg}>
      <div className={classes.noMatchText}>{msg}</div>
    </Root>
  )
}

export default NoMatch
