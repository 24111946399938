import Avatar from '@mui/material/Avatar'
import StyledAccountCard, { classes } from './AccountCardStyles'
import { AccountSource } from '../../../constants/AccountSource'
import { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../store'
import { AccountCardVariant } from '../../../constants/AccountCardVariant'
import Typography from '@mui/material/Typography'
import { anewgoCloudinaryPrefix } from '@anewgo/utils'

export function AccountCard({ source, variant, prospect, text }) {
  const {
    prospect: contextProspect,
    prospectToken,
    dispatch,
  } = useContext(StoreContext)
  const [currentProspect, setCurrentProspect] = useState(contextProspect)

  const getProspect = () => {
    if (source === AccountSource.CONTEXT) {
      return contextProspect
    } else if (source === AccountSource.TOKEN) {
      return prospectToken?.prospect
    }
    return prospect
  }

  // Update the current prospect on change
  useEffect(() => {
    setCurrentProspect(getProspect())
  }, [dispatch, contextProspect, prospect, prospectToken])

  const renderIcon = () => (
    <Avatar
      alt={currentProspect.name}
      src={`${anewgoCloudinaryPrefix}/prospects/${currentProspect.picture}`}
      className={classes.avatar}
    />
  )

  const renderText = () => (
    <div className={classes.text}>
      <Typography className={classes.name}>{currentProspect.name}</Typography>
      <Typography className={classes.email}>{currentProspect.email}</Typography>
    </div>
  )

  return variant && currentProspect ? (
    <StyledAccountCard variant={variant}>
      {variant === AccountCardVariant.TEXT_ICON && renderText()}
      {variant !== AccountCardVariant.TEXT && renderIcon()}
      {(variant === AccountCardVariant.TEXT ||
        variant === AccountCardVariant.ICON_TEXT) &&
        renderText()}
      {variant === AccountCardVariant.ICON_TEXT_UNDER && (
        <Typography>{text}</Typography>
      )}
    </StyledAccountCard>
  ) : null
}
