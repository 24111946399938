import { Fragment, useContext, Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import ClientRoute from './ClientRoute'
// import { Transition, animated } from 'react-spring/renderprops'
import Loading from '../components/Loading/Loading'
import { StoreContext } from '../store'
import { NO_MATCH_BG, NO_MATCH_MSG } from '../constants'
import { useTrackLeaveReservationPage } from '../utils/hooks'

const Unsubscribe = lazy(() => import('../pages/Unsubscribe/Unsubscribe'))
const Favorites = lazy(() => import('../pages/Favorites/Favorites'))
const NoMatch = lazy(() => import('./NoMatch/NoMatch'))
const Brochure = lazy(() => import('../pages/Brochure/Brochure'))
const InteriorBrochure = lazy(() => import('../pages/InteriorBrochure'))
const Community = lazy(() => import('../pages/Community/Community'))
const Floorplan = lazy(() => import('../pages/Floorplan/Floorplan'))
const Header = lazy(() => import('../components/Header/Header'))
const Home = lazy(() => import('../pages/Home/Home'))
const Landing = lazy(() => import('../pages/Landing/Landing'))
const Plans = lazy(() => import('../pages/Plans/Plans'))
const Siteplan = lazy(() => import('../pages/Siteplan/Siteplan'))
const Inventory = lazy(() => import('../pages/Inventory/Inventory'))
const MyHome = lazy(() => import('../pages/MyHome/MyHome'))
const Reserve = lazy(() => import('../pages/Reserve/Reserve'))
const AllInteriors = lazy(() => import('../pages/AllInteriors/AllInteriors'))
const Registration = lazy(() => import('../pages/Registration/Registration'))
const Reservation = lazy(() => import('../pages/Reservation/Reservation'))
const ConfirmBuyer = lazy(() => import('../pages/ConfirmBuyer/ConfirmBuyer'))
const StandAloneInteriorWrapper = lazy(() =>
  import('../pages/StandAloneInterior/StandAloneInterior')
)
const AdaPolicy = lazy(() => import('../pages/AdaPolicy/AdaPolicy'))

/*
const AnimatedRoute = ({ height, children }) => {
  const { uiConfig } = useContext(StoreContext)
  const { scrollableContainerRef } = uiConfig

  return (
    <Route
      render={({ location }) => (
        <Transition
          native
          items={location}
          keys={location => location.pathname}
          from={{ opacity: 0, transform: 'translate3d(100%,0,0)' }}
          enter={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
          leave={{
            opacity: 0,
            transform: 'translate3d(-100%,0,0)',
            pointerEvents: 'none',
          }}
        >
          {location => style => {
            const localStyles = {
              root: {
                width: '100%',
                height,
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
              },
            }
            return (
              <animated.div
                style={{ ...style, ...localStyles.root }}
                ref={scrollableContainerRef}
              >
                {children(location)}
              </animated.div>
            )
          }}
        </Transition>
      )}
    />
  )
}
*/

const Page = ({ appHeight, component: Component, style = {} }) => (
  <div style={{ width: '100%', height: appHeight, ...style }}>
    <Component style={{ marginLeft: 10 }} />
  </div>
)

// list the more specific routes first in the switch statement
// FLOORPLAN:
// elevations may not have captions.
// to specify an elevation we will have to use IDs
// the path will look like this:
// /client/:clientName/community/:communityName/plan/:planName?elevationId=<ELEVATION_ID>
// if elevationId is not provided, the page should display with the first in the elev list
// selected
export const BASE_PATH = `/client/:clientName/community/:communityName`
const AppRoutes = ({ height, setBuyerLoginDialog }) => {
  const { uiConfig } = useContext(StoreContext)
  const { headerHeight } = uiConfig
  const appHeight = height - headerHeight
  useTrackLeaveReservationPage()

  return (
    <Fragment>
      <Suspense fallback={<Loading />}>
        <Switch>
          {/* this route prevents header from rendering or calling graphql on brochure pages */}
          <Route
            path={`${BASE_PATH}/plan/:planName/brochure`}
            render={(props) => null}
          />
          <ClientRoute
            path="/client/:clientName/interiors/brochure"
            render={(props) => null}
          />
          <ClientRoute
            path="/client/:clientName/interior/:interiorId/brochure"
            render={(props) => null}
          />
          <ClientRoute
            path="/client/:clientName"
            render={(props) => (
              <Header setBuyerLoginDialog={setBuyerLoginDialog} />
            )}
          />
          <ClientRoute
            path="/client/:clientName"
            render={(props) => <Header />}
          />
        </Switch>
        <Switch>
          <ClientRoute
            path={'/client/:clientName/compareFavorites'}
            render={(props) => <Favorites />}
          />
          <Route
            path={`${BASE_PATH}/plan/:planName/brochure`}
            render={(props) => <Brochure />}
          />
          <Route
            path={`${BASE_PATH}/inventory/brochure`}
            render={(props) => <Brochure />}
          />
          <Route
            exact
            path="/"
            render={(props) => <Page appHeight={appHeight} component={Home} />}
          />
          <Route
            path="/builders"
            render={(props) => <Page appHeight={appHeight} component={Home} />}
          />
          <ClientRoute
            path={`${BASE_PATH}/inventory/myhome`}
            render={(props) => (
              <Page appHeight={appHeight} component={MyHome} />
              // render={(props) => <Header />}
            )}
          />
          <ClientRoute
            path={`${BASE_PATH}/plan/:planName/myhome`}
            render={(props) => (
              <Page appHeight={appHeight} component={MyHome} />
            )}
          />
          <ClientRoute
            path={`${BASE_PATH}/plan/:planName`}
            render={(props) => (
              <Page appHeight={appHeight} component={Floorplan} />
            )}
          />
          <ClientRoute
            path="/client/:clientName/interiorsbrochure"
            render={(props) => <InteriorBrochure {...props} />}
          />
          <ClientRoute
            path={`/client/:clientName/interiors`}
            render={(props) => (
              <Page
                appHeight={appHeight}
                component={AllInteriors}
                style={{ overflowY: 'auto' }}
              />
            )}
          />
          <ClientRoute
            path="/client/:clientName/interior/:interiorId/brochure"
            render={(props) => <InteriorBrochure {...props} />}
          />
          <ClientRoute
            path={`/client/:clientName/interior/:interiorId`}
            render={(props) => (
              <Page
                appHeight={appHeight}
                component={StandAloneInteriorWrapper}
              />
            )}
          />
          <ClientRoute
            path={`/client/:clientName/reserve/:favoriteId`}
            render={(props) => (
              <Page
                appHeight={appHeight}
                component={Reserve}
                style={{ overflowY: 'auto' }}
              />
            )}
          />
          <ClientRoute
            path={`${BASE_PATH}/inventory`}
            render={(props) => {
              // This will match any route that begins with ${BASE_PATH}/inventory
              // and contains the text inventoryId.
              // We assume this only occurs for a query parameter inventoryId.
              if (!props.location.search.includes('inventoryId')) {
                return <NoMatch bgImg={NO_MATCH_BG} msg={NO_MATCH_MSG} />
              }
              return <Page appHeight={appHeight} component={Inventory} />
            }}
          />
          <ClientRoute
            path={`${BASE_PATH}/siteplan`}
            render={(props) => (
              <Page appHeight={appHeight} component={Siteplan} />
            )}
          />
          <ClientRoute
            path={`${BASE_PATH}/plans`}
            render={(props) => <Page appHeight={appHeight} component={Plans} />}
          />
          <ClientRoute
            path={`${BASE_PATH}`}
            render={(props) => (
              <Page appHeight={appHeight} component={Community} />
            )}
          />
          <Route
            path="/adapolicy"
            render={(props) => (
              <Page appHeight={appHeight} component={AdaPolicy} />
            )}
          />
          <ClientRoute
            path="/client/:clientName/plans"
            render={(props) => <Page appHeight={appHeight} component={Plans} />}
          />
          <ClientRoute
            path={`/client/:clientName/register`}
            render={(props) => (
              <Page appHeight={appHeight} component={Registration} />
            )}
          />
          <ClientRoute
            path={`/client/:clientName/reservation/confirm-buyer/:token`}
            render={() => (
              <Page appHeight={appHeight} component={ConfirmBuyer} />
            )}
          />
          <ClientRoute
            path={`/client/:clientName/reservation/:uuid`}
            render={() => (
              <Page appHeight={appHeight} component={Reservation} />
            )}
          />
          <ClientRoute
            path={`/client/:clientName/unsubscribe`}
            render={(props) => (
              <Page appHeight={appHeight} component={Unsubscribe} />
            )}
          />
          <ClientRoute
            path="/client/:clientName"
            render={(props) => (
              <Page appHeight={appHeight} component={Landing} />
            )}
          />
          <Route
            render={() => <NoMatch bgImg={NO_MATCH_BG} msg={NO_MATCH_MSG} />}
          />
        </Switch>
      </Suspense>
    </Fragment>
  )
}

export default AppRoutes
