import { styled } from '@mui/material/styles'
import { CTAButton } from '../BuyerLoginChoicesDialog/BuyerLoginChoicesDialog.Styles'

export const classes = {
  active: 'active',
  top: 'top',
  bottom: 'bottom',
  loading: 'loading',
}

export const GAHolder = styled(CTAButton)({
  padding: '0',
})

export const GAFakeButton = styled(CTAButton)({
  pointerEvents: 'none',
  '& *': {
    pointerEvents: 'none',
    cursor: 'pointer',
  },
  height: 52,
  cursor: 'pointer',
  transform: 'none',
  border: 'none',
  margin: '1px 0',
  zIndex: 10,
  background: 'lightgray',
  [`&.${classes.active}`]: {
    background: 'white',
  },
  [`& .${classes.loading}`]: {
    position: 'absolute',
    inset: 0,
    background: 'rgba(255,255,255,.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    svg: {
      margin: 0,
    },
  },
})

export const GAButtonsHolder = styled('div')({
  position: 'absolute',
  zIndex: 5,
  maxHeight: '54px',
  overflow: 'hidden',
  top: '1px',
})

export const GAButton = styled('div')({
  [`&.${classes.bottom}`]: {
    position: 'relative',
    bottom: '32px',
  },
})
