import { Fragment } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

const AdblockMessage = ({ setAdblockMessage }) => {
  return (
    <Fragment>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: '#222',
            color: '#faebd7',
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'opaque',
            boxShadow: 'none',
          },
        }}
        open={true}
        onClick={() => {
          setAdblockMessage(false)
        }}
        aria-labelledby={`adblock-message-title`}
      >
        <DialogTitle id="adblock-message-title">
          {
            'Unable to login using Facebook, please make sure your ad blocker is turned off and try again.'
          }
        </DialogTitle>
      </Dialog>
    </Fragment>
  )
}

export default AdblockMessage
