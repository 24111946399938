import { useContext } from 'react'
import ReactDom from 'react-dom'
import LinearProgress from '@mui/material/LinearProgress'
import { LoadingSpinner } from '@anewgo/icons/dist'
import { StoreContext } from '../../store'
import { StyledLoading, LoadingText } from './LoadingStyles'

const Loading = () => {
  const { uiConfig } = useContext(StoreContext)
  const { appRef } = uiConfig
  if (!appRef) {
    return null
  }
  return ReactDom.createPortal(
    <StyledLoading>
      <LoadingSpinner />
      <div>
        <LoadingText variant="button">Loading...</LoadingText>
        <LinearProgress />
      </div>
    </StyledLoading>,
    appRef.current
  )
}

export default Loading
