import { styled } from '@mui/material/styles'

const PREFIX = 'NoMatch'

export const classes = {
  noMatchBg: `${PREFIX}-noMatchBg`,
  noMatchText: `${PREFIX}-noMatchText`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.noMatchBg}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },

  [`& .${classes.noMatchText}`]: {
    position: 'absolute',
    bottom: 100,
    textShadow: '-3px 1px 6px rgba(0, 0, 0, 1)',
    color: 'white',
    fontSize: 40,
    width: '100%',
    textAlign: 'center',
  },
}))

export default Root
