import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { ReservationStep } from '../constants/ReservationStep'
import { useApolloClient } from '@apollo/client'
import { useParams } from 'react-router'
import { gql } from '@apollo/client/core'
import { BuilderStatus } from '../constants/BuilderStatus'
import { restoreSelectionFromFavorite } from '../store/reducers'
import { StoreContext } from '../store'
import * as reducers from '../store/reducers'

const ReservationContext = createContext(null)

const getStepsByBuyer = (isPrimaryBuyer) => {
  const commonSteps = [
    ReservationStep.TermsAndConditions,
    ReservationStep.SignDocument,
    ReservationStep.Done,
  ]

  if (isPrimaryBuyer) {
    commonSteps.splice(2, 0, ReservationStep.MakePayment)
  }

  return commonSteps
}

export const ReservationProvider = ({
  favorite,
  isPrimaryBuyer,
  currentStep,
  builderStatus,
  children,
  client,
  reservationId,
  reservationSubData,
  primaryBuyerProspect,
  onlineReservation,
}) => {
  const steps = getStepsByBuyer(isPrimaryBuyer)
  const apolloClient = useApolloClient()
  const { dispatch } = useContext(StoreContext)
  const { clientName } = useParams()

  // INIT
  useEffect(() => {
    dispatch(
      reducers.setLastVisitedReservation({
        clientName,
        reservationId,
        isPrimaryBuyer,
        step: currentStep,
      })
    )
  }, [])

  // Reservation subscription
  useEffect(() => {
    if (reservationSubData) {
      const { getReservationUpdate: reservationInfo } = reservationSubData
      dispatch(
        reducers.setLastVisitedReservation({
          clientName,
          reservationId,
          isPrimaryBuyer,
          step: currentStep,
        })
      )
      apolloClient.writeFragment({
        id: `ReservationStatus:${reservationId}`,
        fragment: gql`
          fragment ReservationStatus on ReservationStatus {
            isPrimaryBuyer
            currentStep
            builderStatus
          }
        `,
        data: {
          // If new value from subscription is null/undefined we do not want to update these values
          // If new value is defined use it
          isPrimaryBuyer: reservationInfo.isPrimaryBuyer ?? isPrimaryBuyer,
          currentStep: reservationInfo.currentStep ?? currentStep,
          builderStatus: reservationInfo.builderStatus ?? builderStatus,
        },
      })
    }
  }, [reservationSubData])

  useEffect(() => {
    if (builderStatus !== BuilderStatus.IN_PROGRESS) {
      goToLastStep()
    }
  }, [builderStatus])

  useEffect(() => {
    dispatch(restoreSelectionFromFavorite(favorite))
  }, [favorite])

  const nextStep = () => {
    const currentIndex = steps.indexOf(currentStep)
    const newIndex = currentIndex + 1

    if (newIndex > steps.length) {
      throw Error('No more steps!')
    }

    dispatch(
      reducers.setLastVisitedReservation({
        clientName,
        reservationId,
        isPrimaryBuyer,
        step: steps[newIndex],
      })
    )

    apolloClient.writeFragment({
      id: `ReservationStatus:${reservationId}`,
      fragment: gql`
        fragment ReservationStatus on ReservationStatus {
          currentStep
        }
      `,
      data: {
        currentStep: steps[newIndex],
      },
    })
  }

  const goToLastStep = () => {
    dispatch(
      reducers.setLastVisitedReservation({
        clientName,
        reservationId,
        isPrimaryBuyer,
        step: ReservationStep.Done,
      })
    )
    apolloClient.writeFragment({
      id: `ReservationStatus:${reservationId}`,
      fragment: gql`
        fragment ReservationStatus on ReservationStatus {
          currentStep
        }
      `,
      data: {
        currentStep: ReservationStep.Done,
      },
    })
  }

  const value = useMemo(
    () => ({
      client,
      favorite,
      isPrimaryBuyer,
      builderStatus,
      steps,
      nextStep,
      activeStep: currentStep,
      reservationId,
      primaryBuyerProspect,
      onlineReservation,
    }),
    [
      favorite,
      isPrimaryBuyer,
      builderStatus,
      steps,
      nextStep,
      currentStep,
      primaryBuyerProspect,
      onlineReservation,
    ]
  )

  return (
    <ReservationContext.Provider value={value}>
      {children}
    </ReservationContext.Provider>
  )
}

export const useReservation = () => useContext(ReservationContext)
