import { styled } from '@mui/material/styles'
import Modal from '@mui/material/Modal'

const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export default StyledModal
