import DescriptionIcon from '@mui/icons-material/Description'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import PaymentIcon from '@mui/icons-material/Payment'
import HomeIcon from '@mui/icons-material/Home'
import { ReactComponent as TermsAndConditionImage } from '../assets/terms_and_conditions_step.svg'
import { ReactComponent as SignDocumentImage } from '../assets/sign_document.svg'
import { ReactComponent as DoneImage } from '../assets/done.svg'

export class ReservationStep {
  static TermsAndConditions = 'TERMS_AND_CONDITIONS'
  static SignDocument = 'SIGN_DOCUMENT'
  static MakePayment = 'MAKE_PAYMENT'
  static Done = 'DONE'

  static getReservationStepName = (reservationStep) => {
    switch (reservationStep) {
      case this.TermsAndConditions:
        return 'Terms and Conditions'
      case this.SignDocument:
        return 'Sign document'
      case this.MakePayment:
        return 'Make payment'
      case this.Done:
        return 'Done!'
      default:
        throw Error(`Reservation step ${reservationStep} does not exist!`)
    }
  }

  static getReservationStepIcon = (reservationStep) => {
    switch (reservationStep) {
      case this.TermsAndConditions:
        return <DescriptionIcon />
      case this.SignDocument:
        return <AssignmentIndIcon />
      case this.MakePayment:
        return <PaymentIcon />
      case this.Done:
        return <HomeIcon />
      default:
        throw Error(`Reservation step ${reservationStep} does not exist!`)
    }
  }

  static getReservationStepImage = (reservationStep) => {
    switch (reservationStep) {
      case this.TermsAndConditions:
        return <TermsAndConditionImage width={100} height={100} />
      case this.SignDocument:
        return <SignDocumentImage width={100} height={100} />
      case this.MakePayment:
        return null
      case this.Done:
        return <DoneImage width={100} height={100} />
      default:
        throw Error(`Reservation step ${reservationStep} does not exist!`)
    }
  }
}
