export const PAINTED_BRICK_MATERIAL_ID = 246
export const PAINTED_BRICK_NAME = 'Painted Brick'
export const BRICK_MATERIAL_ID = 12
export const PAINTED_BRICK_ROWLOCK_MATERIAL_ID = 277
export const PAINTED_BRICK_ROWLOCK_COLOR_NAME = 'Painted Brick Rowlock'
export const BRICK_AND_MASONRY_MATERIAL_IDS = [
  12, 18, 19, 22, 44, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221,
  222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 252, 254, 255,
]
export const MASONRY2_MATERIAL_ID = 19
