import { StrictMode, useContext, useEffect, useRef, useState } from 'react'
import { matchPath, withRouter } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { compose, setDisplayName } from 'react-recompose'
import Fingerprint2 from '@fingerprintjs/fingerprintjs'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import InformationalDialog from '@anewgo/informational-dialog'
import {
  addProspectFavorites,
  updateProspectFavorites,
} from './utils/favorites'
import './stylesheets/App.css'
import AppRoutes from './routing'
import * as reducers from './store/reducers'
import { withApolloProvider } from './graphql/enhancers'
import { withComponent } from './utils'
import { useClientName, useEventTracker, useGATrackingId } from './utils/hooks'
import { useResizeObserver, useWindowSize } from '@anewgo/hooks'
import { withThemeProvider } from './theme'
import { StoreContext, StoreProvider } from './store'
import {
  EXPANDED_HEADER_HT,
  HEADER_HT,
  ICON_SIZE,
  ICON_SIZE_MOBILE,
  MOBILE_BREAKPOINT,
  NO_MATCH_BG,
} from './constants'
import Loading from './components/Loading/Loading'
import ContactDialog from './components/ContactDialog/ContactDialog'
import DisclaimerDialog from './components/DisclaimerDialog/DisclaimerDialog'
import NoMatch from './routing/NoMatch/NoMatch'
import AuthenticatedDialog from './components/AuthenticatedDialog/AuthenticatedDialog'
import BuyerLoginChoicesDialog from './components/SignIn/BuyerLoginChoicesDialog/BuyerLoginChoicesDialog'
import AdblockMessage from './components/AdblockMessage/AdblockMessage'
import AppSnackBar from './components/AppSnackBar/AppSnackBar'
import AutoLogOutDialog from './components/AutoLogOutDialog/AutoLogOutDialog'
import { BuilderStatus } from './constants/BuilderStatus'
import AllowThirdPartyPackageDialog from './components/AllowThirdPartyPackageDialog/AllowThirdPartyPackageDialog'
import ConsentToNewClientLoginDialog from './components/ConsentToNewClientLoginDialog/ConsentToNewClientLoginDialog'
import { withClient } from './hocs'
import { tokenService, useProspectTokenChangeMonitor } from './services/token'
import { loadProspect } from './services/prospect'
import {
  hasPersistentStorageAccess,
  StorageProvider,
  useSessionState,
  // usePersistentState,
} from '@anewgo/storage'
import DisabledStorageDialog from './components/DisabledStorageDialog/DisabledStorageDialog'
/*
// Comment out Auth0, wait for 3.0 to upgrade
import { useAuth0 } from '@auth0/auth0-react'
import { useProspectSignIn } from './components/SignIn/BuyerLoginChoicesDialog/BuyerLoginChoicesDialog.Login'
import { handleAuth0Login } from './services/auth0'
import LoginNotificationDialog from './components/LoginNotificationDialog/LoginNotificationDialog'
import { handleFailedAuth } from './utils/handleFailedAuth'
import { useSetAuthToken } from './hooks/useSetAuthToken'
import NotVerifiedAccountMessage from './components/NotVerifiedAccountMessage/NotVerifiedAccountMessage'
import { useMutation } from '@apollo/client'
import { RESEND_VERIFY_EMAIL } from './graphql/auth'
import { Button } from '@mui/material'
*/

//
//
// A P P
//
const App = (props) => {
  const track = useEventTracker()
  const appRef = useRef(null)
  const { location, history, client } = props
  // const auth0 = useAuth0()
  // const { user, isAuthenticated, logout } = auth0
  useGATrackingId(client?.trackingId)
  useProspectTokenChangeMonitor()
  const {
    uiConfig,
    anonymousProspect,
    dispatch,
    favorites,
    prospect,
    selection,
    dialog: { isSelectionLockedDialogOpen, isLotBeingReservedDialogOpen },
  } = useContext(StoreContext)
  const { adblockMessageOpen } = uiConfig
  const theme = useTheme()
  const matchXs = useMediaQuery(theme.breakpoints.down('sm'))
  const matchSm = useMediaQuery(theme.breakpoints.down('md'))
  const clientName = useClientName(history, true)
  // const [auth0AccessToken, setAuth0AccessToken] = useState('')
  const [prevClientName, setPrevClientName] = useSessionState(
    'prevClientName',
    null
  )

  /*
  const [openFinishRegistrationDialog, setOpenFinishRegistrationDialog] =
    useState(false)
  const [preRegisteredProfile, setPreRegisteredProfile] = useState(null)
  const [isUserAuthLoading, setIsUserAuthLoading] = useState(false)
  const [redirectUrl, setRedirectUrl] = usePersistentState('redirectTo', ``)
  const sentVerifyEmail = useRef(false)

  const [resendEmail] = useMutation(RESEND_VERIFY_EMAIL)
  const sendVerifyEmail = (id) => {
    if (sentVerifyEmail.current) return
    sentVerifyEmail.current = true
    resendEmail({
      variables: {
        id,
      },
    })
      .then((res) => {
        if (res.data.resendVerifyEmail) {
          dispatch(
            reducers.setSnackBar({
              open: true,
              duration: 7500,
              message: 'The verification email has been sent.',
              type: 'info',
              position: { horizontal: 'center', vertical: 'top' },
            })
          )
        } else {
          dispatch(
            reducers.setSnackBar({
              open: true,
              duration: 7500,
              message: 'Failed to send the verification email.',
              type: 'info',
              position: { horizontal: 'center', vertical: 'top' },
            })
          )
        }
      })
      .catch(() => {
        dispatch(
          reducers.setSnackBar({
            open: true,
            duration: 7500,
            message: 'Failed to send the verification email.',
            type: 'info',
            position: { horizontal: 'center', vertical: 'top' },
          })
        )
      })
  }

  // Hook to listen to changes in Auth0 auth state and sets that token into state.
  useSetAuthToken(setAuth0AccessToken)
  useProspectSignIn(
    dispatch,
    track,
    clientName,
    uiConfig,
    setOpenFinishRegistrationDialog,
    setPreRegisteredProfile,
    setIsUserAuthLoading
  )
  // This effect sets our redirect path that will be used by the subsequent effect on user logout.
  // Without this effect and the next effect the user would be redirected to the base path (due to Auth0)
  // and then automatically routed to the Demo client. This works around that default Auth0 behavior.
  useEffect(() => {
    const token = tokenService.token()
    if (clientName && prospect && token) {
      setRedirectUrl(`/client/${clientName}`)
    }
  }, [clientName, prospect])

  // This effect is what handles redirecting a previously logged in user back to the client landing page/origin
  // when the user is logged out and automatically redirected to 'demo' client. This will automatically redirect
  // the user back and is necessary due to Auth0's implementation of callback routing that is set in the Auth0 Dashboard.
  useEffect(() => {
    const token = tokenService.token()
    if (redirectUrl && !prospect && !token) {
      history.push(redirectUrl)
      setRedirectUrl('')
    }
  }, [clientName])
  */

  const [displayStorageModal, setDisplayStorageModal] = useState(
    () => !hasPersistentStorageAccess()
  )

  /*
  useEffect(() => {
    let token = tokenService.token()
    if (token) return
    if (
      !auth0AccessToken ||
      !user ||
      !clientName ||
      !anonymousProspect ||
      !selection ||
      !dispatch ||
      !track
    ) {
      // we can not process the prospect
      return
    }
    // Do not log the user in. Log them out of Auth0 since they are not verified yet and notify the user of such.
    if (!user.email_verified || !isAuthenticated) {
      handleFailedAuth(
        dispatch,
        logout,
        <NotVerifiedAccountMessage
          sendVerifyEmail={() => sendVerifyEmail(user.sub)}
          logout={logout}
        />
      )
      return
    }
    setIsUserAuthLoading(true)
    handleAuth0Login({
      dispatch,
      accessToken: auth0AccessToken,
      selection,
      anonymousProspect,
      track,
      uiConfig,
      failedAuthLogOut: (message) =>
        handleFailedAuth(dispatch, logout, message),
    }).catch(() => {
      uiConfig.buyerAuthFailedCallback()
    })
  }, [auth0AccessToken, clientName, anonymousProspect, user])
  */

  // Reset the state if going to a new client
  // Ensures client data (selections, favorites) is not accidentally carried to different clients
  const pathname = location && location.pathname
  useEffect(() => {
    // If on builders page, or navigated to a new builder; reset the state.
    if (pathname === '/builders' || prevClientName !== clientName) {
      dispatch(reducers.resetState())
      setPrevClientName(clientName)
    }
  }, [dispatch, pathname, prevClientName])

  useEffect(() => {
    if (window.analytics && typeof window.analytics.user === 'function') {
      const anonymous_id = window.analytics.user().anonymousId()
      dispatch(reducers.setAnonymousProspect(anonymous_id))
    } else {
      Fingerprint2.getPromise().then(function (components) {
        const values = components.map(function (component) {
          return component.value
        })
        const murmur = Fingerprint2.x64hash128(values.join(''), 31)
        dispatch(reducers.setAnonymousProspect(murmur))
      })
    }
  }, [dispatch])

  // Loads prospect from token
  // Validates token using Fence
  useEffect(() => {
    if (clientName && !prospect) {
      let token = tokenService.token()
      if (token) {
        loadProspect({
          clientName,
          dispatch,
          selection,
          anonymousProspect,
          track,
          uiConfig,
          favorites,
          newSignIn: false,
          // failedAuthLogOut: (message) =>
          //  handleFailedAuth(dispatch, logout, message),
        })
      }
    }
  }, [clientName, location])

  useEffect(() => {
    dispatch(reducers.setAppRef(appRef))
  }, [appRef, dispatch])

  const favoritesString = favorites && JSON.stringify(favorites)
  const timeOutDuration = 2000
  let timeOutId = useRef(-1)
  let favoriteRef = useRef([])
  useEffect(() => {
    favoriteRef.current = favorites
    if (timeOutId.current === -1)
      timeOutId.current = setTimeout(() => {
        const favoritesToUpdate = favoriteRef.current.filter(
          (fav) => fav.updateToDB
        )
        if (
          prospect &&
          favoritesToUpdate.length &&
          favoritesToUpdate.length > 0
        ) {
          const getReservationSigningStatus = (favorite) => {
            const prospectReservationsCopy = [...prospect?.onlineReservations]
            const foundFavoriteReservation = prospectReservationsCopy.find(
              (res) => res.favoriteId === favorite.id && res.signatureDate
            )
            return (
              foundFavoriteReservation?.reservationStatus &&
              foundFavoriteReservation.reservationStatus !==
                BuilderStatus.REJECTED_BY_BUILDER &&
              foundFavoriteReservation.reservationStatus !==
                BuilderStatus.REJECTED_BY_PROSPECT
            )
          }
          const updateArray = []
          const addArray = []
          favoritesToUpdate.forEach(async (fav) => {
            const hasSignedReservationInProgress =
              getReservationSigningStatus(fav)
            if (fav.id && !hasSignedReservationInProgress) {
              updateArray.push(fav)
            } else if (fav.addToDb) {
              addArray.push(fav)
            }
          })
          const addFavs = async () => {
            const ids = await addProspectFavorites({
              clientName,
              email: prospect.email,
              favorites: addArray,
            })
            addArray.forEach((fav, index) => {
              dispatch(
                reducers.updateFavoriteAfterDBUpdate({
                  ...fav,
                  id: ids[index],
                  updateToDB: false,
                })
              )
              dispatch(reducers.restoreSelectionFromFavoritesList())
            })
          }
          const updateFavs = async () => {
            await updateProspectFavorites({
              clientName,
              updateArray,
            })
            updateArray.forEach((fav, index) => {
              dispatch(
                reducers.updateFavoriteAfterDBUpdate({
                  ...fav,
                  updateToDB: false,
                })
              )
            })
          }
          updateArray.length && updateArray.length > 0 && updateFavs()
          addArray.length && addArray.length > 0 && addFavs()
        }
        timeOutId.current = -1
      }, timeOutDuration)
  }, [favoritesString])

  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`)

  const isSmall = useMediaQuery('(max-width: 1279.95px)')
  const communityPlansPath =
    '/client/:clientName/community/:communityName/plans'
  const inventoryPath = '/client/:clientName/community/:communityName/inventory'
  const plansPath = '/client/:clientName/plans'
  const planPath = '/client/:clientName/community/:communityName/plan/:planName'
  const siteplanPath = '/client/:clientName/community/:communityName/siteplan'
  const unsubscribePath = '/client/:clientName/unsubscribe'
  const adaPolicyPatch = '/adaPolicy'

  const atSiteplanPage = matchPath(location.pathname, {
    path: siteplanPath,
    exact: true,
  })
  const atPlanPage = matchPath(location.pathname, {
    path: planPath,
    exact: true,
  })
  const atInventoryPage = matchPath(location.pathname, {
    path: inventoryPath,
    exact: true,
  })
  const atUnsubscribePage = matchPath(location.pathname, {
    path: unsubscribePath,
    exact: true,
  })
  const atCommunityPlansPage = matchPath(`${location.pathname}`, {
    path: communityPlansPath,
    exact: true,
  })
  const atPlansPage = matchPath(location.pathname, {
    path: plansPath,
    exact: true,
  })
  const atAdaPolicyPage = matchPath(location.pathname, {
    path: adaPolicyPatch,
    exact: true,
  })

  // on some pages, we need a taller header to accommodate for additional
  // information that will appear as a secondary header
  const needExpandedHeader =
    (atPlansPage && !matchSm) ||
    (atCommunityPlansPage && !matchSm) ||
    (atPlanPage && !matchSm)
  let headerHeight = HEADER_HT
  if (!matchXs && needExpandedHeader) {
    headerHeight =
      atSiteplanPage && !matchSm ? EXPANDED_HEADER_HT + 30 : EXPANDED_HEADER_HT
  }

  useEffect(() => {
    dispatch(
      reducers.setHeaderHeight(headerHeight),
      reducers.setIconSize(isSmall ? ICON_SIZE_MOBILE : ICON_SIZE),
      reducers.setMobile(isMobile),
      reducers.setSmall(isSmall)
    )
  }, [dispatch, isMobile, isSmall, headerHeight])

  const appSize = useWindowSize()
  const [, appHeight] = useResizeObserver(appRef)
  const height = appSize.height || appHeight
  const userAgent = window.navigator.userAgent

  if (userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Trident/') > 0) {
    return (
      <NoMatch
        bgImg={NO_MATCH_BG}
        msg={
          'Unfortunately, Internet Explorer is not supported. Please use a different browser.'
        }
      />
    )
  }
  // r e n d e r
  return (
    <div className="App" ref={appRef} style={{ height }}>
      {/*
        {(!anonymousProspect || isUserAuthLoading) && <Loading />}
        {anonymousProspect && (
          <AppRoutes
            height={height + (atPlanPage ? EXPANDED_HEADER_HT / 2 : 0)}
          />
        )}
        */}
      {!anonymousProspect && <Loading />}
      {anonymousProspect && (
        <AppRoutes
          height={height + (atPlanPage ? EXPANDED_HEADER_HT / 2 : 0)}
          setBuyerLoginDialog={compose(dispatch, reducers.setBuyerLoginDialog)}
        />
      )}
      <ContactDialog />
      {!atAdaPolicyPage && <DisclaimerDialog />}
      <AuthenticatedDialog />
      {adblockMessageOpen && (
        <AdblockMessage
          setAdblockMessage={compose(dispatch, reducers.setAdblockMessageOpen)}
        />
      )}
      {/*
        <BuyerLoginChoicesDialog
          openFinishRegistrationDialog={openFinishRegistrationDialog}
          setOpenFinishRegistrationDialog={setOpenFinishRegistrationDialog}
          preRegisteredProfile={preRegisteredProfile}
        />
        */}
      <BuyerLoginChoicesDialog />
      <AutoLogOutDialog />
      <AppSnackBar />
      <AllowThirdPartyPackageDialog />
      <ConsentToNewClientLoginDialog />
      {/*
        <LoginNotificationDialog />
        */}
      <InformationalDialog
        isInformationalDialogOpen={isSelectionLockedDialogOpen || false}
        dialogContent={[
          'Our records indicate that a reservation has been made on this favorite and that a reservation agreement has been entered.',
        ]}
        dialogTitle={'New selections are disabled for this favorite'}
        setIsInformationalDialogOpen={(arg) =>
          dispatch(reducers.setSelectionLockedDialogOpen(arg))
        }
        buttonText={'OK'}
      />
      <InformationalDialog
        isInformationalDialogOpen={isLotBeingReservedDialogOpen || false}
        dialogContent={[
          'Our records indicate that a reservation is being created for this lot. Please choose another one.',
        ]}
        dialogTitle={'This lot is being reserved'}
        setIsInformationalDialogOpen={(arg) =>
          dispatch(reducers.setLotIsBeingReservedDialogOpen(arg))
        }
        buttonText={'OK'}
      />
      <DisabledStorageDialog
        open={displayStorageModal}
        onClose={() => setDisplayStorageModal(false)}
      />
    </div>
  )
}

const WrappedComponent = compose(
  withComponent(StrictMode),
  setDisplayName('truss'),
  withApolloProvider,
  withComponent(Router),
  withClient,
  withThemeProvider,
  withComponent(StoreProvider),
  withComponent(StorageProvider),
  withRouter
)(App)

export default WrappedComponent
