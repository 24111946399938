export default class DisabledErrorModalOperations {
  static CreateNewReservation = 'CreateNewReservation'
  static AddAdditionalBuyerForReservation = 'AddAdditionalBuyerForReservation'
  static RejectOnlineReservationByProspect = 'RejectOnlineReservationByProspect'
  static AcceptReservationTermsAndConditions =
    'AcceptReservationTermsAndConditions'
  static CreateReservationPaymentUrl = 'CreateReservationPaymentUrl'
  static SignReservationDocument = 'SignReservationDocument'

  static getAllOperations() {
    return Object.values(DisabledErrorModalOperations)
  }
}
