import { useContext, useState, useMemo, useEffect } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import ClearIcon from '@mui/icons-material/Clear'
import EmailIcon from '@mui/icons-material/Email'
import Button from '@mui/material/Button'
import { matchPath, useHistory } from 'react-router'
import { pipe } from '@anewgo/functions'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import MobilePhoneIcon from '@mui/icons-material/StayCurrentPortrait'
import InputAdornment from '@mui/material/InputAdornment'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'
import { StoreContext } from '../../../store'
import * as reducers from '../../../store/reducers'
import LoginDialog from '../../LoginDialog/LoginDialog'
import PhoneMaskedInput from '../../PhoneMaskedInput/PhoneMaskedInput'
import { useEventTracker } from '../../../utils/hooks'
import { GoogleAccountsSignInButton } from '../GoogleAccountsSignInButton/GoogleAccountsSignInButton'
import {
  StyledCloseButton,
  CTAButton,
  FinishReservationDialogContent,
  GothamBookText,
  StyledDialogContent,
  BlockedDialogText,
  InfoMessage,
} from './BuyerLoginChoicesDialog.Styles'
import { ContactMethods } from '../../../constants/ContactMethods'
import { updateProspectContactInfos } from '../../../services/prospect'
import { tokenService } from '../../../services/token'
import { useProspectSignIn } from './BuyerLoginChoicesDialog.Login'
import { initMagicLinkAccounts } from '../../../services/magicLink'

import PersonIcon from '@mui/icons-material/Person'
import { processProspect } from '../../../utils/auth'

const CloseButton = ({ onClick }) => (
  <StyledCloseButton
    onClick={onClick}
    data-testid="buyer_login_choices_dialog_close_sign_in_button"
  >
    <ClearIcon />
  </StyledCloseButton>
)

const phoneRegex = /^\([0-9]{3}\)[ ]{0,1}[0-9]{3}-[0-9]{4}$/
const phoneValid = (phone) => phoneRegex.test(phone)

/*
function BuyerLoginChoicesDialog({
  openFinishRegistrationDialog,
  setOpenFinishRegistrationDialog,
  preRegisteredProfile,
}) {
*/
function BuyerLoginChoicesDialog() {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [isPasswordLess, setIsPasswordLess] = useState(false)
  const [nameError, setNameError] = useState(false)
  const { dispatch, selection, anonymousProspect, uiConfig, favorites } =
    useContext(StoreContext)
  // const { dispatch, selection, anonymousProspect, uiConfig, prospect } =
  // useContext(StoreContext)
  const { buyerLoginDialog, buyerAuthCallback, buyerAuthFailedCallback } =
    uiConfig
  // const { buyerAuthCallback } = uiConfig
  const [authBlocked, setAuthBlocked] = useState('')
  const [isBlockedDialogOpen, setIsBlockedDialogOpen] = useState(false)
  const [openFinishRegistrationDialog, setOpenFinishRegistrationDialog] =
    useState(false)
  const [loginPlatform, setLoginPlatform] = useState('')
  const [loginAccessToken, setLoginAccessToken] = useState(null)
  const [preRegistredProfile, setPreRegistredProfile] = useState(null)
  const [name, setName] = useState(preRegistredProfile?.name || null)
  // const [name, setName] = useState(preRegisteredProfile?.name || null)
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [emailPref, setEmailPref] = useState(false)
  const [phonePref, setPhonePref] = useState(false)
  const [textMePref, setTextMePref] = useState(false)
  const [conferencePref, setConferencePref] = useState(false)
  const [registerButtonEnabled, setRegisterButtonEnabled] = useState(false)
  const history = useHistory()
  const clientName = useMemo(
    () =>
      pipe(
        ({ pathname }) =>
          matchPath(pathname, {
            path: '/client/:clientName',
          }),
        (data) => data?.params?.clientName
      )(history.location),
    [history.location]
  )
  // const clientName = storage.getItem('clientName')
  const track = useEventTracker()
  // const { logout } = useAuth0()

  function closeDialog() {
    dispatch(reducers.setBuyerLoginDialog({ open: false }))
  }

  function unsetBuyerAuthCallbacks() {
    dispatch(reducers.setBuyerAuthCallback())
    dispatch(reducers.setBuyerAuthFailedCallback())
  }

  function handleClose() {
    buyerAuthFailedCallback()
    unsetBuyerAuthCallbacks()
    closeDialog()
  }

  /*
  const handleSignOut = async () => {
    track(EVT_USER_ALIAS, {
      lastUserId: prospect?.email || anonymousProspect,
      nextUserId: anonymousProspect,
      userAction: 'sign-out',
    })
    signOut({
      dispatch,
      clientName,
      uiConfig,
      isAutoLogOut: false,
      auth0Logout: () =>
        logout({ logoutParams: { returnTo: process.env.REACT_APP_TRUSS } }),
    })
  }
  */

  function handleBlockedDialogClose() {
    setAuthBlocked('')
    setIsBlockedDialogOpen(false)
  }

  const handleNameChange = (nameInput) => {
    setNameError(!nameInput?.trim().length)
    setName(!!nameInput?.trim().length ? nameInput : null)
  }

  const handleNameBlur = () => {
    setNameError(!name?.trim().length)
    setName((prevName) => (!!prevName?.trim().length ? prevName.trim() : null))
  }

  function handleMagicLogin() {
    setIsPasswordLess(true)
  }

  const handleMagicAuthLogin = async (profile) => {
    setPreRegistredProfile(profile)
    await initMagicLinkAccounts({
      setAuthBlocked,
      setIsBlockedDialogOpen,
      track,
      dispatch,
      anonymousProspect,
      selection,
      uiConfig,
      preRegistredProfile: profile,
    })
  }

  const finishRegistration = async () => {
    // Set the register button back to false after first click so that user can't multi click and receive multiple emails
    setRegisterButtonEnabled(false)
    const profile = preRegistredProfile
    profile.phone = phone
    profile.phonePref = phonePref
    profile.emailPref = emailPref
    profile.textMePref = textMePref
    profile.conferencePref = conferencePref

    // Conditionally add contact methods to the profile if this is a
    // first time registration.
    profile.preferredContactMethods = [
      ...(phonePref ? [ContactMethods.PHONE] : []),
      ...(emailPref ? [ContactMethods.EMAIL] : []),
      ...(textMePref ? [ContactMethods.TEXT] : []),
      ...(conferencePref ? [ContactMethods.VIDEO_CONFERENCE] : []),
    ]

    if (!loginAccessToken) {
      throw Error('Access Token was not specified!')
    }
    // Update the prospect contact methods and name (name for email login)
    const isUpdatedData = await updateProspectContactInfos(
      profile.id,
      profile.phone,
      profile.preferredContactMethods,
      name || profile.name
    )
    let token = tokenService.token()
    let prospectFirstName = token.prospect.firstName
    if (!!isUpdatedData) {
      if (!token.prospect.firstName && name.length) {
        prospectFirstName = name.split(' ')[0]
      }
      tokenService.setToken(
        token.provider,
        {
          ...token.prospect,
          name: name || profile.name,
          firstName: prospectFirstName,
        },
        token.value,
        token.exp,
        true
      )
      await processProspect(
        {
          ...token.prospect,
          name: name || profile.name,
          firstName: prospectFirstName,
          onlineReservations: [],
        },
        anonymousProspect,
        dispatch,
        track,
        clientName,
        true,
        true,
        selection,
        uiConfig
      )
    }
    token = tokenService.token()
    buyerAuthCallback(token.prospect)
    setOpenFinishRegistrationDialog(false)
  }

  const closeRegistrationDialog = () => {
    // handleSignOut()
    setOpenFinishRegistrationDialog(false)
  }

  const handlePhoneChange = (phoneInput) => {
    if (!phoneValid(phoneInput) && phone.replace(/\s/g, '').length > 3) {
      setPhoneError(true)
    }
    setPhone(phoneInput)
  }

  const handlePhoneClick = (phoneInput) => {
    phoneInput.preventDefault()
    if (phoneInput.target.value.match(/[^0-9]/g)) {
      phoneInput.target.setSelectionRange(1, 1)
    }
  }

  const handlePreferredContact = (contactMethod) => {
    switch (contactMethod) {
      case 'email': {
        setEmailPref(!emailPref)
        break
      }
      case 'phone': {
        setPhonePref(!phonePref)
        break
      }
      case 'textMe': {
        setTextMePref(!textMePref)
        break
      }
      case 'conference': {
        setConferencePref(!conferencePref)
        break
      }
      default:
        break
    }
  }

  useProspectSignIn(
    dispatch,
    track,
    setOpenFinishRegistrationDialog,
    setLoginAccessToken,
    setLoginPlatform,
    setPreRegistredProfile,
    clientName,
    uiConfig,
    buyerAuthCallback,
    buyerAuthFailedCallback,
    unsetBuyerAuthCallbacks,
    setName
  )

  useEffect(() => {
    const phoneRegex = /^\([0-9]{3}\)[ ]{0,1}[0-9]{3}-[0-9]{4}$/
    const phoneValid = phoneRegex.test(phone)
    const nameValid = !!name?.trim().length
    if (!phoneValid && phone.replace(/\s/g, '').length > 3) {
      setPhoneError(true)
    } else {
      setPhoneError(false)
    }

    if (
      !phoneValid ||
      !nameValid ||
      !(phonePref || textMePref || emailPref || conferencePref)
    ) {
      setRegisterButtonEnabled(false)
    } else {
      setRegisterButtonEnabled(true)
    }
  }, [phone, phonePref, textMePref, emailPref, conferencePref, name])

  // r e n d e r
  const renderContactMethods = () => {
    return (
      <FormControl margin="normal" variant="standard" component="fieldset">
        <FormLabel component="legend">Contact Me By</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                color={'primary'}
                checked={emailPref}
                onChange={() => handlePreferredContact('email')}
                value="email"
              />
            }
            label="Email"
          />
          <FormControlLabel
            control={
              <Checkbox
                color={'primary'}
                checked={phonePref}
                onChange={() => handlePreferredContact('phone')}
                value="phone"
              />
            }
            label="Phone"
          />
          <FormControlLabel
            control={
              <Checkbox
                color={'primary'}
                checked={textMePref}
                onChange={() => handlePreferredContact('textMe')}
                value="textMe"
              />
            }
            label="Text Me"
          />
          <FormControlLabel
            control={
              <Checkbox
                color={'primary'}
                checked={conferencePref}
                onChange={() => handlePreferredContact('conference')}
                value="conference"
              />
            }
            label="Video conference"
          />
        </FormGroup>
      </FormControl>
    )
  }
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={buyerLoginDialog.open}
        onClose={(ev) => handleClose(ev, true)}
        aria-labelledby="sign-in-dialog-title"
      >
        <CloseButton onClick={handleClose} />
        <DialogTitle id="sign-in-dialog-title">
          <GothamBookText variant="h6">Sign In / Register</GothamBookText>
        </DialogTitle>
        <StyledDialogContent>
          {buyerLoginDialog.message && (
            <InfoMessage severity="info">
              {buyerLoginDialog.message}
            </InfoMessage>
          )}

          <GoogleAccountsSignInButton />
          <CTAButton
            disableRipple
            style={{ marginBottom: '2rem' }}
            onClick={handleMagicLogin}
            data-testid="buyer_login_choices_dialog_continue_with_email_button"
          >
            <EmailIcon style={{ fill: '#222', width: 30, height: 30 }} />
            Continue with Email
          </CTAButton>
        </StyledDialogContent>
      </Dialog>
      <LoginDialog
        isOpen={isPasswordLess}
        setIsOpen={setIsPasswordLess}
        onLoginSubmit={handleMagicAuthLogin}
      />
      <Dialog
        open={openFinishRegistrationDialog}
        fullScreen={fullScreen}
        aria-labelledby="registrationComplete-dialog-title"
        onClose={closeRegistrationDialog}
      >
        <CloseButton onClick={closeRegistrationDialog} />
        <DialogTitle id="registrationComplete-dialog-title">
          <GothamBookText variant="h6">
            We noticed that this is your first time signing in with this email,
            please enter your phone and preferred contact method to complete the
            registration.
          </GothamBookText>
        </DialogTitle>
        <FinishReservationDialogContent>
          <form>
            <FormControl margin="normal" fullWidth={true}>
              <Input
                onChange={(ev) => handleNameChange(ev.target.value)}
                onBlur={handleNameBlur}
                id="user-name-input"
                fullWidth
                autoFocus
                value={name}
                error={nameError}
                placeholder={'First and Last Name'}
                startAdornment={
                  <InputAdornment position={'start'}>
                    <PersonIcon />
                  </InputAdornment>
                }
              />
              {nameError && (
                <FormHelperText error={nameError} id="user-email-help">
                  Enter a valid name
                </FormHelperText>
              )}
              {!nameError && <FormHelperText>Required</FormHelperText>}
            </FormControl>
            <FormControl
              required
              margin="normal"
              variant="standard"
              fullWidth={true}
            >
              <InputLabel error={phoneError} htmlFor="user-phone-input">
                Phone number
              </InputLabel>
              <Input
                style={{ alignItems: 'left' }}
                id="user-phone-input"
                aria-describedby="user-phone-help"
                error={phoneError}
                value={phone}
                fullWidth
                placeholder={'Phone number'}
                onClick={handlePhoneClick}
                onChange={(ev) => handlePhoneChange(ev.target.value)}
                onBlur={(ev) => handlePhoneChange(ev.target.value)}
                inputComponent={PhoneMaskedInput}
                startAdornment={
                  <InputAdornment position={'start'}>
                    <MobilePhoneIcon />
                  </InputAdornment>
                }
              />
              {phoneError && (
                <FormHelperText error={phoneError} id="user-phone-help">
                  Please enter a valid phone number
                </FormHelperText>
              )}
            </FormControl>
          </form>
          {renderContactMethods()}
        </FinishReservationDialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={finishRegistration}
            disabled={!registerButtonEnabled}
          >
            Register
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isBlockedDialogOpen}
        fullScreen={fullScreen}
        aria-labelledby="blocked-dialog-title"
        onClose={handleBlockedDialogClose}
      >
        <CloseButton onClick={handleBlockedDialogClose} />
        <DialogTitle id="blocked-dialog-title">
          <GothamBookText variant="h6">
            Do you have an Ad Blocker?
          </GothamBookText>
        </DialogTitle>
        <StyledDialogContent>
          <BlockedDialogText>
            {`The ${authBlocked} login script is blocked from loading. This is most
            likely caused by an Ad Blocker. Allow it in your Ad Blocker and
            refresh the window.`}
          </BlockedDialogText>
        </StyledDialogContent>
      </Dialog>
    </div>
  )
}

export default BuyerLoginChoicesDialog
