import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  Observable,
  split,
} from '@apollo/client'

import { getMainDefinition } from '@apollo/client/utilities'

import { createUploadLink } from 'apollo-upload-client'
import ReactDOM from 'react-dom'

import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
// add this in the future when we are certain our cloud infra is not causing
// any network problems.
// import { RetryLink } from '@apollo/client/link/retry'

import { AUTHENTICATE_API_KEY } from '../graphql'
import ApplicationErrorMessage from '../components/ApplicationErrorMessage/ApplicationErrorMessage'
import { absoluteSetInterval } from '../utils'
import DisabledErrorModalOperations from '../utils/disabledErrorModalOperations'
import { tokenService } from '../services/token'

const NEXUS_URI = process.env.REACT_APP_NEXUS_URI
const FLAGSHIP_API_KEY = process.env.REACT_APP_FLAGSHIP_API_KEY

const uniqueKeysByTypename = {
  Elevation: ['communityId', 'planId', 'id'],
  ElevationFilterTag: ['category', 'tag'],
  ElevationFloorplans: ['communityId', 'planId', 'elevationId'],
  // FloatRange: ['min', 'max'],
  Floorplan: ['id', 'groupId', 'standard', 'elevationId'],
  // IntRange: ['min', 'max'],
  InventoryExteriorConfiguration: ['inventoryId'],
  Layer: ['src'],
  LotSiteplanInfo: ['lotId'],
  MaterialPalette: ['communityId', 'planId', 'elevationId', 'materialId'],
  Palette: ['id', 'elevationId', 'materialId'],
  PaletteElementSelection: ['inventoryId', 'elementId'],
  Photo: ['src', 'listIndex'],
  Plan: ['communityId', 'id'],
  PlanElevation: ['planId', 'elevationId'],
  ProspectFavorite: ['id'],
  SiteplanInfo: ['siteplanId'],
  Story: ['id', 'communityId', 'planId', 'elevationId'],
}

const dataIdFromObject = (object) => {
  const { __typename } = object
  const uniqueKeys = uniqueKeysByTypename[__typename]
  if (uniqueKeys) {
    const uniqueValues = uniqueKeys.map((key) => object[key])

    uniqueValues.forEach((value) => {
      if (value === undefined) {
        throw new Error(
          `Encountered an object of type ${__typename} which was missing a required field. Unique keys: ${uniqueKeys}; unique values: ${uniqueValues}.`
        )
      }
    })

    const tuple = uniqueValues.join(':')
    return `${__typename}:${tuple}`
  }

  return defaultDataIdFromObject(object)
}

/*
const dataIdFromObject = object => {
  const { __typename } = object
  const hash = objectHash(object)
  return `${__typename}:${hash}`
}
*/

const cache = new InMemoryCache({
  dataIdFromObject,
  typePolicies: {
    Prospect: {
      fields: {
        favorites: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    ProspectFavorite: {
      fields: {
        interiorDesignSelections: {
          merge(existing, incoming) {
            return incoming
          },
        },
        fpOptSelections: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
  },
})

// https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
/**
 * Converts a promise into an Apollo Observable.
 * Usage: for a linkError, it can only accept a function that either returns
 * an Observable or void. In the case when an expired API token is detected
 * we need to refresh and fetch a new token via an async call within the linkError's
 * onError function.
 * @param {*} promise - a promise
 * @returns an Apollo Observable
 */
const promiseToObservable = (promise) =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return
        subscriber.next(value)
        subscriber.complete()
      },
      (err) => subscriber.error(err)
    )
    return subscriber // this line can removed, as per next comment
  })

// Module-level variable that the Apollo client's authLink will reference.
let apiToken
let apiTokenInterval

// see here https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
// used to trigger a retry if there is a network error
/*
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
})
*/

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // first, let's check an error is due to JWT token expiration
    let expiredToken = false
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        if (message.includes('jwt token expired')) {
          expiredToken = true
        }
      })
    }
    // if there is an expired token error, attempt to retry graphQL operation
    if (expiredToken) {
      // retry graphql operations, but let's reinitialize the api token
      console.log('detected expired token')
      if (apiTokenInterval) {
        // this avoids having more than 1 interval timer running
        // since calling initializeApiToken will call setInterval() again
        clearInterval(apiTokenInterval)
      }
      return promiseToObservable(initializeApiToken()).flatMap(() => {
        // got a new valid apiToken
        const oldHeaders = operation.getContext().headers
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${apiToken}`,
          },
        })
        // Retry the request, returning the new observable
        return forward(operation)
      })
    }
    // if we detect a network error
    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
      const container = document.createElement('div')
      const handleClose = () => {
        ReactDOM.unmountComponentAtNode(container)
      }
      ReactDOM.render(
        <ApplicationErrorMessage
          open={true}
          error={graphQLErrors}
          onClose={handleClose}
          errorType={'graphql'}
        />,
        container
      )
      // ideally, we should let retryLink handle it
      return
    }

    // if we reach here, we have some kind of graphQL error other than
    // expiration of token or network error
    if (
      graphQLErrors &&
      !DisabledErrorModalOperations.getAllOperations().includes(
        operation.operationName
      )
    ) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
      const container = document.createElement('div')
      const handleClose = () => {
        ReactDOM.unmountComponentAtNode(container)
      }
      ReactDOM.render(
        <ApplicationErrorMessage
          open={true}
          error={graphQLErrors}
          onClose={handleClose}
          errorType={'graphql'}
        />,
        container
      )
    }
  }
)

const httpLink = new createUploadLink({ uri: NEXUS_URI })

const authLink = new ApolloLink((operation, forward) => {
  const headers = {}

  if (apiToken) {
    headers['authorization'] = `Bearer ${apiToken}`
  }

  // Get prospect token from cookies
  const token = tokenService.token()
  if (token?.value) {
    headers['Anewgo-Auth-Prospect'] = `Bearer ${token.value}`
  }

  operation.setContext({ headers })

  // On every GraphQL request, conditionally add an authorization header if we have an API token.
  return forward(operation)
})

const websocketUri =
  process.env.REACT_APP_NEXUS_API_SUBSCRIPTIONS_SERVER ||
  'ws://localhost:5001/graphql'

const wsLink = new WebSocketLink({
  uri: websocketUri,
  options: {
    reconnect: true,
    // Note that we're assigning a function to connectionParams. This is to make sure we try reconnecting with the newly
    // signed-in user's token after we've failed to connect with the "null" token. See this github issue and solution:
    // https://github.com/apollographql/apollo-link/issues/197
    connectionParams: () => ({
      token: apiToken || '',
    }),
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

// Set up an Apollo client instance that will initially not have an API token in the header, and
// then always have it after retrieving it.
export const apolloClient = new ApolloClient({
  // this is ultimately what we should be using. But for now, we will not
  // use a retryLink as we want to ascertain the root cause for ASD-973
  // link: retryLink.concat(ApolloLink.from([authLink, errorLink, splitLink])),
  link: authLink.concat(ApolloLink.from([errorLink, splitLink])),
  cache,
})

function resetApiToken() {
  if (apiTokenInterval) {
    // this avoids having more than 1 interval timer running
    // since calling initializeApiToken will call setInterval() again
    clearInterval(apiTokenInterval)
  }
  initializeApiToken()
}

export const initializeApiToken = async () => {
  const { data } = await apolloClient.query({
    fetchPolicy: 'no-cache',
    query: AUTHENTICATE_API_KEY,
    variables: { apiKey: FLAGSHIP_API_KEY },
  })
  if (data.error) {
    console.error('Error initializing API token:', data.error)
    // if this was encountered before the DOM was rendered there is not
    // a whole lot we can but just send it to console.error
    apiToken = null
    apiTokenInterval = null
    return
  }
  // assumes token expires every 12-hours, as determined in the backend
  apiTokenInterval = absoluteSetInterval(resetApiToken, 42900000)
  apiToken = data.authenticateApiKey
}

export const withApolloProvider = (Component) => {
  const HoC = (props) => (
    <ApolloProvider client={apolloClient}>
      <Component {...props} />
    </ApolloProvider>
  )
  return HoC
}

export function getApiToken() {
  return apiToken
}
