export const snackBarTypes = {
  SET_SNACK_BAR: 'SET_SNACK_BAR',
}

export const initialSnackBarState = {
  open: false,
  message: 'Something went wrong! Try to reload page!',
  type: 'error',
  duration: 5000,
  position: { horizontal: 'right', vertical: 'bottom' },
  // callback: () => {},
}

export const snackBarReducer = (action) => (state) => {
  if (global.loggingEnabled) {
    console.log('uiReducer', { action, state })
  }

  switch (action.type) {
    case snackBarTypes.SET_SNACK_BAR:
      return {
        ...state,
        ...action.payload,
      }
    default:
      throw new Error('Unexpected action sent to ui reducer.', { action })
  }
}
