import { createRef } from 'react'
import { HEADER_HT, ALL_PLAN_TYPES, ALL_LOCATIONS_OPTION } from '../constants'
import { futureTimestamp } from '../utils'

export const uiActionTypes = {
  ASSIGN_CLOSING_DATE_FILTER: 'ASSIGN_CLOSING_DATE_FILTER',
  SET_BUYER_AUTH_CALLBACK: 'SET_BUYER_AUTH_CALLBACK',
  SET_BUYER_AUTH_FAILED_CALLBACK: 'SET_BUYER_AUTH_FAILED_CALLBACK',
  RESET_CLOSING_DATE_FILTER: 'RESET_CLOSING_DATE_FILTER',
  RESET_HOME_ATTRIBUTE_RANGES: 'RESET_HOME_ATTRIBUTE_RANGES',
  SET_FAV_MENU_OPEN: 'SET_FAV_MENU_OPEN',
  SET_FNUM: 'SET_FNUM',
  SET_MORTGAGE_CALCULATOR_OPEN: 'SET_MORTGAGE_CALCULATOR_OPEN',
  SET_STATE: 'SET_STATE',
  SET_STATUS_MENU_OPEN: 'SET_STATUS_MENU_OPEN',
  TOGGLE_EXTERIOR_MENU: 'TOGGLE_EXTERIOR_MENU',
  TOGGLE_FLOORPLAN_MENU: 'TOGGLE_FLOORPLAN_MENU',
  TOGGLE_PLANS_MENU: 'TOGGLE_PLANS_MENU',
  TOGGLE_ALL_PLANS_MENU: 'TOGGLE_ALL_PLANS_MENU',
  ADD_TGT_COMMUNITY: 'ADD_TGT_COMMUNITY',
  REM_TGT_COMMUNITY: 'REM_TGT_COMMUNITY',
  SET_TGT_COMMUNITIES: 'SET_TGT_COMMUNITIES',
  SET_FILTERED_COMMUNITIES: 'SET_FILTERED_COMMUNITIES',
  SET_TGT_REGION: 'SET_TGT_REGION',
  SET_TGT_CITIES: 'SET_TGT_CITIES',
  SET_USER_PROFILE: 'SET_USER_PROFILE', // used by LoginDialog
  SET_LOCAL_AGENT: 'SET_LOCAL_AGENT', // a locally set agent profile
}

export const initialUiState = {
  appRef: null,
  bathroomRange: [0, Number.MAX_SAFE_INTEGER],
  bedroomRange: [0, Number.MAX_SAFE_INTEGER],
  buyerLoginDialog: {
    open: false,
    message: '',
  },
  adblockMessageOpen: false,
  buyerAuthCallback: () => {},
  buyerAuthFailedCallback: () => {},
  closingDateFilter: null,
  communitiesMapBounds: {
    southBound: 0,
    eastBound: 0,
    offsetLat: 0,
    offsetLng: 0,
  },
  compareFavoritesOpen: false,
  contactDialogOpen: false,
  exteriorMenuOpen: false,
  favMenuOpen: false,
  floorplanMenuOpen: false,
  fnum: 1,
  headerHeight: HEADER_HT,
  highlightSgtLots: false,
  homeTypeFilter: ALL_PLAN_TYPES,
  iconSize: 40,
  subHeaderRef: null,
  loading: false,
  locationFilter: [ALL_LOCATIONS_OPTION],
  mirror: false,
  mobile: false,
  planNameFilter: null,
  plansMenuOpen: false,
  priceRange: [0, Number.MAX_SAFE_INTEGER],
  scrollableContainerRef: createRef(),
  signInDialogOpen: false,
  small: false,
  sqftRange: [0, Number.MAX_SAFE_INTEGER],
  tgtCommunities: [],
  filteredCommunities: null,
  tgtCities: [],
  tgtRegion: '',
  userProfile: null, // this comes from a form input, not db
  localAgent: null, // a locally set agent profile
}

export const uiReducer = (action) => (state) => {
  if (global.loggingEnabled) {
    console.log('uiReducer', { action, state })
  }

  switch (action.type) {
    case uiActionTypes.ASSIGN_CLOSING_DATE_FILTER:
      return {
        ...state,
        closingDateFilter: {
          daysOut: action.payload.daysOut,
          timestamp: futureTimestamp({ daysOut: action.payload.daysOut }),
        },
      }
    case uiActionTypes.SET_BUYER_AUTH_CALLBACK: {
      return {
        ...state,
        buyerAuthCallback: action.payload
          ? action.payload
          : initialUiState.buyerAuthCallback,
      }
    }
    case uiActionTypes.SET_BUYER_AUTH_FAILED_CALLBACK: {
      return {
        ...state,
        buyerAuthFailedCallback: action.payload
          ? action.payload
          : initialUiState.buyerAuthFailedCallback,
      }
    }
    case uiActionTypes.RESET_CLOSING_DATE_FILTER:
      return {
        ...state,
        closingDateFilter: initialUiState.closingDateFilter,
      }
    case uiActionTypes.RESET_HOME_ATTRIBUTE_RANGES:
      return {
        ...state,
        bathroomRange: initialUiState.bathroomRange,
        bedroomRange: initialUiState.bedroomRange,
        priceRange: initialUiState.priceRange,
        sqftRange: initialUiState.sqftRange,
      }
    case uiActionTypes.SET_FAV_MENU_OPEN:
      return {
        ...state,
        favMenuOpen: action.payload,
        mortgageCalculatorOpen: action.payload
          ? false
          : state.mortgageCalculatorOpen,
      }
    case uiActionTypes.SET_FNUM:
      return {
        ...state,
        fnum: action.payload,
      }
    case uiActionTypes.SET_MIRROR:
      return {
        ...state,
        mirror: action.payload,
      }
    case uiActionTypes.SET_MORTGAGE_CALCULATOR_OPEN:
      return {
        ...state,
        favMenuOpen: action.payload ? false : state.favMenuOpen,
        mortgageCalculatorOpen: action.payload,
      }
    case uiActionTypes.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case uiActionTypes.SET_STATUS_MENU_OPEN:
      return {
        ...state,
        favMenuOpen: action.payload ? false : state.favMenuOpen,
        mortgageCalculatorOpen: action.payload
          ? false
          : state.mortgageCalculatorOpen,
      }
    case uiActionTypes.SET_SIGN_IN_MENU_OPEN:
      return {
        ...state,
        favMenuOpen: action.payload ? false : state.favMenuOpen,
        mortgageCalculatorOpen: action.payload
          ? false
          : state.mortgageCalculatorOpen,
      }
    case uiActionTypes.TOGGLE_EXTERIOR_MENU:
      return {
        ...state,
        exteriorMenuOpen: !state.exteriorMenuOpen,
      }
    case uiActionTypes.TOGGLE_FLOORPLAN_MENU:
      return {
        ...state,
        floorplanMenuOpen: !state.floorplanMenuOpen,
      }
    case uiActionTypes.TOGGLE_PLANS_MENU:
      return {
        ...state,
        plansMenuOpen: !state.plansMenuOpen,
      }
    case uiActionTypes.ADD_TGT_COMMUNITY: {
      const { community } = action.payload
      const communities = state.tgtCommunities.concat([community])
      return {
        ...state,
        tgtCommunities: communities,
      }
    }
    case uiActionTypes.REM_TGT_COMMUNITY: {
      const { community } = action.payload
      const communities = state.tgtCommunities.filter(
        (comm) => comm.id !== community.id
      )
      return {
        ...state,
        tgtCommunities: communities,
      }
    }
    case uiActionTypes.SET_TGT_COMMUNITIES: {
      const { communities } = action.payload
      const tgtCommunities = [].concat(communities)
      return {
        ...state,
        tgtCommunities,
      }
    }
    case uiActionTypes.SET_FILTERED_COMMUNITIES: {
      const { communities } = action.payload
      const filteredCommunities = communities ? [].concat(communities) : null
      return {
        ...state,
        filteredCommunities,
      }
    }
    case uiActionTypes.SET_TGT_CITIES: {
      const { cities } = action.payload
      const tgtCities = [].concat(cities)
      return {
        ...state,
        tgtCities,
      }
    }
    case uiActionTypes.SET_TGT_REGION: {
      const { region } = action.payload
      return {
        ...state,
        tgtRegion: region,
      }
    }
    case uiActionTypes.SET_USER_PROFILE: {
      const { userProfile } = action.payload
      return {
        ...state,
        userProfile,
      }
    }
    case uiActionTypes.SET_LOCAL_AGENT: {
      const { localAgent } = action.payload
      return {
        ...state,
        localAgent,
      }
    }
    default:
      throw new Error('Unexpected action sent to ui reducer.', { action })
  }
}
