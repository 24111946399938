import { useQuery } from '@apollo/client'
import { Route, Redirect, useRouteMatch } from 'react-router-dom'
import { CLIENT_STATUS_BY_NAME } from '../graphql/index'
import Loading from '../components/Loading/Loading'

const ClientRoute = (props) => {
  const match = useRouteMatch('/client/:clientName')
  const clientName = match.params?.clientName

  const { loading, error, data } = useQuery(CLIENT_STATUS_BY_NAME, {
    variables: { clientName },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  if (loading) return <Loading />
  if (error || !clientName || data.clientByName?.status !== 'ACTIVE') {
    return <Redirect to="/404" />
  }

  return <Route {...props} />
}

export default ClientRoute
