import { Fragment, useContext } from 'react'
import { StoreContext } from '../../store'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import * as reducers from '../../store/reducers'

function AuthenticatedDialog() {
  const { authenticatedDialogOpen, authenticatedDialogMessage, dispatch } =
    useContext(StoreContext)

  const handleCloseDialog = () => {
    dispatch(reducers.closeAuthenticatedDialog())
  }

  return (
    <Fragment>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: '#222',
            color: '#faebd7',
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        open={authenticatedDialogOpen}
        onClick={handleCloseDialog}
        aria-labelledby={`Login success. ${authenticatedDialogMessage}`}
      >
        <DialogTitle id="welcome-dialog-title">
          {authenticatedDialogMessage}
        </DialogTitle>
      </Dialog>
    </Fragment>
  )
}

export default AuthenticatedDialog
