import { styled } from '@mui/material/styles'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import Alert from '@mui/material/Alert'

export const classes = {
  loading: 'loading',
}

export const StyledCloseButton = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  marginTop: 8,
  marginRight: 8,
  cursor: 'pointer',
  '& svg': {
    width: 20,
    height: 20,
    fill: '#333',
    '&:hover': {
      transform: 'scale(1.45)',
      transition: '0.4s all ease-in-out',
    },
  },
})

export const CTAButton = styled(ButtonBase)({
  textAlign: 'left',
  fontWeight: 500,
  fontSize: 18,
  paddingLeft: 16,
  borderRadius: 6,
  border: '1px solid #dadada',
  height: 54,
  minWidth: 300,
  marginTop: '1rem',
  transition: 'all  150ms ease-in',
  '& svg': {
    marginRight: 16,
  },
  '&:hover': {
    border: '1px solid #333',
  },
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [`& .${classes.loading}`]: {
    position: 'absolute',
    inset: 0,
    background: 'rgba(255,255,255,.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    svg: {
      margin: 0,
    },
  },
})

export const GothamBookText = styled(Typography)({
  marginTop: 32,
  fontFamily: 'Gotham Book, sans-serif',
  fontWeight: 'bold',
  textAlign: 'center',
})

export const FinishReservationDialogContent = styled(DialogContent)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
})

export const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
})

export const BlockedDialogText = styled(Typography)({
  marginBottom: '2rem',
  maxWidth: 300,
})

export const InfoMessage = styled(Alert)({
  textAlign: 'center',
  margin: '10px 24px 0',
  maxWidth: '268px',
  width: '100%',
})
