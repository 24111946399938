import { pipe } from '@anewgo/functions'

export const makeQuery = (url = '') => ({
  route: url,
  params: [],
})

export const addPath =
  (...items) =>
  (query) => ({
    ...query,
    route: `${query.route}/${items.join('/')}`,
  })

export const addParam =
  (key, ...values) =>
  (query) => ({
    ...query,
    params: [...query.params, [key, values.join()]],
  })

export const getUrlFromQuery = (query) => {
  const formatParam = ([key, value]) => `${key}=${value}`
  const paramsString = query.params.map(formatParam).join('&')
  return query.route + (paramsString && '?') + paramsString
}

export const executeQuery = pipe(getUrlFromQuery, fetch)
