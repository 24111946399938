export const dialogTypes = {
  SET_CONSENT_TO_NEW_CLIENT_LOGIN_DIALOG_OPEN:
    'SET_CONSENT_TO_NEW_CLIENT_LOGIN_DIALOG_OPEN',
  SET_ALLOW_THIRD_PARTY_PACKAGE_DIALOG_OPEN:
    'SET_ALLOW_THIRD_PARTY_PACKAGE_DIALOG_OPEN',
  SET_SELECTION_LOCKED_DIALOG_OPEN: 'SET_SELECTION_LOCKED_DIALOG_OPEN',
  SET_LOT_BEING_RESERVED_DIALOG_OPEN: 'SET_LOT_BEING_RESERVED_DIALOG_OPEN',
  // SET_LOGIN_NOTIFICATION_DIALOG_OPEN: 'SET_LOGIN_NOTIFICATION_DIALOG_OPEN',
}

export const initialDialogState = {
  isConsentToNewClientLoginDialogOpen: false,
  isAllowThirdPartyPackageDialog: false,
  isSelectionLockedDialogOpen: false,
  isLotBeingReservedDialogOpen: false,
  // isPopUpBlockedDialogOpen: false,
}

export const dialogReducer = (action) => (state) => {
  if (global.loggingEnabled) {
    console.log('dialogReducer', { action, state })
  }

  switch (action.type) {
    case dialogTypes.SET_CONSENT_TO_NEW_CLIENT_LOGIN_DIALOG_OPEN:
      return {
        ...state,
        isConsentToNewClientLoginDialogOpen: action.payload,
      }
    case dialogTypes.SET_ALLOW_THIRD_PARTY_PACKAGE_DIALOG_OPEN:
      return {
        ...state,
        isAllowThirdPartyPackageDialog: action.payload,
      }
    case dialogTypes.SET_SELECTION_LOCKED_DIALOG_OPEN:
      return {
        ...state,
        isSelectionLockedDialogOpen: action.payload,
      }
    case dialogTypes.SET_LOT_BEING_RESERVED_DIALOG_OPEN:
      return {
        ...state,
        isLotBeingReservedDialogOpen: action.payload,
      }
    /*
    case dialogTypes.SET_LOGIN_NOTIFICATION_DIALOG_OPEN:
      return {
        ...state,
        isPopUpBlockedDialogOpen: action.payload,
      }
    */
    default:
      throw new Error('Unexpected action sent to dialog reducer.', { action })
  }
}
