import { apply, getPath, identity, pipe } from '@anewgo/functions'
import { authActionTypes, authReducer, initialAuthState } from './auth'
import {
  favoritesActionTypes,
  favoritesReducer,
  initialFavoritesState,
} from './favorites'
import {
  selectionActionTypes,
  selectionReducer,
  initialSelectionState,
} from './selection'
import { uiActionTypes, uiReducer, initialUiState } from './ui'
import {
  initialSnackBarState,
  snackBarReducer,
  snackBarTypes,
} from './snackBar'
import { dialogReducer, dialogTypes, initialDialogState } from './dialog'
import { BuilderStatus } from '../constants/BuilderStatus'
import { getReservationStatus } from '../utils'
import { initialTrackState, trackReducer, trackTypes } from './track'

const reduceSnackBar = (action) => (state) => ({
  ...state,
  snackBarState: snackBarReducer(action)(state.snackBarState),
})

const reduceTrack = (action) => (state) => ({
  ...state,
  trackState: trackReducer(action)(state.trackState),
})

const reduceDialog = (action) => (state) => ({
  ...state,
  dialog: dialogReducer(action)(state.dialog),
})

const reduceAuth = (action) => (state) => ({
  ...state,
  authState: authReducer(action)(state.authState),
})

const reduceFavorites = (action) => (state) => ({
  ...state,
  favoritesState: favoritesReducer(action)(state.favoritesState),
})

const reduceSelection = (action) => (state) => ({
  ...state,
  selectionState: selectionReducer(action)(state.selectionState),
})

const reduceSelectedLot = (action) => (state) => ({
  ...state,
  selectionState: selectionReducer(action)(state.selectionState),
})

const reduceSelectionUsingState = (action) => (state) => ({
  ...state,
  selectionState: selectionReducer(action)({
    selectionState: state.selectionState,
    favoritesState: state.favoritesState,
  }),
})

const updateSelectionToFavorites = (state) => {
  return reduceFavorites({
    type: favoritesActionTypes.UPDATE_SELECTION,
    payload: state.selectionState,
  })(state)
}

const reduceUi = (action) => (state) => ({
  ...state,
  uiState: uiReducer(action)(state.uiState),
})

const getUiReducerByStateKey = (key) => (input) => {
  return reduceUi({
    type: uiActionTypes.SET_STATE,
    payload: { [key]: input },
  })
}

const initialState_unmerged = {
  authState: initialAuthState,
  favoritesState: initialFavoritesState,
  selectionState: initialSelectionState,
  uiState: initialUiState,
  snackBarState: initialSnackBarState,
  trackState: initialTrackState,
  dialog: initialDialogState,
}

export const initialState =
  apply(({ favorites }) =>
    reduceFavorites({
      type: favoritesActionTypes.MERGE_FAVORITES,
      payload: favorites,
    })(initialState_unmerged)
  )(initialAuthState.prospect) || initialState_unmerged

// Reset the state to initialState
export function resetState() {
  return (state) => ({
    ...initialState,
    // Keep anonymous prospect id
    authState: {
      ...initialState.authState,
      anonymousProspect: state.authState.anonymousProspect,
    },
    // Keep most fields from uiState, but remove fields containing
    // client specific data.
    uiState: {
      ...state.uiState,
      filteredCommunities: initialState.uiState.filteredCommunities,
      tgtCommunities: initialState.uiState.tgtCommunities,
      tgtCities: initialState.uiState.tgtCities,
      tgtRegion: initialState.uiState.tgtRegion,
    },
  })
}

export function startOver() {
  return (state) => ({
    ...state,
    uiState: {
      ...state.uiState,
      filteredCommunities: initialState.uiState.filteredCommunities,
      tgtCommunities: initialState.uiState.tgtCommunities,
      tgtCities: initialState.uiState.tgtCities,
      tgtRegion: initialState.uiState.tgtRegion,
    },
  })
}

//
//
// Track actions
//
export function setLastVisitedReservation(lastVisitedReservation) {
  return reduceTrack({
    type: trackTypes.SET_LAST_VISITED_RESERVATION,
    payload: lastVisitedReservation,
  })
}

//
//
// Auth actions
//
export function setSnackBar(snackBarOpt) {
  return reduceSnackBar({
    type: snackBarTypes.SET_SNACK_BAR,
    payload: snackBarOpt,
  })
}

export function setAllowThirdPartyPackageDialogOpen(isOpen) {
  return reduceDialog({
    type: dialogTypes.SET_ALLOW_THIRD_PARTY_PACKAGE_DIALOG_OPEN,
    payload: isOpen,
  })
}

export function setConsentToNewClientLoginDialogOpen(isOpen) {
  return reduceDialog({
    type: dialogTypes.SET_CONSENT_TO_NEW_CLIENT_LOGIN_DIALOG_OPEN,
    payload: isOpen,
  })
}

/*
export function setLoginNotificationDialogOpen(isOpen) {
  return reduceDialog({
    type: dialogTypes.SET_LOGIN_NOTIFICATION_DIALOG_OPEN,
    payload: isOpen,
  })
}
*/

export function setSelectionLockedDialogOpen(isOpen) {
  return reduceDialog({
    type: dialogTypes.SET_SELECTION_LOCKED_DIALOG_OPEN,
    payload: isOpen,
  })
}

export function setLotIsBeingReservedDialogOpen(isOpen) {
  return reduceDialog({
    type: dialogTypes.SET_LOT_BEING_RESERVED_DIALOG_OPEN,
    payload: isOpen,
  })
}

export function closeAuthenticatedDialog() {
  return reduceAuth({
    type: authActionTypes.CLOSE_AUTHENTICATED_DIALOG,
  })
}

export function openAuthenticatedDialog(message) {
  return reduceAuth({
    type: authActionTypes.OPEN_AUTHENTICATED_DIALOG,
    payload: message,
  })
}

export function setAnonymousProspect(prospect) {
  return reduceAuth({
    type: authActionTypes.SET_ANONYMOUS_PROSPECT,
    payload: prospect,
  })
}

export function setProspect(prospect, newProspectFavorites) {
  const prospectFavsWithReservationStatus = prospect?.favorites?.map((fav) => ({
    ...fav,
    reservationStatus: BuilderStatus.getBuilderStatusName(
      getReservationStatus(fav, prospect)
    ),
  }))
  return newProspectFavorites
    ? pipe(
        reduceAuth({
          type: authActionTypes.SET_PROSPECT,
          payload: prospect,
        }),
        reduceFavorites({
          type: favoritesActionTypes.REPLACE_FAVORITES,
          payload: prospectFavsWithReservationStatus || [],
        })
      )
    : reduceAuth({
        type: authActionTypes.SET_PROSPECT,
        payload: prospect,
      })
}

export function setIsProspectFetching(isProspectFetching) {
  return reduceAuth({
    type: authActionTypes.SET_IS_PROSPECT_FETCHING,
    payload: isProspectFetching,
  })
}

export function setProspectToken(prospectToken) {
  return reduceAuth({
    type: authActionTypes.SET_PROSPECT_TOKEN,
    payload: prospectToken,
  })
}

export function duplicateFavorites(newFavorites) {
  return reduceFavorites({
    type: favoritesActionTypes.DUPLICATE_FAVORITES,
    payload: newFavorites,
  })
}

export function addFavorite(favorite) {
  return reduceFavorites({
    type: favoritesActionTypes.ADD_FAVORITE,
    payload: favorite,
  })
}

export function mergeFavorites(newFavorites) {
  return reduceFavorites({
    type: favoritesActionTypes.MERGE_FAVORITES,
    payload: newFavorites,
  })
}

export function updateFavoriteAfterDBUpdate(fav) {
  return reduceFavorites({
    type: favoritesActionTypes.UPDATE_FAVORITE_AFTER_DB_UPDATE,
    payload: fav,
  })
}

export function setAnonymousFavoritesLoaded() {
  return reduceFavorites({
    type: favoritesActionTypes.ANONYMOUS_FAVORITES_LOADED,
  })
}

export function setDbFavoritesLoaded() {
  return reduceFavorites({
    type: favoritesActionTypes.DB_FAVORITES_LOADED,
  })
}

export function signIn() {
  return reduceAuth({
    type: authActionTypes.SIGN_IN,
  })
}

export function signOut() {
  return reduceAuth({
    type: authActionTypes.SIGN_OUT,
  })
}

//
//
// Favorites actions
//

export function deleteFavorite(favorite) {
  return reduceFavorites({
    type: favoritesActionTypes.DELETE_FAVORITE,
    payload: favorite,
  })
}

export function deleteSharedFavoritesLink(receivedFavorites) {
  return reduceAuth({
    type: authActionTypes.DELETE_SHARED_FAVORITES,
    payload: receivedFavorites,
  })
}

export function likeSelection(selection) {
  return reduceFavorites({
    type: favoritesActionTypes.LIKE_SELECTION,
    payload: selection,
  })
}

//
//
// Selection actions
//

export function clearColorSelections() {
  return pipe(
    reduceSelection({
      type: selectionActionTypes.CLEAR_COLOR_SELECTIONS,
    }),
    updateSelectionToFavorites
  )
}

export function restoreSelectionFromFavoritesList() {
  return reduceSelectionUsingState({
    type: selectionActionTypes.RESTORE_SELECTION_FROM_FAVORITES_LIST,
  })
}

export function resetSelectedLot() {
  return reduceSelectedLot({
    type: selectionActionTypes.RESET_SELECTION_LOT,
  })
}

export function clearHomesite() {
  return pipe(
    reduceSelection({
      type: selectionActionTypes.CLEAR_HOMESITE,
    }),
    updateSelectionToFavorites
  )
}

export function clearPlanElevationAndHomesite() {
  return reduceSelection({
    type: selectionActionTypes.CLEAR_PLAN_ELEVATION_AND_HOMESITE,
  })
}

export function clearSelection() {
  return reduceSelection({
    type: selectionActionTypes.CLEAR_SELECTION,
  })
}

export function resetDesignChoices() {
  return pipe(
    reduceSelection({
      type: selectionActionTypes.RESET_DESIGN_CHOICES,
    }),
    updateSelectionToFavorites
  )
}

export function restoreSelectionFromFavorite(favorite, mirror) {
  return pipe(
    reduceUi({
      type: uiActionTypes.SET_MIRROR,
      payload: mirror,
    }),
    reduceUi({
      type: uiActionTypes.SET_FNUM,
      payload:
        (favorite.elevation && (favorite.elevation.defaultFloor || 1)) || 1,
    }),
    reduceSelection({
      type: selectionActionTypes.RESTORE_SELECTION,
      payload: favorite,
    })
  )
}

export function selectCommunity(community) {
  return reduceSelection({
    type: selectionActionTypes.SELECT_COMMUNITY,
    payload: community,
  })
}

export function setSvgMirrored(isMirrored) {
  return reduceSelection({
    type: selectionActionTypes.SET_SVG_MIRRORING,
    payload: {
      isMirrored,
    },
  })
}

export function selectHome({
  plan: originalPlan,
  elevation: originalElevation,
  lot,
  community: originalCommunity,
  client: originalClient,
  clientName: originalClientName,
  mirror,
  garagePosition,
  favId,
  colorSelections,
  scheme,
  lastUpdate,
  fpOptSelections,
  interiorDesignSelections,
  ...rest
}) {
  const plan = originalPlan || getPath('inventory', 'plan')(lot)
  const elevation = originalElevation || getPath('inventory', 'elevation')(lot)
  // Check for community. In the case of 'adding' a favorite from the compare favorites page we need to
  // check for a community object to send with the favorite.
  const community = originalCommunity || null
  const client = originalClient || null
  const updatePlanElevation = plan && elevation
  const clientName = originalClientName || (client && client.altName) || null
  // If an inventory lot is being selected without an elevation assigned to it
  // clear the currently selected plan and elevation
  const clearPlanElevation =
    !updatePlanElevation &&
    lot.salesStatus === 'INVENTORY' &&
    lot.inventory &&
    lot.inventory.elevation === null
  const updateColorSelections = colorSelections
  const updateScheme = scheme
  const updateLastUpdate = lastUpdate
  const updateFpOptSelections = fpOptSelections
  const updateLot = lot
  const updateInteriorDesignSelections = !!interiorDesignSelections?.length
  return pipe(
    reduceUi({
      type: uiActionTypes.SET_MIRROR,
      payload: mirror || false,
    }),
    reduceUi({
      type: uiActionTypes.SET_FNUM,
      payload: (elevation && (elevation.defaultFloor || 1)) || 1,
    }),
    updatePlanElevation
      ? reduceSelection({
          type: selectionActionTypes.SELECT_PLAN_ELEVATION,
          payload: {
            plan,
            elevation,
            community,
            client,
            clientName,
            garagePosition,
            isMirrored: mirror,
            // If favId is present this is the favoriteId from the floorplan page URL
            // query parameters. We need to coerce it to an integer here for when we
            // check strict equality in restoreSelectionFromFavoritesList
            favId: parseInt(favId, 10),
            id: rest.id ?? undefined,
            ...rest,
          },
        })
      : identity,
    clearPlanElevation
      ? reduceSelection({
          type: selectionActionTypes.CLEAR_PLAN_ELEVATION_AND_HOMESITE,
          payload: {
            plan,
            elevation,
          },
        })
      : identity,
    updateLot
      ? reduceSelection({
          type: selectionActionTypes.SELECT_LOT,
          payload: lot,
        })
      : identity,
    updateFpOptSelections
      ? reduceSelection({
          type: selectionActionTypes.UPDATE_FP_OPT_SELECTIONS,
          payload: updateFpOptSelections,
        })
      : identity,
    updateScheme
      ? reduceSelection({
          type: selectionActionTypes.SELECT_SCHEME,
          payload: updateScheme,
        })
      : identity,
    updateLastUpdate
      ? reduceSelection({
          type: selectionActionTypes.UPDATE_LAST_UPDATE,
          payload: updateLastUpdate,
        })
      : identity,
    updateColorSelections
      ? reduceSelection({
          type: selectionActionTypes.UPDATE_COLOR_SELECTIONS,
          payload: updateColorSelections,
        })
      : identity,
    updateInteriorDesignSelections
      ? reduceSelection({
          type: selectionActionTypes.UPDATE_INTERIOR_DESIGN_SELECTIONS,
          payload: interiorDesignSelections,
        })
      : identity,
    updatePlanElevation || updateLot ? updateSelectionToFavorites : identity
  )
}

export function selectScheme(scheme) {
  return pipe(
    reduceSelection({
      type: selectionActionTypes.SELECT_SCHEME,
      payload: scheme,
    }),
    updateSelectionToFavorites
  )
}

export function updateLastUpdate(lastUpdate) {
  return pipe(
    reduceSelection({
      type: selectionActionTypes.UPDATE_LAST_UPDATE,
      payload: lastUpdate,
    }),
    updateSelectionToFavorites
  )
}

export function switchToMirrorElevation({
  mirror,
  mirrorElevation,
  fpOptSelections,
}) {
  return pipe(
    reduceUi({
      type: uiActionTypes.SET_MIRROR,
      payload: mirror,
    }),
    reduceSelection({
      type: selectionActionTypes.SET_GARAGE_POSITION,
      payload: {
        garagePosition: mirror
          ? mirrorElevation.garagePosition === 'LEFT'
            ? 'RIGHT'
            : mirrorElevation.garagePosition
          : mirrorElevation.garagePosition,
        isMirrored: mirror,
      },
    }),
    reduceSelection({
      type: selectionActionTypes.SELECT_MIRROR_ELEVATION,
      payload: mirrorElevation,
    }),
    reduceSelection({
      type: selectionActionTypes.UPDATE_FP_OPT_SELECTIONS,
      payload: fpOptSelections,
    }),
    updateSelectionToFavorites
  )
}

export function updateColorSelections(colorSelections) {
  return pipe(
    reduceSelection({
      type: selectionActionTypes.UPDATE_COLOR_SELECTIONS,
      payload: colorSelections,
    }),
    updateSelectionToFavorites
  )
}

export function replaceColorSelections(colorSelections) {
  return pipe(
    reduceSelection({
      type: selectionActionTypes.REPLACE_COLOR_SELECTIONS,
      payload: colorSelections,
    }),
    updateSelectionToFavorites
  )
}

export function updateInteriorDesignSelections(interiorDesignSelections) {
  return pipe(
    reduceSelection({
      type: selectionActionTypes.UPDATE_INTERIOR_DESIGN_SELECTIONS,
      payload: interiorDesignSelections,
    }),
    updateSelectionToFavorites
  )
}

export function updateMortgageCalculator(mortgage) {
  return pipe(
    reduceSelection({
      type: selectionActionTypes.UPDATE_MORTGAGE,
      payload: mortgage,
    }),
    updateSelectionToFavorites
  )
}

export function updateFpOptSelections(fpOptSelections) {
  return pipe(
    reduceSelection({
      type: selectionActionTypes.UPDATE_FP_OPT_SELECTIONS,
      payload: fpOptSelections,
    }),
    updateSelectionToFavorites
  )
}

//
//
// UI actions
//

export function assignClosingDateFilter({ daysOut }) {
  return reduceUi({
    type: uiActionTypes.ASSIGN_CLOSING_DATE_FILTER,
    payload: { daysOut },
  })
}

export function resetClosingDateFilter() {
  return reduceUi({
    type: uiActionTypes.RESET_CLOSING_DATE_FILTER,
  })
}

export function resetHomeAttributeRanges() {
  return reduceUi({
    type: uiActionTypes.RESET_HOME_ATTRIBUTE_RANGES,
  })
}

export const setAppRef = getUiReducerByStateKey('appRef')
export const setBathroomRange = getUiReducerByStateKey('bathroomRange')
export const setBedroomRange = getUiReducerByStateKey('bedroomRange')
export const setCommunitiesMapBounds = getUiReducerByStateKey(
  'communitiesMapBounds'
)
export const setContactDialogOpen = getUiReducerByStateKey('contactDialogOpen')
export const setIntroSignInDialogOpen =
  getUiReducerByStateKey('signInDialogOpen')
export const setBuyerLoginDialog = getUiReducerByStateKey('buyerLoginDialog')
export const setAdblockMessageOpen =
  getUiReducerByStateKey('adblockMessageOpen')
export function setBuyerAuthCallback(cb) {
  return reduceUi({
    type: uiActionTypes.SET_BUYER_AUTH_CALLBACK,
    payload: cb,
  })
}

export function setBuyerAuthFailedCallback(cb) {
  return reduceUi({
    type: uiActionTypes.SET_BUYER_AUTH_FAILED_CALLBACK,
    payload: cb,
  })
}

export function setFavMenuOpen(open) {
  return reduceUi({
    type: uiActionTypes.SET_FAV_MENU_OPEN,
    payload: open,
  })
}

export function setSignInMenuOpen(open) {
  return reduceUi({
    type: uiActionTypes.SET_SIGN_IN_MENU_OPEN,
    payload: open,
  })
}

export function setFnum(fnum) {
  return reduceUi({
    type: uiActionTypes.SET_FNUM,
    payload: fnum,
  })
}

export function setMirror(mirror) {
  return reduceUi({
    type: uiActionTypes.SET_MIRROR,
    payload: mirror,
  })
}

export const setHeaderHeight = getUiReducerByStateKey('headerHeight')
export const setHomeTypeFilter = getUiReducerByStateKey('homeTypeFilter')
export const setIconSize = getUiReducerByStateKey('iconSize')
export const setSubHeaderRef = getUiReducerByStateKey('subHeaderRef')
export const setLoading = getUiReducerByStateKey('loading')
export const setLocationFilter = getUiReducerByStateKey('locationFilter')
export const setMobile = getUiReducerByStateKey('mobile')
export const setMortgageCalculatorDownPayment = getUiReducerByStateKey(
  'mortgageCalculatorDownPayment'
)

export function setMortgageCalculatorOpen(open) {
  return reduceUi({
    type: uiActionTypes.SET_MORTGAGE_CALCULATOR_OPEN,
    payload: open,
  })
}

export const setMortgageCalculatorRate = getUiReducerByStateKey(
  'mortgageCalculatorRate'
)

export const setMortgageCalculatorTerm = getUiReducerByStateKey(
  'mortgageCalculatorTerm'
)

export const setMorgageCalculatorMortgage = getUiReducerByStateKey(
  'mortgageCalculatorMortgage'
)

export const setPlanNameFilter = getUiReducerByStateKey('planNameFilter')
export const setPriceRange = getUiReducerByStateKey('priceRange')
export const setHighlightSgtLots = getUiReducerByStateKey('highlightSgtLots')
export const setSmall = getUiReducerByStateKey('small')

export function setStatusMenuOpen(open) {
  return reduceUi({
    type: uiActionTypes.SET_STATUS_MENU_OPEN,
    payload: open,
  })
}

export const setSqftRange = getUiReducerByStateKey('sqftRange')

export function toggleExteriorMenu() {
  return reduceUi({
    type: uiActionTypes.TOGGLE_EXTERIOR_MENU,
  })
}

export function toggleFloorplanMenu() {
  return reduceUi({
    type: uiActionTypes.TOGGLE_FLOORPLAN_MENU,
  })
}

export function togglePlansMenu() {
  return reduceUi({
    type: uiActionTypes.TOGGLE_PLANS_MENU,
  })
}

export function addTgtCommunity(community) {
  return reduceUi({
    type: uiActionTypes.ADD_TGT_COMMUNITY,
    payload: { community },
  })
}

export function remTgtCommunity(community) {
  return reduceUi({
    type: uiActionTypes.REM_TGT_COMMUNITY,
    payload: { community },
  })
}

export function setTgtCommunities(communities) {
  return reduceUi({
    type: uiActionTypes.SET_TGT_COMMUNITIES,
    payload: { communities },
  })
}

export function setFilteredCommunities(communities = null) {
  return reduceUi({
    type: uiActionTypes.SET_FILTERED_COMMUNITIES,
    payload: { communities },
  })
}

export function setTgtRegion(region) {
  return reduceUi({
    type: uiActionTypes.SET_TGT_REGION,
    payload: { region },
  })
}

export function setTgtCities(cities) {
  return reduceUi({
    type: uiActionTypes.SET_TGT_CITIES,
    payload: { cities },
  })
}

export function setUserProfile(userProfile) {
  return reduceUi({
    type: uiActionTypes.SET_USER_PROFILE,
    payload: { userProfile },
  })
}

export function setLocalAgent(localAgent) {
  return reduceUi({
    type: uiActionTypes.SET_LOCAL_AGENT,
    payload: { localAgent },
  })
}
