import { useEffect } from 'react'
import { AuthProviders } from '../constants/AuthProviders'
import { tokenService } from './token'
import { __logRpcError, bootstrapProspect, magic } from '../utils/auth'
import { sessionStorage as storage } from '@anewgo/storage'

let loginCompletionEventHandlerFn = null

export const initMagicLinkAccounts = async (args) => {
  let isLoggedIn = false
  const tokenData = tokenService.token()
  try {
    isLoggedIn = await magic.user.isLoggedIn()
    if (isLoggedIn) {
      const { email } = await magic.user.getMetadata()
      if (email !== tokenData?.prospect?.email) {
        console.log('emails not matching', email, tokenData?.prospect?.email)
        await magic.user.logout() // gracefully logout
        isLoggedIn = false // do login again
      }
    }
    if (!isLoggedIn) {
      await magic.auth.loginWithMagicLink({
        email: args.preRegistredProfile.email,
        showUI: true,
      })
    }
    const oneDay = 60 * 60 * 24
    const accessToken = await magic.user.getIdToken({ lifespan: oneDay })

    const clientName = storage.getItem('clientName')
    await bootstrapProspect({
      clientName,
      dispatch: args.dispatch,
      selection: args.selection,
      anonymousProspect: args.anonymousProspect,
      track: args.track,
      authType: AuthProviders.MAGIC,
      accessToken,
      uiConfig: args.uiConfig,
      loginCompletionEventHandlerFn,
    })
  } catch (err) {
    console.log('Failed magic', err)
    __logRpcError(err)
  }
}

function subscribeMagicLinkAccountsCallback(completeLoginHandler) {
  loginCompletionEventHandlerFn = (isRegistered, prospect, token, authType) =>
    completeLoginHandler(isRegistered, prospect, token, authType)
}

function unsubscribeMagiLinkAccountsCallback() {
  loginCompletionEventHandlerFn = null
}

export function useMagicAccounts(handler) {
  useEffect(() => {
    subscribeMagicLinkAccountsCallback(handler)
    return () => {
      unsubscribeMagiLinkAccountsCallback()
    }
  }, [handler])
}
