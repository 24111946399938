import { useEffect, useState } from 'react'
import { compose } from 'react-recompose'
import createPalette from '@mui/material/styles/createPalette'
import createTypography from '@mui/material/styles/createTypography'
import chroma from 'chroma-js'
import { StyledEngineProvider } from '@mui/material/styles'
import createTheme from '@mui/material/styles/createTheme'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes'
import adaptV4Theme from '@mui/material/styles/adaptV4Theme'

import { withBuilderAppConfig, withClientCustomizations } from '../hocs'
import {
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
  DEFAULT_TERTIARY_COLOR,
} from '../constants'
import * as F from '@anewgo/functions'

const palette = createPalette('light')

let themeData = {
  primaryColor: null,
  secondaryColor: null,
  bannerColor: null,
  tertiaryColor: null,
  fontFamily: null,
  theme: null,
}

const getMemoizedTheme = ({
  primaryColor,
  secondaryColor,
  fontFamily,
  bannerColor,
  tertiaryColor,
  useFontsThroughoutApp,
}) => {
  if (
    themeData.primaryColor !== primaryColor ||
    themeData.secondaryColor !== secondaryColor ||
    themeData.fontFamily !== fontFamily ||
    themeData.bannerColor !== bannerColor ||
    themeData.tertiaryColor !== tertiaryColor
  ) {
    themeData.primaryColor = primaryColor
    themeData.secondaryColor = secondaryColor
    themeData.fontFamily = fontFamily
    themeData.bannerColor = bannerColor
    themeData.tertiaryColor = tertiaryColor
    const theme = createTheme(
      adaptV4Theme({
        palette: {
          primary: {
            light: chroma(primaryColor).brighten(2).hex(),
            main: primaryColor,
            dark: chroma(primaryColor).darken().hex(),
            contrastText: '#fff',
          },
          secondary: {
            light: chroma(secondaryColor).brighten(2).hex(),
            main: secondaryColor,
            dark: chroma(secondaryColor).darken().hex(),
            contrastText: '#fff',
          },
          bannerColor: {
            main: bannerColor,
          },
          tertiary: {
            main: tertiaryColor,
          },
        },
        typography: createTypography(palette, {
          useNextVariants: true,
          fontFamily: useFontsThroughoutApp
            ? fontFamily
            : 'Gotham Book, Avenir Light, sans-serif',
          h2: {
            fontFamily: useFontsThroughoutApp
              ? fontFamily
              : 'Gotham Book, Avenir Light, sans-serif',
          },
          h3: {
            fontFamily: useFontsThroughoutApp
              ? fontFamily
              : 'Gotham Book, Avenir Light, sans-serif',
          },
          h4: {
            fontFamily: useFontsThroughoutApp
              ? fontFamily
              : 'Gotham Book, Avenir Light, sans-serif',
          },
        }),
        breakpoints: {
          keys: ['xs', 'sm', 'md', 'lg', 'avg', 'xl', 'xxl', 'xxxl'],
          values: {
            xs: 0,
            sm: 640,
            md: 960,
            lg: 1280,
            avg: 1400,
            xl: 1920,
            xxl: 2560,
            xxxl: 3360,
          },
        },
      })
    )
    themeData.theme = responsiveFontSizes(theme)
  }
  return themeData.theme
}

export const withThemeProvider = (Component) => {
  const Wrapped = compose(withClientCustomizations)(
    withBuilderAppConfig((props) => {
      const fontsFromConfig = F.getPath(
        'theme',
        'font_family'
      )(props.builderAppConfig)
      const useFontsThroughoutApp = F.getPath(
        'theme',
        'mha_full_custom_font'
      )(props.builderAppConfig)
      const bgColorObj =
        props.clientCustomizations &&
        props.clientCustomizations.find((obj) => obj.name === 'bgColor')
      const bannerColor = bgColorObj
        ? `#${bgColorObj.value}`
        : DEFAULT_PRIMARY_COLOR
      const tertiaryColor = DEFAULT_TERTIARY_COLOR
      const [addedFonts, setAddedFonts] = useState([])
      const bgColorObjId = bgColorObj && bgColorObj.id
      useEffect(() => {
        if (!fontsFromConfig) return

        const fontsToAdd = fontsFromConfig.filter(
          ({ family }) => !addedFonts.some(F.propEquals('family', family))
        )
        const fontFacesToAdd = fontsToAdd.map(
          ({ family, src }) => new FontFace(family, `url(${src})`)
        )
        fontFacesToAdd.forEach((fontFace) => {
          document.fonts.add(fontFace)
          fontFace.load()
        })

        document.fonts.ready.then(() => {
          if (fontsToAdd.length === 0) return
          setAddedFonts([...addedFonts, fontsToAdd])
        })
      }, [fontsFromConfig, bgColorObjId])

      const primaryColor =
        F.pipe(
          F.getPath('theme', 'primary_color'),
          F.apply((color) => `#${color}`)
        )(props.builderAppConfig) || DEFAULT_PRIMARY_COLOR
      const secondaryColor =
        F.pipe(
          F.getPath('theme', 'secondary_color'),
          F.apply((color) => `#${color}`)
        )(props.builderAppConfig) || DEFAULT_SECONDARY_COLOR
      const fontFamily = fontsFromConfig
        ? fontsFromConfig.map(F.getProp('family')).join(', ')
        : 'Gotham Book, Avenir Light, sans-serif'

      const theme = getMemoizedTheme({
        primaryColor,
        secondaryColor,
        fontFamily,
        bannerColor,
        tertiaryColor,
        useFontsThroughoutApp,
      })

      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Component {...props} />
          </ThemeProvider>
        </StyledEngineProvider>
      )
    })
  )
  return Wrapped
}
