export class BuilderStatus {
  static IN_PROGRESS = 'IN_PROGRESS'
  static APPROVED_BY_BUILDER = 'APPROVED_BY_BUILDER'
  static REJECTED_BY_BUILDER = 'REJECTED_BY_BUILDER'
  static REJECTED_BY_PROSPECT = 'REJECTED_BY_PROSPECT'
  static RESERVATION_EXPIRED = 'RESERVATION_EXPIRED'

  static getBuilderStatusName = (builderStatus) => {
    switch (builderStatus) {
      case this.IN_PROGRESS:
        return 'In Progress'
      case this.APPROVED_BY_BUILDER:
        return 'Approved'
      case this.REJECTED_BY_PROSPECT:
        return 'Rejected by Buyer'
      case this.REJECTED_BY_BUILDER:
        return 'Rejected by Builder'
      case this.RESERVATION_EXPIRED:
        return 'Expired'
      default:
        return null
    }
  }
}
