import { styled } from '@mui/material/styles'
import { AccountCardVariant } from '../../../constants/AccountCardVariant'

const PREFIX = 'AccountCard'

export const classes = {
  avatar: `${PREFIX}-avatar`,
  text: `${PREFIX}-text`,
  name: `${PREFIX}-name`,
  email: `${PREFIX}-email`,
}

export default styled('div')(({ variant }) => ({
  display: 'flex',
  flexDirection:
    variant !== AccountCardVariant.ICON_TEXT_UNDER ? 'row' : 'column',
  alignItems: 'center',

  [`& .${classes.avatar}`]: {
    width: '2.5em',
    height: '2.5em',
    marginRight: variant === AccountCardVariant.ICON_TEXT && '0.5em',
    marginLeft: variant === AccountCardVariant.TEXT_ICON && '0.5em',
  },

  [`& .${classes.text}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: '1.1em',
  },

  [`& .${classes.name}`]: {
    width: '100%',
  },

  [`& .${classes.email}`]: {
    width: '100%',
    color: '#707070',
    fontSize: '0.8em',
  },
}))
