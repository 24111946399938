import * as reducers from '../../../store/reducers'
import { EVT_CLICKED_SIGN_IN } from '../../../constants/eventTracking'
import { setAutoLogout } from '../../../utils/auth'
import { useGoogleAccounts } from '../../../services/google'
import { useMagicAccounts } from '../../../services/magicLink'
import {
  AuthProviders,
  AuthTrackingMethods,
  AuthTrackingReferrers,
} from '../../../constants/AuthProviders'
/*
import { useAuth0Accounts } from '../../../services/auth0'
import { useAuth0 } from '@auth0/auth0-react'
import * as reducers from '../../../store/reducers'
*/

let authReferrer
let authMethod

const getTrackingReferrerAndMethod = (authType) => {
  switch (authType) {
    case AuthProviders.GOOGLE: {
      authReferrer = AuthTrackingReferrers.GOOGLE
      authMethod = AuthTrackingMethods.GOOGLE
      return
    }
    case AuthProviders.MAGIC: {
      authReferrer = AuthTrackingReferrers.MAGIC
      authMethod = AuthTrackingMethods.MAGIC
      return
    }
    default:
      return null
  }
}

// const authReferrer = 'auth0_accounts_callback'
// const authMethod = 'auth0'

export const useProspectSignIn = (
  dispatch,
  track,
  setOpenFinishRegistrationDialog,
  setLoginAccessToken,
  setLoginPlatform,
  setPreRegistredProfile,
  clientName,
  uiConfig,
  buyerAuthCallback,
  buyerAuthFailedCallback,
  unsetBuyerAuthCallbacks,
  setName
  // setOpenFinishRegistrationDialog,
  // setPreRegisteredProfile,
  // setIsUserAuthLoading
) => {
  const handleProspectLoginAccountsCallback = async (
    // const { buyerAuthCallback, buyerAuthFailedCallback } = uiConfig
    // const { logout } = useAuth0()
    // const handleProspectLoginAccountsCallback = (
    isRegistered,
    prospect,
    token,
    authType
  ) => {
    // Close the dialog
    dispatch(reducers.setBuyerLoginDialog({ open: false }))
    getTrackingReferrerAndMethod(authType)
    // Track sign-in button click
    track(EVT_CLICKED_SIGN_IN, {
      referrer: authReferrer,
      authMethod: authMethod,
    })

    try {
      if (!isRegistered) {
        setLoginPlatform(authType)
        // setIsUserAuthLoading(false)
        // setPreRegisteredProfile({ ...prospect })
        setOpenFinishRegistrationDialog(true)
        setLoginAccessToken(token.value)
        setPreRegistredProfile(Object.assign({}, prospect))
        if (prospect.name) {
          setName(prospect.name)
        }
        return
      }
      setOpenFinishRegistrationDialog(false)
      setAutoLogout(dispatch, clientName, uiConfig, token.exp)
      buyerAuthCallback(prospect)
      /*
      setIsUserAuthLoading(false)
      setAutoLogout(dispatch, clientName, uiConfig, token.exp, () =>
        logout({ logoutParams: { returnTo: process.env.REACT_APP_TRUSS } })
      )
      buyerAuthCallback() // call stored success callback
      */
    } catch (ex) {
      console.error('Error in handleProspectAccountsCallback', ex)
      buyerAuthFailedCallback()
      unsetBuyerAuthCallbacks()
      // dispatch(reducers.setBuyerAuthCallback()) // reset auth callback
      // buyerAuthFailedCallback() // call stored failure callback
    }
  }

  useGoogleAccounts(handleProspectLoginAccountsCallback)
  useMagicAccounts(handleProspectLoginAccountsCallback)
  // useAuth0Accounts(handleProspectLoginAccountsCallback)
}
