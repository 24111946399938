import { useContext, useEffect, useMemo, useState } from 'react'
import Fingerprint2 from '@fingerprintjs/fingerprintjs'
import { apply, getPath, pipe, getPlanName } from '@anewgo/functions'
import { siteplans, getProductSpecs } from '@anewgo/utils'
import { useLocation } from 'react-router-dom'
import { matchPath, useHistory, useParams, useRouteMatch } from 'react-router'
import {
  trackPage,
  useEventTracker as useEventTrackerOrig,
  useGATrackingId as useGATrackingIdOrig,
  usePageView as usePageViewOrig,
} from '@anewgo/tracking'
import { StoreContext } from '../../store'
import * as reducers from '../../store/reducers'
import {
  deleteFavoriteFromDB,
  isElevationExcluded,
  isSelectionInventory,
} from '../'
import { userIdentifier } from './../index'
import {
  EVT_DELETED_FAVORITE,
  EVT_LEAVE_RESERVATION_PAGE,
} from '../../constants/eventTracking'
import { BASE_PATH } from '../../routing'
import { useCurrentFavorites } from './../favorites'
import { useReservation } from '../../context/reservation.context'
import { useQuery } from '@apollo/client'
import { CLIENT } from '../../graphql'
import { useSessionState } from '@anewgo/storage'

const { isInventoryLot } = siteplans

export const useCommunitySelection = (community) => {
  const { selection, dispatch } = useContext(StoreContext)
  const communityId = community && community.id
  const selectionCommunityId =
    selection && selection.community && selection.community.id

  useEffect(() => {
    if (community?.id !== getPath('id')(selection.community)) {
      dispatch(reducers.selectCommunity(community))
    }
  }, [communityId, dispatch, selectionCommunityId])
}

export const useCommunityAndLotSelection = (community, lot, client) => {
  const { selection, dispatch } = useContext(StoreContext)

  const clientId = client && client.id
  const communityId = community && community.id
  const lotId = lot && lot.id
  const selectionCommunityId =
    selection && selection.community && selection.community.id
  const selectionLotId = selection && selection.lot && selection.lot.id

  useEffect(() => {
    if (
      community.id !== getPath('id')(selection.community) ||
      lot.id !== getPath('id')(selection.lot)
    ) {
      dispatch(
        pipe(
          reducers.selectCommunity(community),
          reducers.selectHome({ lot, client })
        )
      )
    }
  }, [
    clientId,
    communityId,
    dispatch,
    lotId,
    selectionCommunityId,
    selectionLotId,
  ])
}

export const useSelection = ({ community, plan, elevation, client, favId }) => {
  const { selection, dispatch, uiConfig, favorites, prospect } =
    useContext(StoreContext)
  const clientId = client && client.id
  const communityId = community && community.id
  const elevationId = elevation && elevation.id
  const planId = plan && plan.id
  const selectionCommunityId =
    selection && selection.community && selection.community.id
  const selectionElevationId =
    selection && selection.elevation && selection.elevation.id
  const selectionLotId = selection && selection.lot && selection.lot.id
  const selectionPlanId = selection && selection.plan && selection.plan.id
  const similarFavoriteExists =
    favorites?.length > 0 &&
    favorites.find(
      (fav) =>
        fav.elevationId === elevation.id &&
        fav.planId === plan.id &&
        fav.communityId === community.id
    )
  // when the community is the same but plan or elevation changes, do not remove the lot selection
  let updateLot =
    community.id === getPath('id')(selection.community) &&
    (plan.id !== getPath('id')(selection.plan) ||
      elevation.id !== getPath('id')(selection.elevation))
  // do not update lot if current plan/elevation is excluded
  if (
    isElevationExcluded(
      plan.id,
      elevation.id,
      selection?.lot?.excludedPlanElevations
    )
  ) {
    updateLot = false
  }

  useEffect(() => {
    if (
      community.id !== getPath('id')(selection.community) ||
      plan.id !== getPath('id')(selection.plan) ||
      elevation.id !== getPath('id')(selection.elevation) ||
      apply(isInventoryLot)(selection.lot)
    ) {
      const lotToUpdate = similarFavoriteExists
        ? similarFavoriteExists?.lot
        : selection.lot
      dispatch(reducers.selectCommunity(community))
      if (prospect && similarFavoriteExists) {
        dispatch(
          reducers.selectHome({
            ...similarFavoriteExists,
          })
        )
      } else {
        dispatch(
          reducers.selectHome({
            plan,
            elevation,
            client,
            mirror: uiConfig.mirror,
            garagePosition: elevation.garagePosition,
            lot: updateLot ? lotToUpdate : undefined,
            // If favId is present this is the favoriteId from the floorplan page URL
            // query parameters
            ...(favId && { favId }),
          })
        )
      }

      dispatch(reducers.restoreSelectionFromFavoritesList())
    }
  }, [
    clientId,
    communityId,
    dispatch,
    elevationId,
    planId,
    selectionCommunityId,
    selectionElevationId,
    selectionLotId,
    selectionPlanId,
  ])
}

export const useHomeIdentifier = () => {
  const location = useLocation()
  const { selection } = useContext(StoreContext)

  const queryParams = new URLSearchParams(location.search)
  const homeIdFromUrl = queryParams.get('homeId')

  const communityName = selection?.community?.name
  const communityId = selection?.community?.id
  const planName = selection?.plan?.name
  const planId = selection?.plan?.id
  const elevationCaption = selection?.elevation?.caption
  const elevationId = selection?.elevation?.id
  const lotId = selection?.lot?.id
  const duplicateNumber = selection?.duplicateNumber
  const lotInventoryId = selection?.lot?.inventory?.id

  return useMemo(() => {
    if (homeIdFromUrl) {
      return homeIdFromUrl
    }
    const values = [
      communityName,
      communityId,
      planName,
      planId,
      elevationCaption,
      elevationId,
      lotId,
      duplicateNumber,
    ]
    if (lotInventoryId) {
      values.push(lotInventoryId)
    }
    return Fingerprint2.x64hash128(values.join(''), 31)
  }, [
    communityName,
    communityId,
    planName,
    planId,
    elevationCaption,
    elevationId,
    lotId,
    duplicateNumber,
    lotInventoryId,
    homeIdFromUrl,
  ])
}

/**
 * Get basic information about reservation
 */
export const useBuyOnlineTrackingInformation = () => {
  const { selection } = useContext(StoreContext)
  const currentFavorites = useCurrentFavorites()
  const { uuid: reservationId } = useParams()
  const reservation = useReservation()

  const isInventory = !!useRouteMatch(`${BASE_PATH}/inventory/myhome`)?.isExact
  const isMyHomePage = !!useRouteMatch(`${BASE_PATH}/plan/:planName/myhome`)
    ?.isExact

  const isReservePage = !!useRouteMatch(
    '/client/:clientName/reserve/:favoriteId'
  )?.isExact

  const isReservationPage = !!useRouteMatch(
    '/client/:clientName/reservation/:uuid'
  )?.isExact

  if (isMyHomePage || isInventory || isReservePage) {
    return {
      clientName: selection?.clientName,
      favoriteId: currentFavorites[0]?.id,
    }
  }

  if (isReservationPage) {
    return {
      clientName: selection?.clientName,
      reservationId,
      isPrimaryBuyer: !!reservation?.isPrimaryBuyer,
    }
  }

  throw Error(
    `Hook useBuyOnlineTrackingInformation must be used at Reserve Online Pages`
  )
}

/**
 * Track if prospect leave reservation page
 */
export const useTrackLeaveReservationPage = () => {
  const location = useLocation()
  const [prevPathname, setPrevPathname] = useState(null)
  const {
    track: { lastVisitedReservation },
  } = useContext(StoreContext)
  const track = useEventTracker()

  useEffect(() => {
    const nextPath = location.pathname
    const reservationPath = '/client/:clientName/reservation/:uuid'
    const isNextPageRegister = matchPath(nextPath, {
      path: reservationPath,
      exact: true,
    })
    const isPrevPathRegister = matchPath(prevPathname, {
      path: reservationPath,
      exact: true,
    })

    if (isPrevPathRegister && !isNextPageRegister) {
      track(EVT_LEAVE_RESERVATION_PAGE, { location: lastVisitedReservation })
    }

    setPrevPathname(nextPath)
  }, [location.pathname])
}

export const usePageView = (title) => {
  const location = useLocation()

  const { pathname } = location

  const clientName = getClientNameFromRouter(pathname)
  const { prospect, anonymousProspect } = useContext(StoreContext)
  const user = useMemo(
    () => userIdentifier(prospect, anonymousProspect),
    [anonymousProspect, prospect]
  )

  return usePageViewOrig(title, pathname, clientName, user)
}

export const useEventTracker = () => {
  const location = useLocation()

  const { pathname } = location

  const clientName = getClientNameFromRouter(pathname)
  const { prospect, anonymousProspect } = useContext(StoreContext)
  return useEventTrackerOrig(prospect, anonymousProspect, clientName)
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return debouncedValue
}

function getClientNameFromRouter(pathname) {
  const matchResults = matchPath(pathname, {
    path: '/client/:clientName',
  })
  return matchResults?.params?.clientName
}

export const useGATrackingId = (trackingId) => {
  const location = useLocation()

  const { pathname } = location

  const clientName = getClientNameFromRouter(pathname)

  useGATrackingIdOrig(trackingId, clientName)
}

export const useRedirectToMyHomePage = (selectionOrFavorite) => {
  const { clientName } = useParams()
  const { prospect } = useContext(StoreContext)
  const history = useHistory()
  const homeId = useHomeIdentifier()
  const prospectId = userIdentifier(prospect)

  return () => {
    let paramPart = selectionOrFavorite?.plan
      ? `plan/${selectionOrFavorite?.elevation?.planName}`
      : 'inventory'

    let queryPart = isSelectionInventory(selectionOrFavorite)
      ? `inventoryId=${selectionOrFavorite?.lot?.inventory?.id}&lotId=${selectionOrFavorite?.lot?.id}`
      : `elevId=${selectionOrFavorite?.elevation?.id}`

    history.replace(
      `/client/${clientName}/community/${
        selectionOrFavorite?.community?.name
      }/${paramPart}/myhome?${queryPart}&prospectId=${encodeURIComponent(
        prospectId
      )}&homeId=${homeId}`
    )
  }
}

export const useDeleteFavorite = (favoriteToDelete, setFavoriteToDelete) => {
  const location = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const track = useEventTracker()
  const { dispatch, prospect } = useContext(StoreContext)
  const { clientName } = useParams()
  const redirectToMyHomePage = useRedirectToMyHomePage(favoriteToDelete)
  const isReservationPage = !!useRouteMatch(
    '/client/:clientName/reservation/:uuid'
  )?.isExact

  return () => {
    const trackingProps = {
      communityId: favoriteToDelete?.community
        ? favoriteToDelete.community.id
        : null,
      communityName: favoriteToDelete?.community
        ? favoriteToDelete.community.name
        : null,
      planId: favoriteToDelete?.plan ? favoriteToDelete.plan.id : null,
      planName: favoriteToDelete?.plan ? favoriteToDelete.plan.name : null,
      elevationId: favoriteToDelete?.elevation
        ? favoriteToDelete.elevation.id
        : null,
      elevationCaption: favoriteToDelete?.elevation
        ? favoriteToDelete.elevation.caption
        : null,
      lotId: favoriteToDelete?.lot ? favoriteToDelete.lot.id : null,
      lotName: favoriteToDelete?.lot ? favoriteToDelete.lot.name : null,
      lotAddress: favoriteToDelete?.lot ? favoriteToDelete.lot.address : null,
      inventoryId: favoriteToDelete?.lot?.inventory
        ? favoriteToDelete.lot.inventory.id
        : null,
      inventoryCaption: favoriteToDelete?.lot?.inventory
        ? favoriteToDelete.lot.inventory.caption
        : null,
    }

    track(EVT_DELETED_FAVORITE, {
      ...trackingProps,
      referrer: 'compare_favorites',
    })

    if (prospect) {
      deleteFavoriteFromDB(favoriteToDelete.id, clientName, prospect.id)
    }

    dispatch(reducers.deleteFavorite(favoriteToDelete))

    if (isReservationPage) {
      redirectToMyHomePage()
    }

    if (
      queryParams.has('favoriteId') &&
      queryParams.get('favoriteId') === favoriteToDelete.id.toString()
    ) {
      queryParams.delete('favoriteId')
      history.replace({
        search: queryParams.toString(),
      })
    }

    setFavoriteToDelete(null)
  }
}

export const useTitleAndDescription = (
  client,
  isInventoryWithNoPlanElevation
) => {
  const { prospect, selection } = useContext(StoreContext)
  const { community, elevation, plan, lot } = selection
  const { size, bed, bath } = getProductSpecs(selection)
  const specs = [size, bed, bath].filter(Boolean).join(' | ')

  const hasElevation = !isInventoryWithNoPlanElevation && elevation
  const hasCommunity = community?.bonafide
  const hasSpecs = specs.length > 0

  const title = [
    prospect?.name && `Can't wait to move in! ${prospect.name}'s new home`,
    !prospect?.name && `Super excited! This is my new home`,
    hasCommunity && ` in ${community.name}`,
  ]
    .filter(Boolean)
    .join('')

  const lotInfo = [
    lot?.name && `Lot ${lot.name}`,
    lot?.name?.size && ` | ${lot.size} ${community.primarySiteplan.lotMetric}`,
  ]
    .filter(Boolean)
    .join('')

  const description = [
    `Super excited! My new home is `,
    hasElevation && `a beautiful ${getPlanName(plan)} `,
    hasElevation && `${elevation.caption || ''}${elevation.caption ? ' ' : ''}`,
    hasCommunity && `built in ${community.name}`,
    hasCommunity && hasSpecs && `, with ${specs}`,
    hasCommunity && '. ',
    hasCommunity && `It is built by ${client.name}`,
    !hasCommunity && `built by ${client.name}`,
    lotInfo && ` on ${lotInfo}`,
    !hasCommunity && hasSpecs && `, with ${specs}`,
    `.`,
  ]
    .filter(Boolean)
    .join('')

  return [title, description]
}

export const useClientName = (history, save = false) => {
  // This is slightly wrong, but it was already like this previously.
  // Since we're not stringyfying the value, null becomes 'null' when retrieved.
  // It should work like this though.
  const [clientName, setClientName] = useSessionState('clientName', 'null')
  const [prevName, setPrevName] = useSessionState('prevClientName', 'null')

  const routeClient = useMemo(() => {
    const { pathname } = history.location
    return getClientNameFromRouter(pathname)
  }, [history.location])

  useEffect(() => {
    if (routeClient && routeClient !== clientName && save) {
      if (prevName !== 'null' && prevName !== 'undefined') {
        setPrevName(routeClient)
        setClientName(routeClient)
      } else {
        setPrevName(clientName)
        setClientName(routeClient)
      }
    }
  }, [routeClient, clientName, save])

  return routeClient
}

export const useClient = () => {
  const history = useHistory()
  const clientName = useClientName(history)

  const { data, loading, error } = useQuery(CLIENT, {
    skip: !clientName,
    variables: {
      clientName,
    },
  })

  const client = useMemo(() => data?.clientByName, [data, loading])
  return { client, loading, error }
}

export { trackPage }
