export const EXPANDED_HEADER_HT = 140
export const LANDING_PAGE_HEADER_HT = 80
export const MOBILE_LANDING_PAGE_HEADER_HT = 150
export const HEADER_HT = 70
export const MOBILE_BREAKPOINT = 768
export const DEFAULT_PRIMARY_COLOR = '#264653'
export const DEFAULT_SECONDARY_COLOR = '#E76F51'
export const DEFAULT_TERTIARY_COLOR = '#F4A261'
export const BUILDER_APP = 'BUILDER_APP'
export const COLOR_TOOL = 'COLOR_TOOL'

export const CLOUDINARY_FETCH_BASE_URL =
  'https://res.cloudinary.com/anewgo-brochures/image/fetch/'

export const ICON_SIZE = 36
export const ICON_SIZE_MOBILE = 30

export const DEFAULT_COMMUNITY_NAME = 'Default Assignments'

export const MAX_PLANCARD_MEDIA_HT = 476

export const MIN_LAT_DIFF = 0.05

export const MODAL_DRAWER_HEADER_HT = 60
export const INTERACTIVE_EXTERIOR_SECTION_PADDING = 60
export const PRODUCT_SECTION_HEADER_HT = 80
export const SIMPLE_PRODUCT_HEADER_HT = 96
export const PRODUCT_HEADER_HT_PADDING = 16
export const OPTIONS_HEADER_HT = 60
export const API_KEY = '376'

export const FAV_PLAN = 'FAV_PLAN'
export const FAV_ELEV = 'FAV_ELEV'
export const FAV_INVE = 'FAV_INVE'

// plan gallery filter constants
export const ALL_PLAN_TYPES = 'All Home Types'
export const QUICK_MOVE_IN_TYPE = 'Quick Move-In Homes'
export const NEW_DESIGN_TYPE = 'Design To Build'
export const ZERO_PRICE_TEXT = 'Contact Us for Pricing'
export const COMING_SOON_PRICE_TEXT = 'Coming soon'

export const ALL_LOCATIONS_ID = Number.MAX_SAFE_INTEGER

export const ALL_LOCATIONS_OPTION = {
  value: ALL_LOCATIONS_ID,
  label: 'All Locations',
}

export const FILTER_MIN_MAX_VALUE = [0, 9007199254740991]
export const DESIGN_EXTERIOR_INDEX = 0
export const DESIGN_FLOORPLAN_INDEX = 1
export const SELECT_HOMESITE_INDEX = 2
export const DESIGN_INTERIOR_INDEX = 3
export const VIEW_FLOORPLANS = 1

export const PRODUCT_OVERVIEW = 'PRODUCT_OVERVIEW'
export const FLOORPLAN_DESIGN = 'FLOORPLAN_DESIGN'
export const EXTERIOR_DESIGN = 'EXTERIOR_DESIGN'
export const HOMESITE_SELECT = 'HOMESITE_SELECT'
export const INTERIOR_DESIGN = 'INTERIOR_DESIGN'
export const CTA_SECTION = 'CTA_SECTION'

export const DEFAULT_FP_PAGE_SECTION_ORDER = [
  PRODUCT_OVERVIEW,
  FLOORPLAN_DESIGN,
  EXTERIOR_DESIGN,
  INTERIOR_DESIGN,
  HOMESITE_SELECT,
  CTA_SECTION,
]

export const DEFAULT_MORTGAGE_DISCLAIMER_TEXT =
  'Calculator is intended for estimation purposes only. Estimated payment comprises principal and interest only. Your actual mortgage payment may vary depending on credit, taxes and insurance, as well as other factors.'

export const DISPLAY_MORTGAGE_DISCLAIMER_TEXT =
  'The displayed mortgage payment is intended for estimation purposes only. Estimated payment comprises principal and interest only. Your actual mortgage payment may vary depending on credit, taxes and insurance, as well as other factors.'

export const NO_MATCH_BG =
  'https://res.cloudinary.com/renderinghouse/image/upload/f_auto,q_auto/v1541601055/anewgo/stock/dogs.jpg'
export const NO_MATCH_MSG = 'Oops. The page you are looking for is not found.'

export const clientPath = '/client/:clientName'
export const communityPath = clientPath + '/community/:communityName'
export const planPath = communityPath + '/plan/:planName'
