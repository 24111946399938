import { useContext, useEffect, useRef, useState } from 'react'
import { getGoogleAccounts } from '../../../services/google'
import { GoogleIcon } from '../GoogleIcon/GoogleIcon'
import {
  GAFakeButton,
  GAButtonsHolder,
  GAHolder,
  GAButton,
  classes,
} from './GoogleAccountsSignInButton.styles'
import { StoreContext } from '../../../store'
import { useEventTracker } from '../../../utils/hooks'
import { googleAccounts } from '../../../services/google'
import { CircularProgress } from '@mui/material'

export function GoogleAccountsSignInButton() {
  const btn1Ref = useRef(null)
  const btn2Ref = useRef(null)
  const [ready, setReady] = useState(false)
  const [btn1Ready, setBtn1Ready] = useState(false)
  const [btn2Ready, setBtn2Ready] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { selection, dispatch, uiConfig, anonymousProspect } =
    useContext(StoreContext)
  const track = useEventTracker()

  const renderGoogleButton = async () => {
    googleAccounts.setOnAuthInitHandler(() => {
      setIsLoading(true)
    })

    googleAccounts.setOnAuthFailedHandler(() => {
      setIsLoading(false)
    })

    if (btn1Ref.current === null || btn2Ref.current === null) return false

    const auth = await getGoogleAccounts({
      selection,
      dispatch,
      track,
      uiConfig,
      anonymousProspect,
    })
    if (auth == null) {
      console.error('Google Accounts API is null')
      return false
    }

    const btnOptions = {
      // customization attributes
      theme: 'outline',
      type: 'standard',
      shape: 'rectangular',
      size: 'large',
      text: 'continue_with',
      logo_alignment: 'right',
      width: 300,
      locale: 'en-US',
    }

    auth.renderButton(btn1Ref.current, btnOptions)
    auth.renderButton(btn2Ref.current, btnOptions)

    return true
  }

  const setBtn1AsReady = () => setBtn1Ready(true)
  const setBtn2AsReady = () => setBtn2Ready(true)
  const iframeEvent = 'load'

  // Triggers rendering of Google buttons using their api
  useEffect(() => {
    renderGoogleButton()
      .then((isRendered) => {
        if (isRendered) {
          // Set button as active after both buttons are rendered
          const iframe1 = btn1Ref.current.querySelector('iframe')
          const iframe2 = btn2Ref.current.querySelector('iframe')

          iframe1.addEventListener(iframeEvent, setBtn1AsReady)
          iframe2.addEventListener(iframeEvent, setBtn2AsReady)

          return () => {
            iframe1.removeEventListener(iframeEvent, setBtn1AsReady)
            iframe2.removeEventListener(iframeEvent, setBtn2AsReady)
          }
        }
      })
      .catch((err) => console.log(`Rendering of Google buttons failed: ${err}`))
  }, [])

  // Sets ready status to true when both buttons are ready
  useEffect(() => {
    if (btn1Ready && btn2Ready) setReady(true)
  }, [btn1Ready, btn2Ready])

  return (
    <GAHolder data-testid="continue_with_google_internal_button_holder">
      <GAFakeButton disableRipple className={ready ? classes.active : null}>
        {isLoading && (
          <div className={classes.loading}>
            <CircularProgress size={30} />
          </div>
        )}
        <GoogleIcon width={30} />
        Google Sign-In
      </GAFakeButton>
      <GAButtonsHolder>
        <GAButton
          ref={btn1Ref}
          className={classes.top}
          data-testid="continue_with_google_internal_button_1"
        />
        <GAButton
          ref={btn2Ref}
          className={classes.bottom}
          data-testid="continue_with_google_internal_button_2"
        />
      </GAButtonsHolder>
    </GAHolder>
  )
}
