import { Fragment, useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { StoreContext } from '../../store'
import * as reducers from '../../store/reducers'
import { AccountCard } from '../SignIn/Account/AccountCard'
import { AccountSource } from '../../constants/AccountSource'
import { AccountCardVariant } from '../../constants/AccountCardVariant'
import StyledDialogTitle, {
  classes,
} from './ConsentToNewClientLoginDialogStyle'
import { loadProspect } from '../../services/prospect'
import { useClient, useEventTracker } from '../../utils/hooks'
import { tokenService } from '../../services/token'

export default function ConsentToNewClientLoginDialog() {
  const {
    dialog: { isConsentToNewClientLoginDialogOpen },
    dispatch,
    uiConfig,
    anonymousProspect,
    selection,
    prospectToken,
  } = useContext(StoreContext)
  const track = useEventTracker()
  const { client } = useClient()

  if (!client?.name || !client?.altName) {
    return null
  }

  const cancelDialog = () => {
    dispatch(reducers.setConsentToNewClientLoginDialogOpen(false))
  }

  const registerWithClient = () => {
    if (prospectToken) {
      dispatch(reducers.setConsentToNewClientLoginDialogOpen(false))
      tokenService.resetValidation()
      loadProspect({
        clientName: client.altName,
        dispatch,
        selection,
        anonymousProspect,
        track,
        uiConfig,
        newSignIn: true,
        hasConsent: true,
      })
    }
  }

  return (
    <Fragment>
      <Dialog
        open={isConsentToNewClientLoginDialogOpen}
        aria-labelledby="form-dialog-title"
      >
        <StyledDialogTitle id="form-dialog-title">
          <AccountCard
            source={AccountSource.TOKEN}
            variant={AccountCardVariant.ICON_TEXT}
          />
          <span className={classes.space}>
            Your account is currently signed in with different builder.
          </span>
          <span className={classes.line}>
            Would you like to also register your account with {client.name} so
            you can save their homes as favorites?
          </span>
        </StyledDialogTitle>
        <DialogActions>
          <Button onClick={cancelDialog} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={registerWithClient}
            color="primary"
            variant="contained"
          >
            Register
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
