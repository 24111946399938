import 'core-js/stable'
import ReactDOM from 'react-dom'
import 'react-image-gallery/styles/css/image-gallery.css'
import once from 'lodash.once'

// import { Auth0Provider } from '@auth0/auth0-react'
import './stylesheets/index.css'
import App from './App'
import { initTracker } from '@anewgo/tracking'
import { initializeApiToken } from './graphql/enhancers'
import * as serviceWorker from './serviceWorker'

// Uncomment to see informative logs whenever the store context updates.
// global.loggingEnabled = true

let timeoutID
const __renderApp = once(() => {
  clearTimeout(timeoutID)
  timeoutID = 0
  console.log(`version: ${process.env.REACT_APP_VERSION}`)

  ReactDOM.render(<App />, document.getElementById('root'))
  /*
  // Comment out auth0, since we will wait for MHA 3.0 to upgrade.
  ReactDOM.render(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_TRUSS,
      }}
    >
      <App />
    </Auth0Provider>,
    document.getElementById('root')
  )
  */
})

const __initApp = async () => {
  try {
    const enableAnalytics = process.env.REACT_APP_TRACK_ANALYTICS
      ? process.env.REACT_APP_TRACK_ANALYTICS.toLowerCase() === 'true'
      : false

    const enableGA = process.env.REACT_APP_TRACK_GA
      ? process.env.REACT_APP_TRACK_GA.toLowerCase() === 'true'
      : false

    const enableSegment = process.env.REACT_APP_TRACK_SEGMENT
      ? process.env.REACT_APP_TRACK_SEGMENT.toLowerCase() === 'true'
      : false

    const enableSnowplow = process.env.REACT_APP_TRACK_SNOWPLOW
      ? process.env.REACT_APP_TRACK_SNOWPLOW.toLowerCase() === 'true'
      : false

    const segmentKey = process.env.REACT_APP_SEGMENT_WRITE_KEY

    const snowplowAppId = process.env.REACT_APP_SNOWPLOW_APP_ID
    const snowplowServer = process.env.REACT_APP_SNOWPLOW_SERVER

    await initTracker({
      disabled: !enableAnalytics,
      ga: {
        disabled: !enableGA,
      },
      segment: {
        disabled: !enableSegment,
        key: segmentKey,
      },
      snowplow: {
        disabled: !enableSnowplow,
        appId: snowplowAppId,
        server: snowplowServer,
      },
    })
    await initializeApiToken()
    __renderApp()
  } catch (ex) {
    console.log('failed to initialize tracker', ex)
    __renderApp() // unconditionally
  }
}
__initApp() // initialize app

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
