import { matchPath, withRouter } from 'react-router'
import { graphql } from '@apollo/client/react/hoc'
import {
  compose,
  fromRenderProps as recompose_fromRenderProps,
} from 'react-recompose'
import gql from 'graphql-tag'
import queryString from 'query-string'

import { either, identity, pipe, propEquals } from '@anewgo/functions'
import { BUILDER_APP_CONFIG, CLIENT, LOT, PLAN } from '../graphql'
import { elevation } from '../graphql/commonFields'
import { clientPath, communityPath, planPath } from '../constants'
//
//
// Not-HOCS
//

export const graphqlProps =
  (
    dataKey,
    {
      optional = false,
      mapResultsToProps = (results) => ({ [dataKey]: results }),
      debugIdentifier,
      mapResultsToDebugValue,
    } = {}
  ) =>
  (results) => {
    const debugIdentifierText = `utils.graphqlProps(${debugIdentifier || ''})`
    const { data, ownProps } = results

    if (data.loading) return { loading: true }
    if (data.error) {
      console.error(
        `${debugIdentifierText}: Error loading GraphQL:`,
        data.error
      )
      return { error: data.error }
    }

    const dataKeyResults = data[dataKey]
    if (!optional && !dataKeyResults) {
      console.error(
        `${debugIdentifierText}: Error loading GraphQL (empty data):`,
        results
      )
      return { error: true }
    }

    if (debugIdentifier) {
      console.log(
        debugIdentifierText,
        (mapResultsToDebugValue || identity)(dataKeyResults)
      )
    }

    return mapResultsToProps(dataKeyResults, ownProps)
  }

//
//
// Queries
//

const inventoryPath = communityPath + '/inventory'

// XXX: profile this function. It may be a performance bottleneck.
const getMatch = ({ path, history } = {}) => {
  return matchPath(history.location.pathname, { path })
}

export const withClient = compose(
  withRouter,
  graphql(CLIENT, {
    skip: ({ history }) => !getMatch({ history, path: clientPath }),
    options: ({ history }) => ({
      variables: {
        clientName: getMatch({ history, path: clientPath }).params.clientName,
      },
    }),
    props: graphqlProps('clientByName', {
      mapResultsToProps: (client) => {
        return { client }
      },
    }),
  })
)

export const withBuilderAppConfig = compose(
  withRouter,
  graphql(BUILDER_APP_CONFIG, {
    skip: ({ history }) => !getMatch({ history, path: clientPath }),
    options: ({ history }) => ({
      variables: {
        clientName: getMatch({ history, path: clientPath }).params.clientName,
      },
    }),
    props: graphqlProps('builderAppConfig', {
      optional: true,
    }),
  })
)

export const withClientCustomizations = compose(
  withRouter,
  graphql(BUILDER_APP_CONFIG, {
    skip: ({ history }) => !getMatch({ history, path: clientPath }),
    options: ({ history }) => ({
      variables: {
        clientName: getMatch({ history, path: clientPath }).params.clientName,
      },
    }),
    props: graphqlProps('clientCustomizations', {
      optional: true,
    }),
  })
)

export const withCommunity = (query, options) =>
  compose(
    withRouter,
    graphql(query, {
      skip: ({ history }) => !getMatch({ history, path: communityPath }),
      options: ({ history }) => {
        const match = getMatch({ history, path: communityPath })

        return {
          variables: {
            clientName: match.params.clientName,
            communityName: match.params.communityName,
          },
          ...options,
        }
      },
      props: graphqlProps('communityByName', {
        mapResultsToProps: (community) => ({ community }),
      }),
    })
  )

export const withLot = compose(
  withRouter,
  graphql(LOT, {
    skip: ({ location }) => {
      const lotId = pipe(queryString.parse, ({ lotId }) =>
        lotId === undefined ? undefined : parseInt(lotId, 10)
      )(location.search)
      return !lotId
    },
    options: ({ history, location }) => {
      const match =
        getMatch({ history, path: planPath }) ||
        getMatch({ history, path: inventoryPath })
      const lotId = pipe(queryString.parse, ({ lotId }) =>
        lotId === undefined ? undefined : parseInt(lotId, 10)
      )(location.search)

      return {
        variables: {
          clientName: match.params.clientName,
          lotId: lotId,
        },
      }
    },
    props: graphqlProps('lot', {
      mapResultsToProps: (lot) => ({
        lot,
        inventory: lot.inventory,
      }),
    }),
  })
)

export const withPlanElevation = compose(
  withRouter,
  graphql(PLAN, {
    skip: ({ history }) => !getMatch({ history, path: planPath }),
    options: ({ history }) => {
      const match = getMatch({ history, path: planPath })

      return {
        variables: {
          clientName: match.params.clientName,
          communityName: match.params.communityName,
          planName: match.params.planName,
        },
      }
    },
    props: graphqlProps('planByName', {
      mapResultsToProps: (plan, ownProps) => {
        const obj = queryString.parse(ownProps.location.search)
        const elevationId =
          obj.elevId === undefined ? undefined : parseInt(obj.elevId, 10)

        return {
          plan,
          elevation:
            elevationId &&
            plan.elevations.find((elev) => elev.id === elevationId),
          defaultElevation:
            plan.elevations.find(
              (elev) => elev.id === plan.defaultElevationId
            ) || plan.elevations[0],
        }
      },
    }),
  })
)

export const withActivePlanElevation = compose(
  withRouter,
  graphql(PLAN, {
    skip: ({ history }) => !getMatch({ history, path: planPath }),
    options: ({ history }) => {
      const match = getMatch({ history, path: planPath })

      return {
        variables: {
          clientName: match.params.clientName,
          communityName: match.params.communityName,
          planName: match.params.planName,
          active: true,
        },
      }
    },
    props: graphqlProps('planByName', {
      mapResultsToProps: (plan, ownProps) => {
        const elevationId = pipe(queryString.parse, ({ elevId }) =>
          elevId === undefined ? undefined : parseInt(elevId, 10)
        )(ownProps.location.search)

        return {
          plan,
          elevation:
            elevationId && plan.elevations.find(propEquals('id', elevationId)),
          defaultElevation:
            plan.elevations.find(propEquals('id', plan.defaultElevationId)) ||
            plan.elevations[0],
        }
      },
    }),
  })
)

//
//
// Other exports
//

export const fromRenderProps =
  (RenderPropsComponent, options) => (Component) => {
    switch (typeof options) {
      case 'function':
        return recompose_fromRenderProps(
          RenderPropsComponent,
          options
        )(Component)
      case 'object':
        const { injectProps, mapRenderProps } = options

        return (props) => (
          <RenderPropsComponent {...(injectProps && injectProps(props))}>
            {(...args) => (
              <Component
                {...{
                  ...props,
                  ...mapRenderProps(props)(...args),
                }}
              />
            )}
          </RenderPropsComponent>
        )
      default:
        throw new Error(
          'Attempted to call fromRenderProps with an options argument that was neither a function nor an object',
          {
            RenderPropsComponent,
            Component,
            options,
          }
        )
    }
  }

export const withFields = ({
  query,
  skip = () => false,
  options,
  props,
  requiredProps,
} = {}) => {
  const rules = []
  Object.keys(requiredProps).forEach((propName) => {
    rules.push([
      propName,
      gql(`
        fragment Fragment on _ {
          ${requiredProps[propName]}
        }
      `),
    ])
  })

  return graphql(query, {
    skip: either(skip, (props) =>
      rules.every(([propName, fragment]) =>
        containsFragment(props[propName], fragment)
      )
    ),
    options,
    props,
  })
}

//
//
// Helpers for withFields
//

export function containsFragment(context, fragment) {
  const {
    definitions: [{ selectionSet }],
  } = fragment
  return context && containsSelectionSet(context, selectionSet)
}

function containsSelectionSet(context, selectionSet) {
  const { selections } = selectionSet
  return selections.every((field) => containsField(context, field))
}

function containsField(context, field) {
  const keys = Object.keys(context)
  const {
    name: { value: fieldName },
    selectionSet,
  } = field
  const { [fieldName]: value } = context

  if (!keys.includes(fieldName)) return false
  if (value === null || !selectionSet) return true

  if (Array.isArray(value)) {
    return value.every((subValue) =>
      containsSelectionSet(subValue, selectionSet)
    )
  }

  return containsSelectionSet(value, selectionSet)
}
