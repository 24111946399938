import { apply, getPath } from '@anewgo/functions'
import { siteplans } from '@anewgo/utils'
import { useContext } from 'react'
import { StoreContext } from '../store'
import { BuilderStatus } from '../constants/BuilderStatus'
import { useParams } from 'react-router-dom'
import { apolloClient } from '../graphql/enhancers'
import { ADD_PROSPECT_FAVORITES, UPDATE_PROSPECT_FAVORITES } from '../graphql'

const { isInventoryLot } = siteplans

let hasSimilarReservationInProgress = false

export const uniqueIndexForFavorite = ({
  community,
  plan,
  elevation,
  lot,
  garagePosition,
  duplicateNumber,
}) => {
  if (apply(isInventoryLot)(lot)) {
    return `Inventory:${lot.inventory.id}`
  }

  const communityId = getPath('id')(community) || null
  const planId = getPath('id')(plan) || null
  const elevationId = getPath('id')(elevation) || null

  return `${communityId}:${planId}:${elevationId}:${
    garagePosition ? garagePosition : null
  }:${duplicateNumber ? duplicateNumber : 0}`
}

export const useCurrentFavorites = () => {
  const { selection, favorites } = useContext(StoreContext)
  const duplicateFavorites = favorites?.filter(
    (fav) =>
      fav.id &&
      fav?.lot?.id === selection?.lot?.id &&
      fav?.planId === selection?.plan?.id &&
      fav?.elevationId === selection?.elevation?.id &&
      fav?.communityId === selection?.community?.id
  )
  if (duplicateFavorites.length > 1) {
    // If no duplicate favorite has a reservation return the newest favorite on login.
    if (
      duplicateFavorites.every(
        (duplicateFavorite) => !duplicateFavorite?.reservationStatus
      )
    ) {
      hasSimilarReservationInProgress = false
      return [duplicateFavorites[duplicateFavorites.length - 1]]
    } else {
      // If a duplicate favorite has a reservation return the favorite with a reservation.
      hasSimilarReservationInProgress = true
      return duplicateFavorites.filter(
        (duplicateFavorite) => !!duplicateFavorite?.reservationStatus
      )
    }
  }
  // There is only one duplicate favorite, return that single favorite.
  hasSimilarReservationInProgress = false
  return duplicateFavorites
}

export const useCurrentFavoriteReservation = (favoriteId) => {
  const { prospect, favorites } = useContext(StoreContext)
  const { clientName } = useParams()
  const currentFavorites = useCurrentFavorites()
  const prospectReservations = prospect?.onlineReservations ?? []

  const foundRes = prospectReservations.find((onlineRes) => {
    if (favoriteId) {
      return onlineRes.favoriteId === favoriteId
    } else {
      return favorites.some(
        (fav) =>
          fav.id === onlineRes.favoriteId &&
          currentFavorites.some(
            (currFav) => currFav?.id === onlineRes.favoriteId
          )
      )
    }
  })
  const isReservationInProgressOrApproved =
    !!foundRes?.reservationStatus &&
    foundRes.reservationStatus !== BuilderStatus.REJECTED_BY_BUILDER &&
    foundRes.reservationStatus !== BuilderStatus.REJECTED_BY_PROSPECT &&
    foundRes.reservationStatus !== BuilderStatus.RESERVATION_EXPIRED

  const reservationLink = foundRes
    ? `/client/${clientName}/reservation/${foundRes.id}`
    : null

  return {
    reservation: foundRes,
    reservationLink,
    isReservationInProgressOrApproved,
    hasSimilarReservationInProgress,
  }
}

export function updateProspectFavorites({ clientName, updateArray }) {
  return apolloClient
    .mutate({
      mutation: UPDATE_PROSPECT_FAVORITES,
      variables: {
        clientName,
        input: updateArray.map((fav) => {
          return {
            id: fav?.id,
            communityId: fav?.community?.id,
            planId: fav?.plan?.id,
            elevationId: fav?.elevation?.id,
            lotId: fav?.lot?.id,
            schemeId: fav?.scheme?.id,
            colorSelections: fav?.colorSelections,
            fpOptSelections: fav?.fpOptSelections,
            interiorDesignSelections: JSON.stringify(
              fav?.interiorDesignSelections
            ),
            source: 'truss',
            garagePosition: fav?.garagePosition,
            isMirrored: fav?.isMirrored,
            mortgage: fav?.mortgage && {
              downPayment: fav.mortgage.downPayment,
              downPercent: fav.mortgage.downPercent,
              interest: fav.mortgage.interest,
              term: fav.mortgage.term,
            },
          }
        }),
      },
    })
    .then((response) => {
      return response?.data?.updateProspectFavorites
    })
}

export function addProspectFavorites({ clientName, email, favorites }) {
  return apolloClient
    .mutate({
      mutation: ADD_PROSPECT_FAVORITES,
      variables: {
        clientName,
        email,
        favorites: favorites.map((fav) => ({
          communityId: fav?.community?.id,
          planId: fav?.plan?.id,
          elevationId: fav?.elevation?.id,
          lotId: fav?.lot?.id,
          schemeId: fav?.scheme?.id,
          colorSelections: fav?.colorSelections,
          fpOptSelections: fav?.fpOptSelections,
          interiorDesignSelections: JSON.stringify(
            fav?.interiorDesignSelections
          ),
          source: 'truss',
          garagePosition: fav?.garagePosition,
          isMirrored: fav?.isMirrored,
          mortgage: fav?.mortgage && {
            downPayment: fav.mortgage.downPayment,
            downPercent: fav.mortgage.downPercent,
            interest: fav.mortgage.interest,
            term: fav.mortgage.term,
          },
        })),
      },
    })
    .then((response) => {
      return response?.data?.addFavoritesForProspect
    })
}
