import React, { useContext } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { StoreContext } from '../../store'
import { setSnackBar } from '../../store/reducers'

const AppSnackBar = () => {
  const { appSnackBar, dispatch } = useContext(StoreContext)

  const closeSnackBar = () => {
    // appSnackBar.callback()
    dispatch(
      setSnackBar({
        open: false,
        duration: 5000,
        position: appSnackBar.position
          ? appSnackBar.position
          : { horizontal: 'right', vertical: 'bottom' },
      })
    )
  }

  return (
    <Snackbar
      open={appSnackBar.open}
      autoHideDuration={appSnackBar.duration}
      anchorOrigin={appSnackBar.position}
      onClose={closeSnackBar}
    >
      <Alert severity={appSnackBar.type} style={{ textAlign: 'center' }}>
        {appSnackBar.message}
      </Alert>
    </Snackbar>
  )
}

export default AppSnackBar
