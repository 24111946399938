import { initialSelectionState } from './selection'
import { uniqueIndexForFavorite } from '../utils/favorites'
import { getPath, not, propEquals } from '@anewgo/functions'
import isEqual from 'lodash.isequal'

export const favoritesActionTypes = {
  DELETE_FAVORITE: 'DELETE_FAVORITE',
  MERGE_FAVORITES: 'MERGE_FAVORITES',
  REPLACE_FAVORITES: 'REPLACE_FAVORITES',
  DUPLICATE_FAVORITES: 'DUPLICATE_FAVORITES',
  ANONYMOUS_FAVORITES_LOADED: 'ANONYMOUS_FAVORITES_LOADED',
  DB_FAVORITES_LOADED: 'DB_FAVORITES_LOADED',
  LIKE_SELECTION: 'LIKE_SELECTION',
  UPDATE_SELECTION: 'UPDATE_SELECTION',
  ADD_FAVORITE: 'ADD_FAVORITE',
}

export const initialFavoritesState = {
  favorites: [],
  anonymousFavoritesLoaded: false,
  dbFavoritesLoaded: false,
}

export const favoritesReducer = (action) => (state) => {
  if (global.loggingEnabled) {
    console.log('favoritesReducer', { action, state })
  }

  // const upsertFavorite = favorite => {
  //   // adding all of these properties to our new favorite creates a matching 'shape' for all of our
  //   // favorites stored in our Store Context
  //   const newFavorite = {
  //     ...favorite,
  //     index: uniqueIndexForFavorite(favorite),
  //   }
  //   const favorites = []
  //   state.favorites.forEach(oldFavorite => {
  //     if (oldFavorite.index === newFavorite.index) {
  //       favorites.push(newFavorite)
  //     } else {
  //       favorites.push(oldFavorite)
  //     }
  //   })
  //   favorites.push(newFavorite)
  //   return favorites
  // }

  const areFavoritesEqual = (newFav, old) => {
    const newer = {
      index: uniqueIndexForFavorite(newFav),
      communityId: getPath('community', 'id')(newFav),
      planId: getPath('plan', 'id')(newFav),
      elevationId: getPath('elevation', 'id')(newFav),
      lotId: getPath('lot', 'id')(newFav),
      schemeId: getPath('schemeId')(newFav) || getPath('scheme', 'id')(newFav),
      clientName: getPath('clientName')(newFav),
      id: newFav.id,
      colorSelections: getPath('colorSelections')(newFav),
      fpOptSelections: getPath('fpOptSelections')(newFav),
      interiorDesignSelections: getPath('interiorDesignSelections')(newFav),
    }
    const older = {
      index: uniqueIndexForFavorite(old),
      communityId: getPath('community', 'id')(old),
      planId: getPath('plan', 'id')(old),
      elevationId: getPath('elevation', 'id')(old),
      lotId: getPath('lot', 'id')(old),
      schemeId: getPath('schemeId')(old) || getPath('scheme', 'id')(old),
      clientName: getPath('clientName')(old),
      id: old.id,
      colorSelections: getPath('colorSelections')(old),
      fpOptSelections: getPath('fpOptSelections')(old),
      interiorDesignSelections: getPath('interiorDesignSelections')(old),
    }
    return isEqual(newer, older)
  }

  const compileFavorite = (fav, preventInsert) => {
    return {
      ...fav,
      favId: fav.id,
      index: uniqueIndexForFavorite(fav),
      communityId: getPath('community', 'id')(fav),
      planId: getPath('plan', 'id')(fav),
      elevationId: getPath('elevation', 'id')(fav),
      lotId: getPath('lot', 'id')(fav),
      schemeId: getPath('schemeId')(fav) || getPath('scheme', 'id')(fav),
      clientName: getPath('clientName')(fav),
      // We add the property/value 'liked: true' to each favorite. This property will toggle
      // the 'LIKE/LIKED' icon on the plan/inventory card.
      lastUpdate: preventInsert
        ? getPath('lastUpdate')(fav)
        : new Date().getTime(),
      liked: true,
      addToDb: preventInsert ? false : true,
      updateToDB: true,
    }
  }
  const upsertAnonymousFavorites = (
    stateFavorites,
    newFavorites,
    preventInsert
  ) => {
    const compiledStateFavorites = stateFavorites.map((favorite) =>
      compileFavorite(favorite, preventInsert)
    ) // add unique identifier and other data
    return newFavorites.reduce(
      (favs, fav) => {
        const compiledNewFavorite = compileFavorite(fav, preventInsert)
        favs.forEach((oldFavorite, index) => {
          if (oldFavorite.index === compiledNewFavorite.index) {
            favs.splice(index, 1)
            if (preventInsert) {
              // If we're preventing a new favorite insert, only push if we're replacing the old one.
              favs.push(compiledNewFavorite)
            }
          }
        })
        if (!preventInsert) {
          // If we're not preventing inserts, always add new favorites.
          favs.push(compiledNewFavorite)
        }
        return favs
      },
      [...compiledStateFavorites] // State favorites are always returned.
    )
  }

  // Merges all state favorites and prospect favorites and returns the merged array of all favorites
  const upsertFavorites = (
    stateFavorites,
    newFavorites,
    preventInsert = false
  ) => {
    // This checks if new favorites have an ID, essentially confirming if the new favorites are DB
    // favorites that need to be updated.
    if (newFavorites.length && newFavorites[0].id) {
      //update favorite or mirror thingy
      newFavorites.forEach((newFav) => {
        const currentFavIndex = stateFavorites.findIndex(
          (fav) => fav.id === newFav.id
        )
        let newFavorite

        // This check checks if the new favorite we have has a change in index
        //  This would essentially point to it being a mirrored favorite
        if (uniqueIndexForFavorite(newFav) !== newFav.index) {
          const alreadyExistingFavorite = stateFavorites.findIndex(
            (fav) => fav.index === uniqueIndexForFavorite(newFav)
          )
          // If the mirror of this favorite already exists
          if (alreadyExistingFavorite >= 0) {
            newFavorite = compileFavorite(newFav, preventInsert)
            newFavorite.id = stateFavorites[alreadyExistingFavorite].id
            // update if the new mirrored favorite is any different from the existing one
            if (
              !areFavoritesEqual(
                newFavorite,
                stateFavorites[alreadyExistingFavorite]
              )
            ) {
              stateFavorites.push(newFavorite)
              stateFavorites.splice(alreadyExistingFavorite, 1)
            }
          }
          // else if the mirror of the favorite is not yet in the DB add it in the store with an undefined id (it has an ID right now, we need to remove it)
          else if (!preventInsert) {
            newFavorite = compileFavorite(newFav, preventInsert)
            newFavorite.id = undefined
            stateFavorites.push(newFavorite)
          }
        }
        // update an existing favorite
        else {
          newFavorite = compileFavorite(newFav, preventInsert)
          stateFavorites.push(newFavorite)
          stateFavorites.splice(currentFavIndex, 1)
        }
      })
    } else {
      // this condition checks if the favorites coming from state are DB favorites
      if (stateFavorites.length && stateFavorites[0].id && !preventInsert) {
        newFavorites.forEach((favorite) => {
          const newFavorite = compileFavorite(favorite, preventInsert)

          const alreadyExistingIndex = stateFavorites.findIndex(
            (fav) => fav.index === newFavorite.index
          )
          const alreadyExistingFavorite = stateFavorites.find(
            (fav) => fav.index === newFavorite.index
          )
          if (alreadyExistingIndex === -1) {
            stateFavorites.push(newFavorite)
          }
          if (
            alreadyExistingIndex !== -1 &&
            !isEqual(alreadyExistingFavorite, newFavorite)
          ) {
            stateFavorites.push(newFavorite)
          }
        })
      } else {
        // anonymous favorites or added a new favorite
        return upsertAnonymousFavorites(
          stateFavorites,
          newFavorites,
          preventInsert
        )
      }
    }
    return stateFavorites
  }

  switch (action.type) {
    case favoritesActionTypes.DELETE_FAVORITE: {
      return action.payload.id
        ? {
            ...state,
            favorites: state.favorites.filter(
              not(propEquals('id', action.payload.id))
            ),
            prospect: {
              ...state.prospect,
              // remove online reservations in app state from favorite that is deleted
              onlineReservations: state.prospect?.onlineReservations?.length
                ? [
                    ...state.prospect.onlineReservations.filter(
                      (res) => res.favoriteId === action.payload.id
                    ),
                  ]
                : [],
            },
          }
        : {
            ...state,
            favorites: state.favorites.filter(
              (fav) => !(fav.index === action.payload.index)
            ),
          }
    }
    case favoritesActionTypes.LIKE_SELECTION: {
      const baseFavorite =
        state.favorites.find(
          propEquals('index', uniqueIndexForFavorite(action.payload))
        ) || initialSelectionState
      return {
        ...state,
        favorites: upsertFavorites(state.favorites, [
          {
            ...baseFavorite,
            ...action.payload,
            liked: true,
          },
        ]),
      }
    }

    case favoritesActionTypes.UPDATE_FAVORITE_AFTER_DB_UPDATE: {
      let favoritesNotToUpdate = state.favorites.filter(
        (fav) => !(!fav.id && fav.index === action.payload.index)
      )
      if (favoritesNotToUpdate.length === state.favorites.length) {
        favoritesNotToUpdate = state.favorites.filter(
          (fav) => !(fav.id === action.payload.id)
        )
      }
      return {
        ...state,
        favorites: [...favoritesNotToUpdate, action.payload],
      }
    }
    case favoritesActionTypes.MERGE_FAVORITES: {
      let mergedFavorites = action.payload.map((favorite) => ({
        ...favorite,
        index: uniqueIndexForFavorite(favorite),
        liked: true,
      }))

      // This code assumes we will never have a situation where the favorites in state are from a
      // different client than the client of the prospect favorites retrieved from the database.
      // This should always be true, since App.js deletes anonymous favorites whenever the client
      // is changed, which means anonymous favorites will always be for the same client as the
      // client the user is viewing when they sign in.
      mergedFavorites = upsertFavorites(state.favorites, mergedFavorites)

      return {
        ...state,
        favorites: mergedFavorites,
      }
    }
    case favoritesActionTypes.REPLACE_FAVORITES: {
      const mergedFavorites = action.payload.map((favorite) => ({
        ...favorite,
        index: uniqueIndexForFavorite(favorite),
        liked: true,
      }))

      return {
        ...state,
        favorites: mergedFavorites,
      }
    }
    case favoritesActionTypes.DUPLICATE_FAVORITES: {
      const newFavorites = action.payload.map((favorite) => ({
        ...favorite,
        reservationStatus: null,
        index: uniqueIndexForFavorite(favorite),
      }))

      // we add the duplicate with a new field duplicateNumber that is incremented on top of all the already present duplicates
      const duplicateFavorites = newFavorites.map((fav) => {
        const newDuplicate =
          !fav.id &&
          Math.max(
            ...state.favorites
              .filter((item) => item.index === fav.index)
              .map((a) => a.duplicateNumber || 0)
          ) + 1
        return {
          ...fav,
          duplicateNumber: !fav.id ? newDuplicate : undefined,
        }
      })
      return {
        ...state,
        favorites: [...state.favorites, ...duplicateFavorites],
      }
    }
    case favoritesActionTypes.ANONYMOUS_FAVORITES_LOADED: {
      return {
        ...state,
        anonymousFavoritesLoaded: true,
      }
    }
    case favoritesActionTypes.DB_FAVORITES_LOADED: {
      return {
        ...state,
        dbFavoritesLoaded: true,
      }
    }
    case favoritesActionTypes.UPDATE_SELECTION: {
      return {
        ...state,
        favorites: upsertFavorites(
          state.favorites,
          [
            {
              ...action.payload,
              liked: true,
            },
          ],
          true // Send "true" for preventInsert
        ),
      }
    }
    case favoritesActionTypes.ADD_FAVORITE: {
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
      }
    }
    default:
      throw new Error('Unexpected action sent to favorites reducer.', {
        action,
      })
  }
}
