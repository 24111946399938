import { createContext, useCallback, useMemo, useState } from 'react'
import { initialState } from './reducers'
import { pipe } from '@anewgo/functions'

export const StoreContext = createContext()
export const StoreConsumer = StoreContext.Consumer

export const StoreProvider = ({ children }) => {
  const [state, setState] = useState(initialState)
  const dispatch = useCallback((...reductions) => {
    return setState(pipe(...reductions))
  }, [])
  const providerValue = useMemo(
    () => ({
      anonymousProspect: state.authState.anonymousProspect,
      favorites: state.favoritesState.favorites,
      isSignedIn: Boolean(state.authState.prospect),
      dialog: state.dialog,
      selection: state.selectionState,
      prospect: state.authState.prospect,
      prospectToken: state.authState.prospectToken,
      isProspectFetching: state.authState.isProspectFetching,
      anonymousFavoritesLoaded: state.favoritesState.anonymousFavoritesLoaded,
      dbFavoritesLoaded: state.favoritesState.dbFavoritesLoaded,
      uiConfig: state.uiState,
      authenticatedDialogOpen: state.authState.authenticatedDialogOpen,
      authenticatedDialogMessage: state.authState.authenticatedDialogMessage,
      appSnackBar: state.snackBarState,
      track: state.trackState,
      dispatch,
    }),
    [dispatch, state]
  )

  if (global.loggingEnabled) {
    console.log('StoreContext update', state)
  }

  return (
    <StoreContext.Provider value={providerValue}>
      {children}
    </StoreContext.Provider>
  )
}
