export const trackTypes = {
  SET_LAST_VISITED_RESERVATION: 'SET_LAST_VISITED_RESERVATION',
}

export const initialTrackState = {
  lastVisitedReservation: null,
}

export const trackReducer = (action) => (state) => {
  if (global.loggingEnabled) {
    console.log('trackReducer', { action, state })
  }

  switch (action.type) {
    case trackTypes.SET_LAST_VISITED_RESERVATION:
      return {
        ...state,
        lastVisitedReservation: action.payload,
      }
    default:
      throw new Error('Unexpected action sent to ui reducer.', { action })
  }
}
