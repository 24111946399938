import { Fragment, useContext, useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { ApolloConsumer } from '@apollo/client'
import { compose, fromRenderProps, setDisplayName } from 'react-recompose'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { StoreContext } from '../../store'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import PrivacyAndADADialog from '../PrivacyAndADADialog/PrivacyAndADADialog'
import Root, { classes } from './DisclaimerDialogStyles'
import { useTypedPersistentState, validators } from '@anewgo/storage'

function DisclaimerDialog() {
  const { uiConfig } = useContext(StoreContext)
  const { mobile } = uiConfig
  const [displayPolicyModal, setDisplayPolicyModal] = useState(false)
  const [linkToDisplay, setLinkToDisplay] = useState(null)

  const [hasViewedADAOverlay, setHasViewedADAOverlay] = useTypedPersistentState(
    'hasViewedADAOverlay',
    false,
    validators.bool
  )

  const [hasViewedPrivacyOverlay, setHasViewedPrivacyOverlay] =
    useTypedPersistentState('hasViewedPrivacyOverlay', false, validators.bool)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setHasViewedADAOverlay(true)
    setHasViewedPrivacyOverlay(true)
  }

  // r e n d e r
  return (
    <Root>
      <Snackbar
        open={!hasViewedADAOverlay || !hasViewedPrivacyOverlay}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <SnackbarContent
          classes={{ message: classes.snackMessage }}
          message={
            <div className={classes.snackDiv}>
              {!hasViewedADAOverlay && (
                <div>
                  <Typography
                    variant={!mobile ? 'button' : 'caption'}
                    classes={{ button: classes.h2, caption: classes.h2 }}
                  >
                    {'Click '}
                  </Typography>
                  <Link
                    style={{ color: '#89C2FB' }}
                    className={classes.link}
                    onClick={() => {
                      setDisplayPolicyModal(true)
                      setLinkToDisplay(
                        `${process.env.REACT_APP_TRUSS}/adapolicy`
                      )
                      setHasViewedADAOverlay(true)
                    }}
                  >
                    <Typography
                      variant={!mobile ? 'button' : 'caption'}
                      classes={{ button: classes.h2, caption: classes.h2 }}
                    >
                      here
                    </Typography>
                  </Link>
                  <Typography
                    variant={!mobile ? 'button' : 'caption'}
                    classes={{ button: classes.h2, caption: classes.h2 }}
                  >
                    {' to view our ADA Policy and Disclaimer'}
                  </Typography>
                </div>
              )}
              {!hasViewedPrivacyOverlay && (
                <div>
                  <Typography
                    variant={!mobile ? 'button' : 'caption'}
                    classes={{ button: classes.h2, caption: classes.h2 }}
                  >
                    {'Click '}
                  </Typography>
                  <Link
                    style={{ color: '#89C2FB' }}
                    className={classes.link}
                    onClick={() => {
                      setDisplayPolicyModal(true)
                      setLinkToDisplay('https://anewgosell.com/privacy-policy')
                      setHasViewedPrivacyOverlay(true)
                    }}
                  >
                    <Typography
                      variant={!mobile ? 'button' : 'caption'}
                      classes={{ button: classes.h2, caption: classes.h2 }}
                    >
                      here
                    </Typography>
                  </Link>
                  <Typography
                    variant={!mobile ? 'button' : 'caption'}
                    classes={{ button: classes.h2, caption: classes.h2 }}
                  >
                    {' to view our Privacy Policy'}
                  </Typography>
                </div>
              )}
            </div>
          }
          action={
            <Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={handleClose}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Fragment>
          }
        />
      </Snackbar>
      <PrivacyAndADADialog
        isDisplayed={displayPolicyModal}
        setIsDisplayed={setDisplayPolicyModal}
        linkToDisplay={linkToDisplay}
      />
    </Root>
  )
}

const Wrapped = compose(
  withRouter,
  fromRenderProps(ApolloConsumer, (apolloClient) => ({ apolloClient })),
  setDisplayName('DisclaimerDialog')
)(DisclaimerDialog)

export default Wrapped
