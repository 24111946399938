import { validateProspectToken } from './prospect'
import omit from 'lodash.omit'
import isEqual from 'lodash.isequal'
import { useContext, useEffect } from 'react'
import { setProspectToken } from '../store/reducers'
import { StoreContext } from '../store'
import { persistentStorage } from '@anewgo/storage'

const EIGHT_HOURS_IN_MS = 8 * 60 * 60 * 1000
const persistentTokenName = 'pt'
const dataKey = 'pd'

let prospectToken = undefined
let tokenChangedEventHandlerFn = undefined
const notifyChange = () => tokenChangedEventHandlerFn?.(prospectToken)

/**
 * Token
 * @class
 * @param {string} value - Token value
 * @param {number} exp - Token expiration date
 * @param {AuthProvider} provider - Authentication provider
 * @param {object} data - Additional data
 * @param {{isValid: boolean, isRegistered: boolean, isFirstSignIn: boolean}} validation - Validation result
 */
class Token {
  constructor({ value, exp, provider, prospect, data, validation }) {
    this.value = value
    this.exp = exp
    this.provider = provider
    this.data = data
    this.prospect = prospect
    this.validation = validation
  }

  separate() {
    return {
      token: {
        value: this.value,
        exp: this.exp,
        provider: this.provider,
        prospect: this.prospect,
      },
      data: this.data,
    }
  }
}

/**
 * Creates token storage string from parameters.
 * @param value Token value
 * @param expires Timestamp until token expires (defaults to 8 hours)
 * @return {string} Token string
 */
export const createTokenString = (value, expires) => {
  const deleteToken = expires === 0
  const date = deleteToken ? new Date(0) : new Date()
  if (!deleteToken) date.setTime(expires || date.getTime() + EIGHT_HOURS_IN_MS)
  if (value !== null && typeof value !== 'string') {
    value = JSON.stringify(value)
  }
  return value
}

/**
 * Returns token from persistent storage.
 * @return {Token | null}
 */
function getToken() {
  // Get persistent token
  let persistentToken = persistentStorage.getItem(persistentTokenName)
  if (!persistentToken) return null

  // Get token from persistent storage and validate it
  let token = parsePersistentToken(persistentToken, false)
  if (!token?.exp || token.exp < Date.now()) return null

  // Get additional data from local storage
  let data = persistentStorage.getItem(dataKey)
  if (data) data = JSON.parse(data)
  // Build token and return it
  return new Token({ ...token, data })
}

/**
 * Sets token in persistent storage.
 * @param provider {string} - Authentication Provider
 * @param prospect {NexusProspect} - Prospect data
 * @param token {string} - Token value
 * @param exp {number} - Token expiration time
 * @param isRegistered {boolean} - Set isRegistered flag
 * @param data {object} - Additional data to be stored with token
 */
// function setToken(prospect, token, exp, isRegistered = true, data = undefined) {
function setToken(
  provider,
  prospect,
  token,
  exp,
  isRegistered = true,
  data = undefined
) {
  let prospectToken = new Token({
    provider,
    prospect: cleanProspect(prospect),
    exp,
    value: token,
    data,
    validation: {
      isValid: true,
      isRegistered: !!prospect && isRegistered,
      isFirstSignIn: false,
    },
  })

  const { token: tokenData, data: extraData } = prospectToken.separate()
  clearToken()

  // Store token data in persistentStorage
  persistentStorage.setItem(
    persistentTokenName,
    createTokenString(tokenData, exp)
  )

  if (extraData && Object.keys(extraData).length > 0) {
    persistentStorage.setItem(dataKey, JSON.stringify(extraData))
  } else {
    persistentStorage.removeItem(dataKey)
  }

  notifyChange()
}

function updateProspectInPersistentStorage(newProspectData) {
  const token = getToken()

  const updatedProspect = {
    ...token.prospect,
    ...newProspectData,
  }

  setToken(
    token.provider,
    updatedProspect,
    token.value,
    token.exp,
    true,
    token.data
  )
}

/**
 * Cleans prospect properties before save.
 * @param prospect {NexusProspect|null} - Prospect
 */
function cleanProspect(prospect) {
  if (prospect) {
    return omit(prospect, [
      'favorites',
      'brochures',
      'receivedFavorites',
      'onlineReservations',
    ])
  }

  return prospect
}

/**
 * Gets token from cache or token. If it's not in cache, it will be cached.
 * @return {Token | null}
 */
function getCachedToken() {
  if (!!prospectToken) return prospectToken
  prospectToken = getToken()
  notifyChange()
  return prospectToken
}

/**
 * Gets token from cache or persistent storage. If it's not in cache, it will be cached. Validates token from persistent storage as well.
 * @return {Token | null}
 */
function getValidCachedToken() {
  const token = getCachedToken()
  if (!token) return null

  if (token.validation && !token.validation.isValid) {
    clearToken()
    return null
  }

  return token
}

/**
 * Clears token and validation status from cache.
 */
function clearCache() {
  prospectToken = undefined
}

/**
 * Clears token from cache and persistent storage.
 */
function clearToken() {
  persistentStorage.removeItem(persistentTokenName)
  persistentStorage.removeItem(dataKey)
  clearCache()
  notifyChange()
}

/**
 * Parses persistent token value as string or JSON.
 * @param persistentTokenValue persistentToken value from persistent storage
 * @param isString if true, will parse as string, otherwise as JSON
 * @return {null|any} parsed persistentToken value or null on failure
 */
export const parsePersistentToken = (
  persistentTokenValue,
  isString = false
) => {
  if (isString) return persistentTokenValue
  try {
    return JSON.parse(persistentTokenValue)
  } catch (e) {
    return null
  }
}

/**
 * Validates token using Fence and caches result.
 * @return {Promise<boolean>} - True if token is valid, false otherwise.
 */
async function validateToken(clientName, hasConsent = false) {
  const token = getCachedToken()
  if (!token) {
    return false
  }

  if (token?.validation?.isValid) {
    return true
  }

  try {
    prospectToken.validation = {
      ...(await validateProspectToken(clientName, token.value, hasConsent)),
    }
  } catch (e) {
    console.error(`Token validation failed: ${e.message}`)
    prospectToken.validation.isValid = false
  }

  const isValid =
    prospectToken.validation.isValid && prospectToken.validation.isRegistered
  if (!isValid) {
    clearToken()
  }
  notifyChange()

  return isValid
}

/**
 * Resets validation state of cached token.
 */
const resetValidationState = () => {
  if (prospectToken) {
    prospectToken.validation = undefined
  }
}

export const tokenService = {
  setToken,
  token: getValidCachedToken,
  validate: validateToken,
  clear: clearToken,
  clearCache,
  resetValidation: resetValidationState,
  updateProspectInPersistentStorage,
}

/**
 * Monitors changes to prospect token and updates it in store context.
 */
export function useProspectTokenChangeMonitor() {
  const { prospectToken, dispatch } = useContext(StoreContext)
  const tokenChangeEventHandler = (newToken) => {
    if (!isEqual(prospectToken, newToken)) {
      dispatch(setProspectToken(newToken))
    }
  }

  useEffect(() => {
    tokenChangedEventHandlerFn = tokenChangeEventHandler
    tokenChangedEventHandlerFn(prospectToken)
    return () => (tokenChangedEventHandlerFn = undefined)
  }, [dispatch, prospectToken])
}
