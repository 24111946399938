export class AuthProviders {
  static FACEBOOK = 'FACEBOOK'
  static GOOGLE = 'GOOGLE'
  static MAGIC = 'MAGIC'
  static EMAIL = 'EMAIL'
}

export class AuthTrackingReferrers {
  static FACEBOOK = 'facebook_accounts_callback'
  static GOOGLE = 'google_accounts_callback'
  static MAGIC = 'magic_accounts_callback'
}

export class AuthTrackingMethods {
  static FACEBOOK = 'facebook'
  static GOOGLE = 'google'
  static MAGIC = 'magic'
}
