export const authActionTypes = {
  CLOSE_AUTHENTICATED_DIALOG: 'CLOSE_AUTHENTICATED_DIALOG',
  OPEN_AUTHENTICATED_DIALOG: 'OPEN_AUTHENTICATED_DIALOG',
  SET_ANONYMOUS_PROSPECT: 'SET_ANONYMOUS_PROSPECT',
  SET_IS_PROSPECT_FETCHING: 'SET_IS_PROSPECT_FETCHING',
  SET_PROSPECT: 'SET_PROSPECT',
  SET_PROSPECT_TOKEN: 'SET_PROSPECT_TOKEN',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  DELETE_SHARED_FAVORITES: 'DELETE_SHARED_FAVORITES',
}

export const initialAuthState = {
  anonymousProspect: null,
  authenticatedDialogOpen: false,
  authenticatedDialogMessage: '',
  isSignedIn: false,
  prospect: null, // this is synced with db
  isProspectFetching: null,
  prospectToken: null,
}

export const authReducer = (action) => (state) => {
  if (global.loggingEnabled) {
    console.log('authReducer', { action, state })
  }

  switch (action.type) {
    case authActionTypes.CLOSE_AUTHENTICATED_DIALOG: {
      return {
        ...state,
        authenticatedDialogOpen: false,
      }
    }
    case authActionTypes.OPEN_AUTHENTICATED_DIALOG: {
      return {
        ...state,
        authenticatedDialogOpen: true,
        authenticatedDialogMessage: action.payload,
      }
    }
    case authActionTypes.SET_ANONYMOUS_PROSPECT: {
      return {
        ...state,
        anonymousProspect: action.payload,
      }
    }
    case authActionTypes.SET_PROSPECT: {
      return {
        ...state,
        prospect: action.payload,
      }
    }
    case authActionTypes.SET_IS_PROSPECT_FETCHING: {
      return {
        ...state,
        isProspectFetching: action.payload,
      }
    }
    case authActionTypes.SET_PROSPECT_TOKEN: {
      return {
        ...state,
        prospectToken: action.payload,
      }
    }
    case authActionTypes.SIGN_IN: {
      return {
        ...state,
        isSignedIn: true,
      }
    }
    case authActionTypes.SIGN_OUT: {
      return {
        ...state,
        anonymousProspect: null,
        prospect: null,
        isSignedIn: false,
      }
    }
    case authActionTypes.DELETE_SHARED_FAVORITES: {
      return {
        ...state,
        prospect: {
          ...state.prospect,
          receivedFavorites:
            state.prospect.receivedFavorites?.filter(
              (fav) =>
                !(
                  fav.clientId === action.payload.clientId &&
                  fav.sentByProspectId === action.payload.senderProspectId
                )
            ) || [],
        },
      }
    }
    default:
      throw new Error('Unexpected action sent to auth reducer.', { action })
  }
}
