export default class LotStatus {
  static Available = 'AVAILABLE'
  static Inventory = 'INVENTORY'
  static UnderConstruction = 'UNDER_CONSTRUCTION'
  static Sold = 'SOLD'
  static Reserved = 'RESERVED'
  static Unreleased = 'UNRELEASED'
  static Model = 'MODEL'
  static Closed = 'CLOSED'
  static UnderContract = 'UNDER_CONTRACT'
  static BeingReserved = 'BEING_RESERVED'
  static FutureInventory = 'FUTURE_INVENTORY'

  static getInventoryStatuses() {
    return [
      LotStatus.Inventory,
      LotStatus.UnderConstruction,
      LotStatus.FutureInventory,
    ]
  }
}
