import { Fragment } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { useHistory, useLocation } from 'react-router'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { UrlParams } from '../../constants/UrlParams'

const AutoLogOutDialog = () => {
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(location.search)

  const handleClose = () => {
    params.delete(UrlParams.autoLogOut)
    history.replace({
      search: params.toString(),
    })
  }

  return (
    <Fragment>
      <Dialog
        open={params.has(UrlParams.autoLogOut)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ position: 'relative' }}>
          Your secure session has expired and you have been automatically logged
          out!
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default AutoLogOutDialog
