import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'

const PREFIX = 'ContactDialog'

export const classes = {
  h2: `${PREFIX}-h2`,
  img: `${PREFIX}-img`,
  formControl: `${PREFIX}-formControl`,
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.h2}`]: {
    fontFamily: 'Gotham Book, sans-serif',
    fontWeight: 'bold',
    position: 'absolute',
    top: '50%',
    left: 40,
    transform: 'translate(0%, -50%)',
    zIndex: 1,
  },

  [`& .${classes.img}`]: {
    width: '100%',
    height: 150,
    objectFit: 'cover',
    opacity: 0.75,
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
}))

export default StyledDialog
