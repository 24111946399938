import { Fragment, useState, useContext } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PersonIcon from '@mui/icons-material/Person'
import IconButton from '@mui/material/IconButton'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormHelperText from '@mui/material/FormHelperText'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import { StoreContext } from '../../store'
import * as reducers from '../../store/reducers'
import { isEmail } from '../../utils'

const LoginDialog = ({ isOpen, setIsOpen, onLoginSubmit }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { dispatch, uiConfig } = useContext(StoreContext)
  const { buyerAuthFailedCallback } = uiConfig
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)

  const handleClose = () => {
    closeDialog()
    dispatch(reducers.setBuyerAuthCallback())
    dispatch(reducers.setBuyerAuthFailedCallback())
  }

  const closeDialog = () => {
    setIsOpen(false)
    buyerAuthFailedCallback()
  }

  const handleEmailsChange = (emailsInput) => {
    setEmail(emailsInput)
  }

  const handleEmailOnBlur = () => {
    setEmailError(!isEmail(email))
  }

  const isFormValid = () => {
    let valid = true
    if (!isEmail(email)) {
      setEmailError(true)
      valid = false
    }
    return valid
  }

  const handleSignIn = (e) => {
    e.preventDefault()
    if (!isFormValid()) {
      return
    }

    const userProfile = {
      email,
    }

    if (onLoginSubmit) {
      onLoginSubmit(userProfile)
      closeDialog()
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      fullScreen={fullScreen}
    >
      <IconButton
        style={{
          position: 'absolute',
          right: 0,
          top: 4,
          color: 'rgba(0, 0, 0, 0.54)',
        }}
        data-testid="login_dialog_close_email_login_button"
        color="inherit"
        onClick={handleClose}
        aria-label="Close"
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <Fragment>
        <DialogTitle id="form-dialog-title">Email Login</DialogTitle>
        <form onSubmit={handleSignIn}>
          <DialogContent style={{ overflow: 'hidden' }}>
            <FormControl margin="normal" fullWidth={true}>
              <Input
                id="user-email-input"
                inputProps={{ 'data-testid': 'login_dialog_email_input' }}
                placeholder={'Email Address'}
                fullWidth
                error={emailError}
                value={email}
                onChange={(ev) => handleEmailsChange(ev.target.value)}
                onBlur={handleEmailOnBlur}
                startAdornment={
                  <InputAdornment position={'start'}>
                    <MailOutlineIcon />
                  </InputAdornment>
                }
              />
              {emailError && (
                <FormHelperText error={emailError} id="user-email-help">
                  Enter a valid email address
                </FormHelperText>
              )}
              {!emailError && <FormHelperText>Required</FormHelperText>}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              data-testid="login_dialog_cancel_button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              data-testid="login_dialog_submit_button"
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Fragment>
    </Dialog>
  )
}

export default LoginDialog
