import { Fragment } from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'

const ApplicationErrorMessage = ({ open, error, onClose, errorType }) => {
  const renderGraphqlMessage = () => {
    return error.map((err) => {
      const { message, locations, path } = err
      return (
        <div>
          <Typography>{`[GraphQL error]: Message: ${message}`}</Typography>
          <Typography>{`Location: ${JSON.stringify(locations)}`}</Typography>
          <Typography>{`Path: ${path}`}</Typography>
        </div>
      )
    })
  }
  const renderNetworkMessage = () => (
    <div>
      <Typography>{`[Network error]: Message: ${error}`}</Typography>
    </div>
  )

  return (
    <Fragment>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: '#222',
            color: '#faebd7',
            textAlign: 'center',
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'opaque',
            boxShadow: 'none',
          },
        }}
        onClose={onClose}
        open={open}
        aria-labelledby={`application-error-message-title`}
      >
        <DialogTitle
          id="application-error-message-title"
          style={{ paddingBottom: '0px' }}
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Error <ErrorIcon style={{ marginLeft: 10 }} />
          </span>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 16 }}>
          <Typography>
            The application has encountered an unknown error.
          </Typography>
          <Typography gutterBottom>
            Please contact support with the details below.
          </Typography>
          <div>
            <Typography style={{ fontWeight: 'bold' }}>
              Error Details
            </Typography>
            {errorType === 'graphql'
              ? renderGraphqlMessage()
              : renderNetworkMessage()}
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default ApplicationErrorMessage
