import Backdrop from '@mui/material/Backdrop'
import PropTypes from 'prop-types'
import StyledModal from './PrivacyAndADADialogStyles'

const PrivacyAndADADialog = ({
  isDisplayed,
  setIsDisplayed,
  linkToDisplay,
}) => {
  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isDisplayed}
      onClose={() => setIsDisplayed(false)}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <iframe
        src={linkToDisplay}
        style={{ height: '75vh', width: '75vw', backgroundColor: 'white' }}
      />
    </StyledModal>
  )
}

PrivacyAndADADialog.propTypes = {
  isDisplayed: PropTypes.bool.isRequired,
  setIsDisplayed: PropTypes.func.isRequired,
  linkToDisplay: PropTypes.string,
}
PrivacyAndADADialog.defaultProps = {
  linkToDisplay: null,
}
export default PrivacyAndADADialog
