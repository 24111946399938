import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const StyledLoading = styled('div')({
  top: 0,
  left: 0,
  display: 'flex',
  position: 'fixed',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'rgba(255, 255, 255, 0.75)',
  zIndex: 1000,
})

export const LoadingText = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 'normal',
})
