import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

const DisabledStorageDialog = ({ open, onClose }) => (
  <Dialog open={open}>
    <DialogTitle>Your cookies appear to be disabled</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Please enable cookies for this site to ensure full functionality. Using
        this application without cookies is not supported.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose}>
        I Understand
      </Button>
    </DialogActions>
  </Dialog>
)

export default DisabledStorageDialog
